import React from 'react';

import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import { useSite } from '../../hooks/use-site';

export default function LayoutFooterComponent() {
  const { isfoodId } = useSite();

  return (
    <div className="mt-[40px] max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-sm text-gray-500 py-5 sm:flex border-gray-200 border-t">
      <div className="sm:w-3/4">
        {strings().footer.companyInfo}

        <div>{strings().footer.address}</div>
        <div>
          <a
            className="text-gray-500"
            href={`mailto:${isfoodId ? strings().FOOD_ID.footer.email : strings().footer.email}`}
          >
            {isfoodId ? strings().FOOD_ID.footer.email : strings().footer.email}
          </a>
        </div>
      </div>
      <div className="sm:w-1/4 print:hidden">
        <div className="flex space-x-5 mt-4 sm:mt-0 items-center sm:justify-end print:hidden">
          <img src={IMAGES.BANKS.VISA} className="h-[25px]" alt="Visa" />
          <img src={IMAGES.BANKS.MASTERCARD} alt="Mastercard" />
        </div>
      </div>
    </div>
  );
}
