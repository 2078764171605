import React from 'react';
import { ExclamationIcon, XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/outline';

interface ComponentProps {
  title?: string;
  text?: string;
  type?: 'danger' | 'success' | 'failed';
  marginTop?: string;
}

function AlertPrimary({ title, text, type, marginTop }: ComponentProps) {
  return (
    <div
      className={classNames('rounded-2xl bg-content-300 p-4 md:p-5', marginTop, {
        'bg-red-50': type === 'failed' || type === 'danger',
        'bg-success-300': type === 'success',
      })}
    >
      <div className="flex-row flex items-center">
        <div className="w-8">
          {type === 'success' && (
            <div className="mx-auto flex items-center justify-center h-8 w-8 rounded-full bg-success-800">
              <CheckIcon className="h-6 w-6 text-white" />
            </div>
          )}
          {type === 'failed' && (
            <XCircleIcon className="h-8 w-8 mb-2 mx-auto md:mb-0 md:h-10 md:w-10 text-red-400" aria-hidden="true" />
          )}
          {type !== 'failed' && type !== 'success' && (
            <ExclamationIcon
              className={`h-8 w-8 mb-2 mx-auto md:mb-0 md:h-9 md:w-9 text-content-700 ${
                type === 'danger' && 'text-red-500'
              }`}
              aria-hidden="true"
            />
          )}
        </div>

        <div className="pl-4">
          {title && <h3 className="font-bold font-base">{title}</h3>}
          {text && <div className="mt-1 text-sm">{text}</div>}
        </div>
      </div>
    </div>
  );
}
AlertPrimary.defaultProps = {
  type: 'primary',
  title: '',
  text: '',
  marginTop: 'mt-0',
};

export default AlertPrimary;
