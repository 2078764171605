import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { down } from 'styled-breakpoints';

import { Question } from '../../../entities/question';
import { QuestionOption } from '../../../entities/question-option';
import CommonButtonQuestion from '../../common/buttons/question';
import QuestionnaireFooterButtons from '../footer-buttons';

const QuestionOptionsContainer = styled.div`
  ${tw`flex flex-row -mx-2 flex-wrap`}
`;

const QuestionOptionContainer = styled.div`
  ${tw`px-2 pb-4`}

  ${down('sm')} {
    width: 100%;
  }
`;

interface ComponentProps {
  question: Question;
  onSelect?: (options: QuestionOption[]) => void;
}

function QuestionnaireQuestionMultipleComponent({ question, onSelect }: ComponentProps) {
  const [selected, setSelected] = useState<QuestionOption[]>([]);

  const onContinue = () => {
    if (onSelect) {
      onSelect(selected);
    }
  };

  const onSelectOption = (option: QuestionOption) => {
    const index = selected.indexOf(option);

    if (index === -1) {
      setSelected([...selected, option]);
    } else {
      selected.splice(index, 1);
      setSelected([...selected]);
    }
  };

  return (
    <>
      <QuestionOptionsContainer>
        {question.options.map((option) => (
          <QuestionOptionContainer key={option.id}>
            <CommonButtonQuestion
              hasCheckbox
              wrapTexts={question.wrap}
              active={selected.indexOf(option) !== -1}
              onClick={() => onSelectOption(option)}
              key={option.id}
            >
              {option.title}
            </CommonButtonQuestion>
          </QuestionOptionContainer>
        ))}
      </QuestionOptionsContainer>

      <QuestionnaireFooterButtons disableContinue={selected.length === 0} onContinue={() => onContinue()} />
    </>
  );
}

QuestionnaireQuestionMultipleComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionMultipleComponent;
