import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import useScript from '../../hooks/use-script';

interface ComponentProps {
  link: string;
}

export default function ModalSignAgreement({ link }: ComponentProps) {
  useScript('https://code.jquery.com/jquery-3.6.0.slim.min.js');
  useScript('https://gateway-sandbox.dokobit.com/js/isign.frame.js');

  return (
    <ModalText type={ModalKeys.SignAgreement} title={strings().textPage.signAgreement.title}>
      <iframe title={link} id="isign-gateway" width="100%" height="1000px" src={link} />
    </ModalText>
  );
}
