import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import Select, { OnChangeValue } from 'react-select';
import { useNavigate } from 'react-router-dom';

import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';
import { Location } from '../../entities/location';
import ContainerMd from '../common/containers/containerMd';
import StyledLabel from '../common/form/styled-label';
import Heading3 from '../common/headings/heading3';
import { useSite } from '../../hooks/use-site';

const BgImage = styled.img`
  left: -8.125rem;
  top: 0;
  width: 20.938rem;
  ${tw`absolute z-0`};
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-row pt-12 justify-end`}
`;

const SpacedButton = styled(CommonButtonDefault)`
  ${tw`ml-5`}
`;

interface ComponentProps {
  locations: Location[];
  onContinue: (location: Location) => void;
}

function BloodRegistrationSelectLocation({ onContinue, locations }: ComponentProps) {
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const navigate = useNavigate();
  const { isAllergomedica } = useSite();

  const onSelectLocation = (option: OnChangeValue<Location, false>) => {
    setCurrentLocation(option);
  };

  const onContinueButtonClick = () => {
    if (currentLocation) {
      onContinue(currentLocation);
    }
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <ContainerMd className="mt-10">
      <div className="relative pt-24">
        <Heading3 className="relative z-10">{strings().bloodRegistration.title}</Heading3>
        {isAllergomedica && <BgImage src={IMAGES.BLOB.FIRST} alt="" aria-hidden="true" />}
      </div>

      <StyledLabel>{strings().bloodRegistration.selectClinicStep}</StyledLabel>
      <Select
        options={locations}
        placeholder={strings().bloodRegistration.pickerPlaceholder}
        getOptionValue={(option: Location) => `${option.id}`}
        getOptionLabel={(option: Location) => `${option.partnerName}, ${option.title}`}
        onChange={onSelectLocation}
      />
      <ButtonsContainer>
        <CommonButtonDefault onClick={() => onGoBack()}>{strings().button.goBack}</CommonButtonDefault>
        <SpacedButton
          className="group relative ml-5"
          type="submit"
          primary
          disabled={!currentLocation}
          onClick={() => onContinueButtonClick()}
        >
          <span className="inline-block pr-3"> {strings().button.continue}</span>
          <img
            src={IMAGES.ICONS.ARROWRIGHT}
            className="transition-all absolute right-5 top-1/2 -mt-2 group-hover:right-4"
            alt=""
          />
        </SpacedButton>
      </ButtonsContainer>
    </ContainerMd>
  );
}

BloodRegistrationSelectLocation.defaultProps = {};

export default BloodRegistrationSelectLocation;
