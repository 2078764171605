import axios, { AxiosRequestConfig } from 'axios';

import { parameters } from '../../localization/strings';
import { Appointment } from '../../entities/appointment';
import { Allergist } from '../../entities/forms/allergist';

export const getAllergistTime = async (allergistId: number, answerHash?: string): Promise<Appointment[]> => {
  const url = `${parameters().api.baseUrl}/calendar/appoitment-slots/${allergistId}`;

  const config: AxiosRequestConfig = {
    params: {},
  };

  if (answerHash) {
    config.params.answerHash = answerHash;
  }

  const result = await axios.get(url, config);

  return result.data;
};

export const getAllergists = async (allergistId: any): Promise<Allergist[]> => {
  let params = {};
  const url = `${parameters().api.baseUrl}/users/admin/allergists-list`;

  if (allergistId) {
    params = { allergistId };
  }

  const result = await axios.get(url, { headers: {}, params });

  return result.data;
};
