import { MutableRefObject, useRef } from 'react';

const useTextAreaFocus = (): [MutableRefObject<HTMLTextAreaElement | null>, () => void] => {
  const htmlElRef: MutableRefObject<HTMLTextAreaElement | null> = useRef(null);
  const setFocus = (): void => {
    htmlElRef?.current?.focus?.();
  };

  return [htmlElRef, setFocus];
};

export default useTextAreaFocus;
