import axios from 'axios';

import { parameters } from '../../localization/strings';
import { ParcelTerminal } from '../../entities/parcel-terminal';

export const getList = async (key?: string): Promise<ParcelTerminal[]> => {
  let url = `${parameters().api.baseUrl}/parcel-terminals/list`;

  if (key) {
    url += `?key=${key}`;
  }

  const result = await axios.get(url);

  return result.data;
};
