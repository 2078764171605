import React, { useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import tw, { styled } from 'twin.macro';
import { lt } from 'date-fns/locale';

import * as appointmentsApi from '../../../services/api/appointments';
import QuestionnaireFooterButtons from '../footer-buttons';
import { Appointment } from '../../../entities/appointment';
import { Allergist } from '../../../entities/forms/allergist';
import CommonAnimatedLoader from '../../common/animated/loader';
import CommonButtonDefault from '../../common/buttons/default';
import IMAGES from '../../../assets/images';
import { strings } from '../../../localization/strings';
import { getLongMonthName } from '../../../services/formatter/date';
import { useQuestionnaire } from '../../../hooks/use-questionnaire';
import { Question } from '../../../entities/question';
import CommonAnimatedSpinner from '../../common/animated/spinner';
import { useSite } from '../../../hooks/use-site';

interface ComponentProps {
  onSelect?: (appointment: Appointment) => void;
}

const LoaderContainer = styled.div<{ absolute?: boolean }>`
  ${tw`h-full py-10 flex justify-center items-center `}
  z-index: 950;
`;

function QuestionnaireQuestionCalendarComponent({ onSelect }: ComponentProps) {
  const colStartClasses = [
    'col-start-7',
    'col-start-1',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
  ];
  const today = startOfToday();
  // const date = new Date();
  // const thisMonth = date.getMonth() + 1;

  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMMM-yyyy'));
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [allergists, setAllergists] = useState<Allergist[]>([]);
  const firstDayOfCurrentMonth = parse(currentMonth, 'MMMM-yyyy', new Date());
  const [currentAppointment, setCurrentAppointment] = useState<Appointment | null>(null);
  // const [currentAnswer, setCurrentAnswer] = useState<Question | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);

  const { answerHash, questionValues, allergistId } = useQuestionnaire();

  const [selectedDay, setSelectedDay] = useState(today);
  const [userSelectedDay, setUserSelectedDay] = useState<boolean>(false);
  const [selectedAllergist, setSelectedAllergist] = useState<Allergist>(allergists[questionValues?.allergistId]);
  const [selectedDayAppointments, setSelectedDayAppointments] = useState<Appointment[]>([]);

  const days = eachDayOfInterval({
    start: startOfWeek(firstDayOfCurrentMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayOfCurrentMonth), { weekStartsOn: 1 }),
  });
  const { locale } = useSite();

  const onSelectDay = (day: Date) => {
    setSelectedDay(day);
    setUserSelectedDay(true);
  };

  const onSelectAllergist = (allergist: Allergist) => {
    const fetchAllergistsTime = async () => {
      setCalendarLoading(true);
      const res = await appointmentsApi.getAllergistTime(allergist.id, answerHash);

      setAppointments(res);

      if (questionValues && questionValues.reservationTime) {
        const currAppointment = res.find((a) => a.startTime === questionValues.reservationTime);

        if (currAppointment) {
          setCurrentAppointment(currAppointment);
          onSelectDay(startOfDay(new Date(currAppointment.date)));
        }
      }
      setCalendarLoading(false);
    };

    const drInfo = strings().doctors.find((d: any) => d.imageUrl === allergist.imageKey);

    setSelectedAllergist({ ...allergist, role: drInfo?.role, nameAccusative: drInfo?.nameAccusative });

    fetchAllergistsTime();
  };

  useEffect(() => {
    setLoading(true);
    const fetchAllergists = async () => {
      const response = await appointmentsApi.getAllergists(allergistId);

      if (response) {
        const selectedDr = response.find((d) => d.id === parseInt(questionValues?.allergistId, 10)) || response[0];

        setAllergists(response);
        onSelectAllergist(selectedDr);
      }
    };

    fetchAllergists();

    setLoading(false);
  }, [allergistId, questionValues]);

  useEffect(() => {
    setSelectedDayAppointments(
      appointments.filter((appointment) => isSameDay(parseISO(appointment.startTime), selectedDay)),
    );
  }, [selectedDay, appointments]);

  const onSelectTime = (appointment: OnChangeValue<Appointment, false>) => {
    setCurrentAppointment(appointment);
  };

  const onContinue = () => {
    if (onSelect && currentAppointment) {
      onSelect(currentAppointment);
    }
  };

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  function previousMonth() {
    const firstDayOfNextMonth = add(firstDayOfCurrentMonth, { months: -1 });

    setCurrentMonth(format(firstDayOfNextMonth, 'MMMM-yyyy'));
  }

  function nextMonth() {
    const firstDayOfNextMonth = add(firstDayOfCurrentMonth, { months: 1 });

    setCurrentMonth(format(firstDayOfNextMonth, 'MMMM-yyyy'));
  }

  function doctorPosition(imgName: any) {
    const dr = strings().doctors.find((d: any) => d.imageUrl === imgName);

    return [dr?.role];
  }

  return (
    <div>
      {loading ? (
        <CommonAnimatedLoader />
      ) : (
        <>
          <div className="lg:grid lg:grid-cols-6 mt-3 md:mt-7 mb-3 md:mb-0">
            <div className="col-span-2">
              {allergists && (
                <div className="border border-blue-900 rounded-br-none rounded-bl-none rounded-xl lg:rounded-xl overflow-hidden md:flex lg:block">
                  {allergists.map((allergist) => (
                    <CommonButtonDefault
                      calendar
                      className={`${selectedAllergist?.id === allergist.id && '!bg-blue-900 !text-white'} `}
                      onClick={() => onSelectAllergist(allergist)}
                      key={allergist.id}
                    >
                      <img
                        src={allergist.imageKey ? IMAGES.DOCTORS[allergist.imageKey].IMAGE : undefined}
                        className="transition-all h-12 w-12 lg:h-20 lg:w-20 rounded-full mr-5"
                        alt=""
                      />
                      <div className="flex flex-col">
                        <div>
                          {allergist.firstName} {allergist.lastName}
                        </div>
                        <div className="font-normal text-sm md:text-base">{doctorPosition(allergist.imageKey)}</div>
                      </div>
                    </CommonButtonDefault>
                  ))}
                </div>
              )}
            </div>
            <div className="lg:px-10 col-span-4 pt-5 lg:pt-0 px-3 border border-blue-900 border-t-0 lg:border-0 rounded-bl-xl rounded-br-xl">
              <div className="md:grid md:grid-cols-5 md:divide-x md:divide-gray-200 mb-3">
                <div className={classNames(userSelectedDay && 'hidden md:block', 'md:pr-5 md:col-span-3')}>
                  <h3 className="text-lg font-bold mb-4">
                    {selectedAllergist?.nameAccusative} {strings().calendar.freeTime}{' '}
                  </h3>
                  <div className="md:max-w-[360px]">
                    {calendarLoading ? (
                      <LoaderContainer>
                        <CommonAnimatedSpinner />
                      </LoaderContainer>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <h2 className="flex-auto font-semibold text-gray-900 !capitalize">
                            {getLongMonthName(firstDayOfCurrentMonth.getMonth() + 1, locale)},{' '}
                            {format(firstDayOfCurrentMonth, 'yyy')}
                          </h2>
                          <button
                            onClick={previousMonth}
                            type="button"
                            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">{strings().calendar.previousMonth}</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                          <button
                            onClick={nextMonth}
                            type="button"
                            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">{strings().calendar.nextMonth}</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                          <div>{strings().calendar.weekDays.M}</div>
                          <div>{strings().calendar.weekDays.Tue}</div>
                          <div>{strings().calendar.weekDays.W}</div>
                          <div>{strings().calendar.weekDays.T}</div>
                          <div>{strings().calendar.weekDays.F}</div>
                          <div>{strings().calendar.weekDays.S}</div>
                          <div>{strings().calendar.weekDays.Su}</div>
                        </div>
                        <div className="mt-2 grid grid-cols-7 text-sm">
                          {days.map((day, dayIdx) => (
                            <div
                              key={day.toString()}
                              className={classNames(
                                dayIdx > 6 && 'border-t border-gray-200',
                                dayIdx === 0 && colStartClasses[getDay(day)],
                                'py-1.5',
                              )}
                            >
                              <button
                                type="button"
                                onClick={() => onSelectDay(day)}
                                className={classNames(
                                  isEqual(day, selectedDay) && 'text-white',
                                  !isEqual(day, selectedDay) && isToday(day) && 'text-blue-900',
                                  !isEqual(day, selectedDay) &&
                                    !isToday(day) &&
                                    isSameMonth(day, firstDayOfCurrentMonth) &&
                                    'text-gray-900',
                                  !isEqual(day, selectedDay) &&
                                    !isToday(day) &&
                                    !isSameMonth(day, firstDayOfCurrentMonth) &&
                                    'text-gray-400',
                                  isEqual(day, selectedDay) && isToday(day) && 'bg-blue-900',
                                  isEqual(day, selectedDay) && !isToday(day) && 'bg-blue-900',
                                  !isEqual(day, selectedDay) && 'hover:bg-blue-600',
                                  (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                                )}
                              >
                                <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                              </button>
                              <div className="w-1 h-1 mx-auto mt-1">
                                {appointments.some((appointment) =>
                                  isSameDay(parseISO(appointment.startTime), day),
                                ) && <div className="w-1 h-1 rounded-full bg-sky-500" />}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <section className={`${!userSelectedDay && 'hidden'} mb-4 md:my-0 md:pl-4 lg:block col-span-2`}>
                  {userSelectedDay && (
                    <div className="lg:hidden mb-3">
                      <CommonButtonDefault
                        className="!flex items-center"
                        linkNext
                        onClick={() => setUserSelectedDay(false)}
                      >
                        <ChevronLeftIcon className="h-6 w-6" /> <span>{strings().calendar.showDates}</span>
                        <span className="sr-only">{strings().calendar.showDates}</span>
                      </CommonButtonDefault>
                    </div>
                  )}
                  <h2 className="font-semibold text-gray-900 flex-grow-0">
                    {strings().calendar.titleFreeTime}{' '}
                    <span className="!capitalize inline-block">
                      <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>
                        {format(selectedDay, 'MMMM dd, yyyy', { locale: lt })}
                      </time>
                    </span>
                  </h2>

                  <div className="mt-4 grid grid-cols-2 gap-2">
                    {selectedDayAppointments.length > 0 ? (
                      selectedDayAppointments.map((appointment) => (
                        <div key={appointment.startTime} className="">
                          <button
                            type="button"
                            onClick={() => onSelectTime(appointment)}
                            className={classNames(
                              'content-center w-full px-4 py-2 border border-blue-500 text-sm font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700',
                              appointment.startTime === currentAppointment?.startTime
                                ? 'bg-blue-900 text-white hover:bg-blue-900'
                                : 'hover:bg-blue-600 bg-white',
                            )}
                          >
                            {format(parseISO(appointment.startTime), 'HH:mm')}
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="col-span-2">{strings().calendar.noAppointments}</div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>

          <QuestionnaireFooterButtons disableContinue={!currentAppointment} onContinue={() => onContinue()} />
        </>
      )}
    </div>
  );
}

QuestionnaireQuestionCalendarComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionCalendarComponent;
