import React from 'react';
import tw, { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';

import IMAGES from '../../../assets/images';
import HeadingXl from '../headings/heading1';
import { useSite } from '../../../hooks/use-site';

interface ComponentProps {
  title: string;
  subtitle?: string;
  text?: string;
  isResult?: boolean;
}

const BgImage = styled.img`
  top: 0;
  left: -15%;
  width: 20rem;
  z-index: -1;
  ${tw`absolute`};

  ${up('md')} {
    left: -40px;
    width: 27rem;
  }

  ${up('lg')} {
    left: 40px;
  }
`;

const HeroContainer = styled.div`
  ${tw`relative text-center pt-6 pb-14 md:pb-32`};
  padding-top: 2.4rem;

  ${up('md')} {
    padding-top: 4rem;
  }

  ${up('xl')} {
    padding-top: 9rem;
  }
`;

const TextContainer = styled.div<{ isResult?: boolean }>`
  ${tw`relative z-10 mt-5`};
  ${(props) => (props.isResult ? tw`sm:w-4/5 md:w-3/5 mx-auto` : tw`w-full`)}
`;

function HeroLg({ title, text, subtitle, isResult }: ComponentProps) {
  const { isAllergomedica } = useSite();

  return (
    <HeroContainer>
      <HeadingXl>{title}</HeadingXl>
      <TextContainer isResult={isResult}>
        {subtitle}
        {text && <div className={`banner ${isResult ? 'mt-7' : 'mt-5'}`}>{text}</div>}
      </TextContainer>
      {isAllergomedica && <BgImage src={IMAGES.BLOB.FIRST} alt="" aria-hidden="true" />}
    </HeroContainer>
  );
}
HeroLg.defaultProps = {
  subtitle: '',
  text: '',
  isResult: false,
};

export default HeroLg;
