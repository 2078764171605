import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { strings } from '../../../localization/strings';
import IMAGES from '../../../assets/images';
import CommonButtonDefault from '../../common/buttons/default';
import OrderProgressComponent from '../../order/progress';
import { Question } from '../../../entities/question';
import { Patient } from '../../../entities/patient';
import { getPatientDoctor } from '../../../services/api/patients';

interface ComponentProps {
  onContinue?: () => void;
  question: Question;
  hasProduct: boolean;
  isConsultation: boolean;
  isResearch: boolean;
  isCheckAllergy: boolean;
  isImmunotherapy: boolean;
  isImmunotherapyConsultation: boolean;
  isAnamnesis: boolean;
  hideSteps: boolean;
}

function QuestionnaireQuestionDescriptionComponent({
  question,
  onContinue,
  hasProduct,
  isConsultation,
  isResearch,
  isCheckAllergy,
  isImmunotherapy,
  isImmunotherapyConsultation,
  isAnamnesis,
  hideSteps,
}: ComponentProps) {
  const onButtonClick = () => {
    if (onContinue) {
      onContinue();
    }
  };

  const highlightStars = (text: string) => {
    return {
      __html: text.replace(/\*(.*?)\*/g, '<span class="text-blue-800">$1</span>'),
    };
  };

  let button = strings().button.continue;

  if (isResearch) {
    button = strings().button.start;
  }

  const { patientHash } = useParams();
  const [error, setError] = useState<any>();

  const [patient, setPatient] = useState<Patient>();
  const [drPosition, setDrPosition] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (patientHash) {
      setIsLoading(true);
      const getPatient = async () => {
        try {
          const res = await getPatientDoctor(patientHash);

          if (res) {
            setPatient(res);
            const dr = strings().doctors.find((doctor: any) => doctor.imageUrl === res.doctorImageKey);

            setDrPosition(dr?.role);
          }
          setIsLoading(false);
        } catch (e: any) {
          setIsLoading(false);
          setError(e.message);
        }
      };

      getPatient();
    }
  }, []);

  return (
    <div className="text-center">
      {/* eslint-disable react/no-danger */}
      {question.description && !isCheckAllergy && !isAnamnesis && (
        <div className="mb-4" dangerouslySetInnerHTML={highlightStars(question.description)} />
      )}
      {isCheckAllergy && <div className="mb-4">{strings().questionnaireCheckAllergyIntro}</div>}
      {hasProduct && !hideSteps && (
        <div className="mt-10 mb-10 sm:mt-20">
          <OrderProgressComponent
            isConsultation={isConsultation}
            isResearch={isResearch}
            isImmunotherapy={isImmunotherapy}
            isImmunotherapyConsultation={isImmunotherapyConsultation}
            current={0}
          />
        </div>
      )}
      {!hasProduct && !isCheckAllergy && !isAnamnesis && (
        <div className="z-10 relative mt-10">{strings().questionnaireGenericIntro}</div>
      )}
      {isAnamnesis && (
        <>
          <div className="z-10 relative mb-7 lg:px-10">{strings().anamnesis.questionnaire.description}</div>
          <div className="">
            {patient && (
              <div className="inline-flex items-center mx-auto">
                {patient.doctorImageKey && (
                  <img
                    src={IMAGES.DOCTORS[patient.doctorImageKey as keyof typeof IMAGES.DOCTORS].IMAGE}
                    alt=""
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20 relative"
                  />
                )}

                <div className="font-medium pl-4 text-left">
                  <div className="font-bold">
                    {patient.doctorFirstName} {patient.doctorLastName}
                  </div>
                  <p className="text-md">{drPosition}</p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <CommonButtonDefault
        className="group mx-auto w-full sm:w-80 mt-3 md:mt-12"
        onClick={() => onButtonClick()}
        primary
      >
        <span className="relative">
          <span className="inline-block pr-3">{button}</span>
          <img
            src={IMAGES.ICONS.ARROWRIGHT}
            className="transition-all absolute -right-4 top-1/2 -mt-2 group-hover:-right-5"
            alt=""
          />
        </span>
      </CommonButtonDefault>
      <div className="text-center mt-5">{strings().questionnaireDuration}</div>
    </div>
  );
}

QuestionnaireQuestionDescriptionComponent.defaultProps = {
  onContinue: null,
};

export default QuestionnaireQuestionDescriptionComponent;
