import React from 'react';

interface ComponentProps {
  total: number;
  current: number;
}

export default function QuestionnaireProgressBar({ current, total }: ComponentProps) {
  const width = (current * 100) / total;

  return (
    <div className="w-full bg-content-300 fixed top-0 left-0">
      <div className="bg-blue-500 h-1 md:h-2" style={{ width: `${width}%`, transition: 'width 500ms' }} />
    </div>
  );
}
