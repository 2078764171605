import React from 'react';

import { ModalKeys } from '../../context/modals';
import { parameters, strings } from '../../localization/strings';
import ModalText from './text-modal';
import ExternalText from '../text/external-text';

export default function ModalTermsConditions() {
  return (
    <ModalText type={ModalKeys.Terms} title={strings().textPage.termsAndConditions.title}>
      <ExternalText url={parameters().textPage.terms} />
    </ModalText>
  );
}
