import React from 'react';
import tw, { styled } from 'twin.macro';
import { ChevronRightIcon } from '@heroicons/react/solid';

import { strings } from '../../../localization/strings';
import { FullAnswerSymptom } from '../../../entities/full-answer';
import { accusative } from '../../../services/grammar/lithuanian/singular';
import { allWords } from '../../../services/formatter/text';
import { useSite } from '../../../hooks/use-site';

interface ComponentProps {
  title: string;
  strength: number;
  iconName: string;
  symptoms?: FullAnswerSymptom[];
}

const Icon = styled.div<{ strength: number }>`
  ${tw`h-8 w-8 sm:h-12 sm:w-12 text-2xl sm:text-4xl rounded-full text-white justify-center inline-flex items-center border print:hidden`}
  ${(props) => props.strength >= 3 && tw`bg-senseHigh-500 border-senseHigh-500`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-500 border-senseMedium-500`}
  ${(props) => props.strength === 1 && tw`bg-senseLow-500 border-senseLow-500`}
  ${(props) => props.strength <= 0 && tw`text-content-700 border-content-500`}
`;

const Container = styled.div<{ strength: number }>`
  ${tw`flex  items-center text-left rounded-lg md:rounded-2xl p-3 sm:py-7 sm:px-6 mb-3 md:mb-4 bg-white text-content-700 border-content-500 border w-full`}
  ${tw`print:!p-0 print:!bg-white print:border-0 print:!text-black`}
  ${(props) => props.strength >= 3 && tw`bg-senseHigh-50 border-senseHigh-50 text-senseHigh-500`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-50 border-senseMedium-50 text-senseMedium-500`}
  ${(props) => props.strength === 1 && tw`bg-senseLow-50 border-senseLow-50 text-senseLow-500`}
`;

const ChevronIcon = styled(ChevronRightIcon)<{ strength: number }>`
  ${tw`text-content-700 h-5 sm:h-10 font-light`}
  ${(props) => props.strength >= 3 && tw`text-senseHigh-500`}
  ${(props) => props.strength === 2 && tw`text-senseMedium-500`}
  ${(props) => props.strength === 1 && tw`text-senseLow-500`}
`;

const Heading = styled.div`
  ${tw`font-semibold text-md sm:text-xl print:!text-lg`}
`;

const Subheading = styled.div`
  ${tw`font-normal text-sm`}
`;

function SourceGroup({ strength, title, iconName, symptoms }: ComponentProps) {
  const { locale } = useSite();

  return (
    <Container strength={strength}>
      <Icon strength={strength} aria-hidden="true">
        <span className={`icon am-icon-${iconName}`} />
      </Icon>
      <div className="ml-2 md:ml-6 print:!ml-0">
        <Heading>{title}</Heading>
        <Subheading>
          {strings().test_answer.sensitivity[strength]}
          {symptoms &&
            (symptoms.length > 0 ? (
              <>
                {strings().test_answer.sensitivitySuffix}
                <span className="font-bold">
                  {symptoms.length > 3
                    ? strings().test_answer.manySymptoms
                    : symptoms
                        ?.map((symptom) =>
                          symptom.titleKa && locale === 'pl'
                            ? symptom.titleKa.toLowerCase()
                            : allWords(symptom.title.toLowerCase(), accusative, locale),
                        )
                        .join(', ')}
                </span>
              </>
            ) : (
              strings().test_answer.noSymptoms
            ))}
        </Subheading>
      </div>
      <div className="ml-auto flex-shrink-0 print:hidden">
        <ChevronIcon strength={strength} />
      </div>
    </Container>
  );
}

SourceGroup.defaultProps = {
  symptoms: [],
};

export default SourceGroup;
