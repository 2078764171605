import React from 'react';
import tw, { styled } from 'twin.macro';

import { strings } from '../../localization/strings';
import Heading2 from '../common/headings/heading2';
import IMAGES from '../../assets/images';

const Image = styled.img`
  width: 250px;
  height: auto;
  ${tw`mx-auto mb-4`};
`;

export default function AnamnesisSuccessComponent() {
  return (
    <div className="md:w-9/12 mx-auto">
      <div className="mb-6 md:mb-10 text-center">
        <Image src={IMAGES.ANAMNESIS.SUCCESS} alt="" />
        <Heading2 className="mb-6">{strings().anamnesis.questionnaire.successTitle}</Heading2>
      </div>
    </div>
  );
}
