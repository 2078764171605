import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  link?: boolean;
  linkNext?: boolean;
  disabled?: boolean;
  wide?: boolean;
  chat?: boolean;
  calendar?: boolean;
  sm?: boolean;
}

const Button = styled.button<ComponentProps>`
  ${tw`block text-base rounded-2xl py-3.5 font-bold`}
  ${tw`bg-content-300 text-content-900 hover:bg-content-400`}
  ${(props) => props.primary && tw`!no-underline bg-blue-800 text-white hover:bg-blue-900`}
  ${(props) => props.disabled && tw`opacity-25`}
  ${(props) => (props.wide ? tw`px-28` : tw`px-7 sm:px-9`)}
  ${(props) => props.chat && tw`p-2 sm:px-4 my-2 rounded-lg text-sm`}
     ${(props) =>
    props.linkNext &&
    tw`!p-0 text-blue-800 inline-block no-underline leading-5 bg-transparent hover:bg-transparent hover:underline hover:text-blue-500 rounded-none`}
   ${(props) =>
    props.link
      ? tw`!p-0 text-gray-900 inline-block text-[14px] sm:text-base leading-6 bg-transparent hover:bg-transparent underline hover:text-blue-500 font-normal rounded-none`
      : tw`no-underline`}
        ${(props) =>
    props.calendar &&
    tw`py-2 md:py-3.5 !px-4 text-left rounded-none flex items-center w-full bg-white hover:bg-blue-600`}
  ${(props) => props.sm && tw`!px-6 py-2 text-sm rounded-lg`}
`;

function CommonButtonDefault({ children, ...rest }: ComponentProps) {
  return <Button {...rest}>{children}</Button>;
}

CommonButtonDefault.defaultProps = {
  primary: false,
  disabled: false,
  link: false,
  linkNext: false,
  wide: false,
  chat: false,
  calendar: false,
  sm: false,
};

export default CommonButtonDefault;
