import axios from 'axios';

import { parameters } from '../../localization/strings';
import { Location } from '../../entities/location';

export const getList = async (): Promise<Location[]> => {
  const url = `${parameters().api.baseUrl}/locations/list`;

  const result = await axios.get(url);

  return result.data;
};

export const getListByProduct = async (productId: number): Promise<Location[]> => {
  const url = `${parameters().api.baseUrl}/locations/list-by-product/${productId}`;

  const result = await axios.get(url);

  return result.data;
};
