import React from 'react';
import { useNavigate } from 'react-router-dom';

import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';
import Heading2 from '../common/headings/heading2';
import Heading3 from '../common/headings/heading3';
import { Location } from '../../entities/location';
import { formatPhone } from '../../services/formatter/phone';
import ContainerMd from '../common/containers/containerMd';
import AlertXl from '../common/alerts/alert-xl';
import { accusative } from '../../services/grammar/lithuanian/singular';

interface ComponentProps {
  location: Location;
}

function BloodRegistrationSuccessMessage({ location }: ComponentProps) {
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <ContainerMd>
      <AlertXl textXL title={strings().bloodRegistration.completedTitle} />

      <Heading3 className="text-center mt-7 md:mt-10">
        {strings()
          .bloodRegistration.completedLocation.replace('{title}', accusative(location.partnerName))
          .replace('{address}', location.address)}
      </Heading3>
      <Heading2 className="text-center mt-5 md:mt-10">
        {strings().bloodRegistration.completedFurtherAction}
        <br />
        {formatPhone(location.phone)}
        <br />
        {strings().bloodRegistration.completeFurtherActionSuffix}
      </Heading2>
      <div className="text-content-700">{strings().dashboard.bloodRegistration.completedDescription}</div>
      <div className="text-center sm:w-3/5 mx-auto mt-7 md:mt-10">
        <CommonButtonDefault className="w-full" primary onClick={() => onGoBack()}>
          {strings().button.understood}
        </CommonButtonDefault>
      </div>
    </ContainerMd>
  );
}

export default BloodRegistrationSuccessMessage;
