import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

import Heading2 from '../headings/heading2';
import Heading1 from '../headings/heading1';

interface ComponentProps {
  title?: string;
  text?: string;
  type?: string;
  marginTop?: string;
  textXL?: boolean;
}

function AlertXl({ title, text, type, marginTop, textXL }: ComponentProps) {
  return (
    <div className={`text-center ${type === 'danger' && 'bg-red-50'} ${marginTop}`}>
      <div className="mx-auto flex items-center justify-center h-12 w-12 md:h-32 md:w-32 rounded-full bg-green-600 mb-4 md:mb-7">
        <CheckIcon className="h-12 w-12 sm:h-16 sm:w-16 md:h-20 md:w-20 text-white" aria-hidden="true" />
      </div>

      {title && textXL === false && <Heading2>{title}</Heading2>}
      {textXL && title && <Heading1 className="mt-5">{title}</Heading1>}
      {text && <div className="mt-1">{text}</div>}
    </div>
  );
}
AlertXl.defaultProps = {
  type: 'primary',
  title: '',
  text: '',
  marginTop: 'mt-0',
  textXL: false,
};

export default AlertXl;
