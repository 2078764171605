import React, { useCallback, useEffect, useRef, useState } from 'react';

interface ComponentProps {
  children: React.ReactNode;
}

function ScreenHeightContainer({ children }: ComponentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(200);

  const updateHeight = useCallback(
    (element: HTMLDivElement) => {
      const bodyPos = document.body.getBoundingClientRect();
      const elementPos = element.getBoundingClientRect();

      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      setHeight(vh - elementPos.top - bodyPos.top);
    },
    [setHeight],
  );

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current);
    }
  }, [ref, updateHeight]);

  useEffect(() => {
    const listener = () => {
      if (ref.current) {
        updateHeight(ref.current);
      }
    };

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [updateHeight, ref]);

  return (
    <div ref={ref} style={{ height }}>
      {children}
    </div>
  );
}

export default ScreenHeightContainer;
