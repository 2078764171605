import axios from 'axios';

import { StatusResponse } from '../../entities/status-response';
import { parameters } from '../../localization/strings';

export const updateStatus = async (
  token: string,
  orderId: number,
  recommendationId: number,
  status: string,
): Promise<StatusResponse> => {
  const url = `${parameters().api.baseUrl}/recommendations/update-status`;

  const result = await axios.post(
    url,
    {
      orderId,
      recommendationId,
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};
