export const multiWord = (input: string, callback: any): string => {
  const delimiters = [' ', '-'];

  if (!input) {
    return input;
  }

  let res = input;

  delimiters.forEach((delimiter) => {
    res = res
      .split(delimiter)
      .map((part) => {
        return callback(part);
      })
      .join(delimiter);

    // input = parts.join(delimiter);
  });

  return res;
};

const kreipinys = (input: string) => {
  const root = input.slice(0, -2);
  const lastChar = input.slice(-1);
  const suffix = input.slice(-2);

  switch (suffix) {
    case 'as':
      return `${root}ai`;
    case 'us':
      return `${root}au`;
    case 'is':
      return `${root}i`;
    case 'ys':
      return `${root}y`;
    default:
      return lastChar === 'ė' ? `${input.slice(0, -1)}e` : input;
  }
};

export const kreipinysVardoPavardes = (input: string) => {
  return multiWord(input, kreipinys);
};
