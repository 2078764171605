export const ending = (input: string): string => {
  let word = input;

  if (word.toLowerCase() === 'duktė') {
    word = `${word.substring(0, word.length - 2)}ra`;
  }

  if (word.toLowerCase() === 'marti') {
    word = `${word.substring(0, word.length - 2)}čia`;
  }

  if (word.toLowerCase() === 'sesuo') {
    word = `${word.substring(0, word.length - 3)}ė`;
  }

  switch (word.substring(word.length - 1)) {
    case 'a':
      return 'a';

    case 'ė':
      if (word.substring(word.length - 2) === 'tė') {
        return 'tė';
      }

      return 'ė';

    case 's':
      switch (word.substring(word.length - 2)) {
        case 'as':
          if (word.substring(word.length - 4) === 'čias') {
            return 'čias';
          }
          if (word.substring(word.length - 3) === 'jas') {
            return 'jas';
          }
          if (word.substring(word.length - 3) === 'ias') {
            return 'ias';
          }

          return 'as';

        case 'us':
          if (word.substring(word.length - 3) === 'ius') {
            return 'ius';
          }

          return 'us';

        case 'is':
          if (word.substring(word.length - 3) === 'tis') {
            return 'tis';
          }
          if (word.substring(word.length - 3) === 'dis') {
            return 'dis';
          }
          if (word.substring(word.length - 3) === 'sis') {
            return 'sis';
          }

          return 'is';

        case 'ys':
          if (word.substring(word.length - 3) === 'jys') {
            return 'jys';
          }
          if (word.substring(word.length - 3) === 'dys') {
            return 'dys';
          }

          return 'ys';

        default:
          return '';
      }

    case 'o':
      if (word.substring(word.length - 3) === 'šuo') {
        return 'šuo';
      }
      if (word.substring(word.length - 3) === 'muo') {
        return 'muo';
      }
      if (word.substring(word.length - 3) === 'duo') {
        return 'duo';
      }
      if (word.substring(word.length - 3) === 'suo') {
        return 'suo';
      }

      return '';
    default:
      return '';
  }
};
