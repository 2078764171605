import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import Cookies from 'universal-cookie';

import Heading2 from '../../components/common/headings/heading2';
import { strings, parameters } from '../../localization/strings';
import CommonButtonDefault from '../../components/common/buttons/default';
import { getPatients } from '../../services/api/patients';
import { useAuth } from '../../hooks/use-auth';
import { Patient } from '../../entities/patient';
import StyledError from '../../components/common/form/styled-error';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import IMAGES from '../../assets/images';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import * as questionnaireApi from '../../services/api/questionnaire';
import { toLatin } from '../../services/formatter/text';
import { useSite } from '../../hooks/use-site';

const BgImage = styled.img`
  top: 0;
  width: 20rem;
  height: auto;
  ${tw`absolute z-0 left-[-6rem]`};
`;

function UsersSelectPatientPageComponent() {
  type Inputs = {
    selectedPatientId: number;
  };

  const cookies = new Cookies();

  const hash = cookies.get(COOKIE_NAME_ANSWER);

  const navigate = useNavigate();
  const { token, user } = useAuth();
  const { hasPresale, theme } = useSite();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[]>([]);

  const [firstName, setFirstName] = useState<string>('');
  const [age, setAge] = useState<number>(0);
  const [isNewPatientRequired, setIsNewPatientRequired] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>();

  useEffect(() => {
    const fetchData = async (h: string) => {
      setIsLoading(true);

      const [res, patientsList] = await Promise.all([questionnaireApi.answer(h), getPatients(token)]);

      if (res) {
        setProductId(res.product?.id);
      }

      let patientName = '';

      if (res.name && res.isChild) {
        patientName = res.name;
      } else if (user?.firstName) {
        patientName = user?.firstName;
      }

      setFirstName(patientName);

      if (res.age) {
        setAge(Number(res.age));
      }

      if (patientsList.length === 0) {
        navigate(parameters().createPatient);
      }
      setPatients(patientsList);

      const hasExistingPatient = patientsList.find(
        (item) => toLatin(item.name) === toLatin(patientName) && item.isChild === res.isChild,
      );

      setIsNewPatientRequired(!hasExistingPatient);

      setIsLoading(false);
    };

    if (hash) {
      fetchData(hash);
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);

      const patientId = Number(data.selectedPatientId);

      const productIds = [12, 13, 15, 17, 20];

      if (productId && productIds.includes(productId) && hasPresale) {
        navigate(`/questionnaire/select-review/${hash}/${patientId}`);
      } else if (patientId && hash) {
        navigate(`/payments/checkout/${hash}/${patientId}`);
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError('selectedPatientId', { message: e.message });
      }
    }
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="mx-auto w-full lg:w-4/12 relative py-12 lg:py-20 xl:my-30">
        {theme === 'allergomedica' && <BgImage src={IMAGES.BLOB.FIRST} alt="" aria-hidden="true" />}
        <div className="relative z-10">
          <Heading2 className="mb-8">{strings().selectPatient.title}</Heading2>
          {isNewPatientRequired && (
            <Link className="no-underline" to={parameters().createPatient}>
              <div className="mb-4">
                <div
                  className={classNames(
                    'relative border border-content-400 rounded-3 rounded-lg bg-white hover:border-blue-800',
                  )}
                >
                  <label className="font-medium text-gray-700 flex items-center px-3 py-5">
                    <div className="flex h-5 items-center mr-3">
                      <input type="radio" className="h-4 w-4 border-gray-300 text-blue-800 focus:ring-blue-800" />
                    </div>
                    {age && age !== 0 ? (
                      <span>
                        {firstName} {strings().years.replace('{age}', age)}
                      </span>
                    ) : (
                      <div>{firstName}</div>
                    )}
                    <span className="text-gray-400 pl-2 inline-block text-sm">
                      ({strings().selectPatient.addNewPatient})
                    </span>
                  </label>
                </div>
              </div>
            </Link>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            {patients &&
              patients.map((patient, settingIdx) => (
                <div key={`selectPatient-${settingIdx + 1}`} className="mb-4">
                  <div
                    className={classNames(
                      'relative border border-content-400 rounded-3 rounded-lg bg-white hover:border-blue-800',
                      {
                        'bg-blue-650': patient.id === Number(watch('selectedPatientId')),
                      },
                    )}
                  >
                    <label className="font-medium text-gray-700 flex items-center px-3 py-5">
                      <div className="flex h-5 items-center mr-3">
                        <input
                          type="radio"
                          value={patient.id}
                          {...register('selectedPatientId', { required: true })}
                          id={`patient-${patient.id}`}
                          className="h-4 w-4 border-gray-300 text-blue-800 focus:ring-blue-800"
                        />
                      </div>
                      {patient.age && patient.age !== 0 ? (
                        <span>
                          {patient.name} {patient.lastName} {strings().years.replace('{age}', patient.age)}
                        </span>
                      ) : (
                        <div>
                          {patient.name} {patient.lastName}
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              ))}
            {errors.selectedPatientId && <StyledError>{strings().error.selectedPatientId.required}</StyledError>}

            <div className="mt-3">
              <Link to={parameters().createPatient}>
                <CommonButtonDefault linkNext className="text-[14px] font-bold">
                  + {strings().selectPatient.addNewPatient}
                </CommonButtonDefault>
              </Link>
            </div>
            <div className="mt-10">
              <CommonButtonDefault
                primary
                type="submit"
                disabled={!watch('selectedPatientId')}
                onClick={() => clearErrors('selectedPatientId')}
              >
                {strings().selectPatient.button}
              </CommonButtonDefault>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UsersSelectPatientPageComponent;
