import React from 'react';
import { useParams } from 'react-router-dom';

import { QuestionnaireContextProvider } from '../../context/questionnaire';
import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';

export default function QuestionnaireRepeatPageComponent() {
  const { orderHash, productId, extraSurveyId } = useParams();
  let intProductId = 0;
  let intExtraSurveyId = 0;

  if (typeof productId === 'string') {
    intProductId = parseInt(productId, 10);
  }

  if (typeof extraSurveyId === 'string') {
    intExtraSurveyId = parseInt(extraSurveyId, 10);
  }

  return (
    <QuestionnaireContextProvider
      type={QuestionnaireType.Onboarding}
      productId={intProductId}
      repeatOrderHash={orderHash}
      extraSurveyId={intExtraSurveyId}
    >
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
