import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import tw, { styled } from 'twin.macro';
import { useNavigate, useParams } from 'react-router-dom';

import { strings } from '../../../localization/strings';
import ContainerMd from '../../../components/common/containers/containerMd';
import { COOKIE_NAME_ANSWER } from '../../../context/questionnaire';
import { useLayout } from '../../../hooks/use-layout';
import Heading2 from '../../../components/common/headings/heading2';
import IMAGES from '../../../assets/images';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { ModalKeys } from '../../../context/modals';
import { useModal } from '../../../hooks/use-modal';
import { instrumental } from '../../../services/grammar/lithuanian/singular';
import { multiWord } from '../../../services/grammar/lithuanian';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { answer, storeAnamnesisAnswer } from '../../../services/api/questionnaire';
import { getPatientDoctor } from '../../../services/api/patients';
import { Patient } from '../../../entities/patient';
import AnamnesisSuccessComponent from '../../../components/anamnesis/successAnamnesis';

const cookies = new Cookies();

const Image = styled.img`
  width: 250px;
  height: auto;
  ${tw`mx-auto mb-4`};
`;

export default function AnamnesisSubmitPageComponent() {
  const { setMenuLogoState } = useLayout();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [patient, setPatient] = useState<Patient>();
  const [success, setSuccess] = useState<boolean>(false);
  const [isSecond, setIsSecond] = useState<boolean>(false);

  const { patientHash } = useParams();
  const answerHash = cookies.get(COOKIE_NAME_ANSWER);

  const { setVisible } = useModal();

  useEffect(() => {
    setMenuLogoState(false);
  }, [setMenuLogoState]);

  useEffect(() => {
    if (answerHash) {
      const getAnswer = async () => {
        try {
          setIsLoading(true);
          const res = await answer(answerHash);

          if (res.isFirstVisit === false) {
            setIsSecond(true);
          }
          setIsLoading(false);
        } catch (e: any) {
          setError(e.message);
          setIsLoading(false);
        }
      };

      getAnswer();
    }

    if (patientHash) {
      const getPatient = async () => {
        try {
          setIsLoading(true);
          const res = await getPatientDoctor(patientHash);

          if (res) {
            setPatient(res);
          }
          setIsLoading(false);
        } catch (e: any) {
          setError(e.message);
          setIsLoading(false);
        }
      };

      getPatient();
    }
  }, []);

  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  const onSubmitQuestionnaire = async () => {
    try {
      if (patientHash && answerHash) {
        setIsLoading(true);
        const res = await storeAnamnesisAnswer(patientHash, answerHash);

        if (res) {
          cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });

          setSuccess(true);
          setIsLoading(false);
        }
      } else {
        setError(strings().error.backendError);
      }
    } catch (e) {
      setError((e as Error).message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && (
        <ContainerMd>
          {success ? (
            <AnamnesisSuccessComponent />
          ) : (
            <div className="md:w-9/12 mx-auto">
              <div className="mb-6 md:mb-10 text-center">
                <Image src={IMAGES.ANAMNESIS.SEND} alt="" />
                {isSecond ? (
                  <div>
                    <Heading2>{strings().anamnesis.questionnaire.submit.isSecond.title}</Heading2>
                    <div>{strings().anamnesis.questionnaire.submit.isSecond.title2}</div>
                    <div className="flex justify-center mt-7 mb-5">
                      <CommonButtonDefault primary onClick={() => onSubmitQuestionnaire()}>
                        {strings().button.complete}
                      </CommonButtonDefault>
                    </div>
                  </div>
                ) : (
                  <>
                    <Heading2 className="mb-6">{strings().anamnesis.questionnaire.submit.title}</Heading2>
                    {error && (
                      <div className="mb-5 text-left">
                        <AlertPrimary type="danger" title={strings().anamnesis.questionnaire.error} text={error} />
                      </div>
                    )}
                    <div className="mt-7 mb-5 flex justify-center space-x-4">
                      <CommonButtonDefault onClick={() => onGoBack()}>{strings().button.goBack}</CommonButtonDefault>
                      <CommonButtonDefault primary onClick={() => onSubmitQuestionnaire()}>
                        {strings().button.send}
                      </CommonButtonDefault>
                    </div>
                  </>
                )}
                <div>
                  {isSecond
                    ? strings().anamnesis.questionnaire.submit.privacypolicy1IsSecond
                    : strings().anamnesis.questionnaire.submit.privacypolicy1}{' '}
                  {patient && multiWord(`${patient.doctorFirstName} ${patient.doctorLastName}`, instrumental)}{' '}
                  {strings().anamnesis.questionnaire.submit.privacypolicy2}{' '}
                  <CommonButtonDefault link onClick={() => onOpenModal(ModalKeys.ClinicPrivacyPolicy)}>
                    {strings().anamnesis.questionnaire.submit.privacypolicyLinkTex}
                  </CommonButtonDefault>{' '}
                  {strings().anamnesis.questionnaire.submit.termsAndConditions1}{' '}
                  <CommonButtonDefault link onClick={() => onOpenModal(ModalKeys.ClinicTerms)}>
                    {strings().anamnesis.questionnaire.submit.termsAndConditionsLinkText}
                  </CommonButtonDefault>
                </div>
              </div>
            </div>
          )}
        </ContainerMd>
      )}
    </>
  );
}
