import React, { useEffect, useState } from 'react';

import { getTextContent } from '../../services/api/texts';
import CommonAnimatedSpinner from '../common/animated/spinner';

export default function TextTreatmentProtocol() {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    const loadText = async () => {
      const content = await getTextContent('imunoterapijos-sutikimas');

      setText(content.content);
    };

    loadText();
  }, [setText]);

  if (!text.length) {
    return (
      <div className="text-center">
        <CommonAnimatedSpinner />
      </div>
    );
  }

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
}
