import axios from 'axios';

import { parameters } from '../../localization/strings';
import Product from '../../entities/product';

export const getProducts = async (): Promise<Product[] | null> => {
  const result = await axios.get(`${parameters().api.baseUrl}/products/list`);

  return result.data;
};
