import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import UsersRegisterFormComponent from '../../components/users/register-form';
import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';
import Heading3 from '../../components/common/headings/heading3';
import { useSite } from '../../hooks/use-site';

const BgImage = styled.img`
  top: 0;
  width: 18rem;
  height: auto;
  ${tw`absolute z-0 left-[-2rem] md:left-[-1rem]`};
`;

function QuestionnaireRegisterPageComponent() {
  useEffect(() => {
    if (window.fbq) {
      window.fbq('trackCustom', 'SubmitQuestionnaire');
    }

    window.dataLayer.push({
      event: 'SubmitQuestionnaire',
    });
  }, []);

  const { isAllergomedica } = useSite();

  return (
    <div className="min-h-full md:flex items-center justify-center py-10 md:py-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl relative">
      {isAllergomedica && <BgImage src={IMAGES.BLOB.FIRST} alt="" aria-hidden="true" />}
      <div className="md:w-3/5 z-10 relative border-b md:border-b-0 pb-10 md:pb-0 md:border-r border-gray-200 md:pr-20">
        <UsersRegisterFormComponent isQuestionnaire />
      </div>
      <div className="mt-10 md:mt-0 md:w-2/5 relative z-10 md:pl-20">
        <Heading3 className="mb-6"> {strings().registration.haveAccount}</Heading3>
        <NavLink
          key="login"
          to="/questionnaire/login"
          className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
        >
          {strings().registration.login}
        </NavLink>
      </div>
    </div>
  );
}

export default QuestionnaireRegisterPageComponent;
