import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import IMAGES from '../../assets/images';
import { useLayout } from '../../hooks/use-layout';
import { parameters, strings } from '../../localization/strings';
import { useAuth } from '../../hooks/use-auth';
import { useSite } from '../../hooks/use-site';

export default function LayoutHeadingComponent() {
  const { hasMenu, hasLogo, setMenuState, hasProgressBar, isSticky, isPageNotFound } = useLayout();
  const { user, signout } = useAuth();
  const navigate = useNavigate();
  const { isfoodId } = useSite();

  const onSignoutClick = () => {
    signout();
    setMenuState(false);
    navigate('/');
  };

  let resultLink = '';
  let completedOrder = user?.orders.find((o) => o.status === 'completed' && o.productKey !== 'TREATMENT_PLAN');

  if (!completedOrder && user?.orders.length) {
    if (user?.orders[0].productKey !== 'TREATMENT_PLAN') {
      completedOrder = user?.orders[0];
    }
  }

  if (completedOrder) {
    resultLink = `/profile/results/${completedOrder.hash}${
      completedOrder.productKey === 'CONSULTATION' ? '/recommendations' : ''
    }`;
  }

  const links = [
    {
      title: strings().navigation.consultations,
      href: '/profile/messages',
      comingSoon: false,
    },
    {
      title: strings().navigation.result,
      href: resultLink,
      comingSoon: !resultLink,
    },
    {
      title: strings().navigation.orders,
      href: '/profile/dashboard',
      comingSoon: false,
    },
  ];

  return (
    <Disclosure
      as="nav"
      id="nav"
      className={classNames({
        'print:no-shadow print:!border-0 print:border-transparent shadow bg-white z-10 relative': hasMenu,
        'absolute w-full top left': isSticky,
      })}
    >
      {({ open, close }) => (
        <>
          <div
            className={classNames('max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-998 relative', {
              'mt-1 md:mt-2': hasProgressBar,
            })}
          >
            <div className="flex justify-between h-16">
              <div className="flex justify-between w-full">
                {hasLogo && (
                  <div className="flex-shrink-0 flex items-center">
                    {isfoodId ? (
                      <a className="py-2" href={hasMenu || isPageNotFound ? parameters().appUrl : parameters().appUrl}>
                        <span className="sr-only">Food Id</span>
                        <img className="h-6 w-auto" src={IMAGES.LOGOTYPES.FOODID_LOGO} alt="Food Id" />
                      </a>
                    ) : (
                      <a className="py-2" href={hasMenu || isPageNotFound ? parameters().appUrl : parameters().appUrl}>
                        <span className="sr-only">AllergoMedica</span>
                        <img className="h-8 w-auto" src={IMAGES.LOGOTYPES.PRIMARY} alt="AllergoMedica" />
                      </a>
                    )}
                  </div>
                )}
                {hasMenu && (
                  <>
                    <div className="print:hidden hidden sm:ml-6 sm:flex sm:space-x-8">
                      {links.map((link) => {
                        return link.comingSoon ? (
                          <span
                            className="nav-link border-transparent no-underline text-gray-300 print:hidden inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            key={link.href}
                          >
                            {link.title}
                            {user?.orders !== undefined &&
                              user?.orders.length > 0 &&
                              user?.orders[0].productKey !== 'TREATMENT_PLAN' && (
                                <span className="print:hidden inline-flex items-center ml-3 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  {strings().navigation.comingSoon}
                                </span>
                              )}
                          </span>
                        ) : (
                          <NavLink
                            key={link.href}
                            to={link.href}
                            className="nav-link border-transparent no-underline text-gray-500 hover:border-gray-300 hover:text-gray-700 print:hidden inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          >
                            {link.title}
                          </NavLink>
                        );
                      })}
                    </div>
                    <div className="print:hidden hidden sm:ml-6 sm:flex sm:items-center">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3">
                        <div>
                          <Menu.Button className="bg-white rounded-full print:hidden flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span className="sr-only">Open user menu</span>
                            <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                              <span className="text-xs font-medium leading-none text-white">
                                {user?.firstName && user?.firstName.substring(0, 1).toUpperCase()}
                              </span>
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 z-50 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {/* <Menu.Item> */}
                            {/*  {({ active }) => ( */}
                            {/*    <a */}
                            {/*      href="#" */}
                            {/*      className={classNames( */}
                            {/*        active ? 'bg-gray-100' : '', */}
                            {/*        'block px-4 py-2 text-sm text-gray-700', */}
                            {/*      )} */}
                            {/*    > */}
                            {/*      Your Profile */}
                            {/*    </a> */}
                            {/*  )} */}
                            {/* </Menu.Item> */}
                            <Menu.Item onClick={onSignoutClick}>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 no-underline',
                                  )}
                                >
                                  {strings().navigation.signOut}
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="print:hidden -mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">{strings().navigation.openMainMenu}</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="print:hidden sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {links.map((link) => {
                return link.comingSoon ? (
                  <span
                    className="border-transparent text-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium no-underline"
                    key={link.href}
                  >
                    {link.title}

                    <span className="inline-flex items-center ml-3 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {strings().navigation.comingSoon}
                    </span>
                  </span>
                ) : (
                  <NavLink
                    as="a"
                    onClick={() => close()}
                    key={link.href}
                    to={link.href}
                    className="nav-link border-transparent no-underline text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  >
                    {link.title}
                  </NavLink>
                );
              })}
            </div>
            <div className="print:hidden pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                    <span className="text-xs font-medium leading-none text-white">
                      {user?.firstName && user?.firstName.substring(0, 1).toUpperCase()}
                    </span>
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {/* <Disclosure.Button */}
                {/*  as="a" */}
                {/*  href="#" */}
                {/*  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100" */}
                {/* > */}
                {/*  {strings().navigation.yourProfile} */}
                {/* </Disclosure.Button> */}
                <button
                  type="button"
                  onClick={onSignoutClick}
                  className="block px-4 py-2 text-base font-medium no-underline text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  {strings().navigation.signOut}
                </button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
