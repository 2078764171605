import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import * as uuid from 'uuid';

import { useAuth } from '../hooks/use-auth';
import * as messagesService from '../services/api/messages';
import { Message } from '../entities/message';
import { MessageGroup } from '../entities/message-group';
import { parameters } from '../localization/strings';
import { MessageDirection } from '../entities/message-direction';

interface ProviderProps {
  children: React.ReactNode;
}

interface ContextValue {
  messageGroups: MessageGroup[];
  isLoading: boolean;
  addMessage: (id: string, text: string) => void;
}

const MessagesContext = createContext<ContextValue>({
  messageGroups: [],
  isLoading: true,
  addMessage: () => null,
});

let websocketConnection: WebSocket;

const groupMessages = (messages: Message[]): MessageGroup[] => {
  const groups: MessageGroup[] = [];
  let collectedMessages: Message[] = [];
  let prevMessage: Message | null = null;

  for (const message of messages) {
    if (prevMessage && prevMessage.isCreatedByCustomer !== message.isCreatedByCustomer) {
      groups.push({
        id: uuid.v4(),
        direction: prevMessage.isCreatedByCustomer ? MessageDirection.Outgoing : MessageDirection.Incoming,
        messages: collectedMessages,
      });
      collectedMessages = [];
    }

    collectedMessages.push(message);
    prevMessage = message;
  }

  if (collectedMessages.length) {
    groups.push({
      id: uuid.v4(),
      direction: collectedMessages[0].isCreatedByCustomer ? MessageDirection.Outgoing : MessageDirection.Incoming,
      messages: collectedMessages,
    });
  }

  return groups;
};

function MessagesContextProvider({ children }: ProviderProps) {
  const [messageGroups, setMessageGroups] = useState<MessageGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { token } = useAuth();

  const getExistingMessage = useCallback(
    (messageId: string): Message | null => {
      const messageGroupsLength = messageGroups.length;

      for (let i = messageGroupsLength - 1; i >= 0; i -= 1) {
        const message = messageGroups[i].messages.find((m) => m.messageId === messageId);

        if (message) {
          return message;
        }
      }

      return null;
    },
    [messageGroups],
  );

  const appendMessage = useCallback(
    (message: Message): void => {
      const existing = getExistingMessage(message.messageId);

      if (!existing) {
        const lastMessageGroup = messageGroups.length ? messageGroups[messageGroups.length - 1] : null;
        const messageDirection = message.isCreatedByCustomer ? MessageDirection.Outgoing : MessageDirection.Incoming;

        if (lastMessageGroup && lastMessageGroup.direction === messageDirection) {
          messageGroups[messageGroups.length - 1].messages.push(message);

          messageGroups[messageGroups.length - 1].messages = messageGroups[messageGroups.length - 1].messages.sort(
            (a, b) => {
              return a.createdAt <= b.createdAt ? -1 : 1;
            },
          );
        } else {
          // sukuriam nauja grupe
          messageGroups.push({
            id: uuid.v4(),
            direction: messageDirection,
            messages: [message],
          });
        }

        setMessageGroups([...messageGroups]);
      }
    },
    [messageGroups, setMessageGroups, getExistingMessage],
  );

  const addMessage = useCallback(
    (id: string, text: string): void => {
      const message = {
        isCreatedByCustomer: true,
        messageId: id,
        message: text,
        customerId: -1,
        administratorId: -1,
        showConsultationBooking: false,
        createdAt: new Date().getTime(),
      };

      appendMessage(message);
    },
    [appendMessage],
  );

  const initializeSockets = () => {
    if (!websocketConnection) {
      websocketConnection = new WebSocket(`${parameters().api.websocketUrl}?token=${token}`);
    }

    // websocketConnection.onopen = (event) => {
    //   // console.log('connect:', event);
    //   // this._instance.send(JSON.stringify({ action: 'test'}));
    // };
    //
    websocketConnection.onclose = (event) => {
      // eslint-disable-next-line

      setTimeout(() => {
        initializeSockets();
      }, 5000);
    };

    websocketConnection.onerror = (event) => {
      // eslint-disable-next-line
    };

    websocketConnection.onmessage = async (event) => {
      // console.log('message:', event);
      const messageDetails = JSON.parse(event.data);

      const message = await messagesService.getMessage(token, messageDetails.messageId);

      appendMessage(message);
    };
  };

  initializeSockets();

  useEffect(() => {
    const loadData = async () => {
      const latestMessages = await messagesService.getLatest(token);

      setMessageGroups(groupMessages(latestMessages));
      setIsLoading(false);
    };

    loadData();
  }, [setMessageGroups, token]);

  const contextValue = useMemo(
    () => ({
      messageGroups,
      isLoading,
      addMessage,
    }),
    [messageGroups, isLoading, addMessage],
  );

  return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>;
}

MessagesContextProvider.defaultProps = {};

export { MessagesContext, MessagesContextProvider };
