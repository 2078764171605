import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useAuth } from '../../hooks/use-auth';
import ImmunotherapyFormComponent from '../../components/immunotherapy/form';
import { getPatient } from '../../services/api/patients';

function ImmunotherapyAgreementPageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChild, setIsChild] = useState<boolean | undefined>(false);
  const [patientId, setPatientId] = useState<number | undefined>();
  const { token, user } = useAuth();

  const { patientHash, productId } = useParams();

  useEffect(() => {
    const fetchPatient = async (h: string) => {
      setIsLoading(true);
      const res = await getPatient(token, h);

      setIsChild(res.isChild);
      setPatientId(res.id);

      setIsLoading(false);
    };

    if (patientHash) {
      fetchPatient(patientHash);
    }
  }, [isChild, patientHash, token]);

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto lg:w-4/12">
        <ImmunotherapyFormComponent
          customerId={user?.id}
          patientId={patientId}
          isChild={isChild}
          token={token}
          productId={Number(productId)}
        />
      </div>
    </div>
  );
}

export default ImmunotherapyAgreementPageComponent;
