import React from 'react';
import tw, { styled } from 'twin.macro';
import classNames from 'classnames';

import { strings } from '../../../localization/strings';
import { useSite } from '../../../hooks/use-site';

interface ComponentProps {
  title: string;
  strength: number;
  iconName: string;
  full?: boolean;
}

//   Su sense spalvomis
const Icon = styled.div<{ strength: number }>`
  ${tw`h-8 w-8 md:h-10 md:w-10 text-2xl md:text-[28px] rounded-full text-content-700 border-content-500 justify-center inline-flex items-center border`}
  ${tw`print:hidden`}
  ${(props) => props.strength >= 3 && tw`bg-senseHigh-500 border-senseHigh-500 text-white`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-500 border-senseMedium-500 text-white`}
  ${(props) => props.strength === 1 && tw`bg-senseLow-500 border-senseLow-500 text-white`}
`;

const Allergen = styled.div<{ strength: number }>`
  ${tw`flex sm:inline-flex items-center rounded-lg md:rounded-2xl py-2 pl-2 pr-3 md:pr-4 font-semibold text-sm bg-white text-content-700 border-content-500 border`}
  ${tw`print:!text-black print:border-0 print:!p-0 print:font-normal`}
  ${(props) => props.strength >= 3 && tw`bg-senseHigh-50 border-senseHigh-50 text-senseHigh-500`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-50 border-senseMedium-50 text-senseMedium-500`}
  ${(props) => props.strength === 1 && tw`bg-senseLow-50 border-senseLow-50 text-senseLow-500`}
`;

// const Icon = styled.div<{ strength: number }>`
//   ${tw`h-8 w-8 md:h-10 md:w-10 text-2xl md:text-4xl rounded-full text-white justify-center inline-flex items-center border`}
//   ${(props) => props.strength >= 3 && tw`bg-red-600 border-red-600`}
//   ${(props) => props.strength === 2 && tw`bg-orange-400 border-orange-400`}
//   ${(props) => props.strength === 1 && tw`bg-success-800 border-success-800`}
//   ${(props) => props.strength <= 0 && tw`text-content-700 border-content-500`}
// `;
//
// const Allergen = styled.div<{ strength: number }>`
//   ${tw`flex sm:inline-flex items-center rounded-lg md:rounded-2xl py-2 pl-2 pr-3 md:pr-4 font-semibold text-sm mb-3 md:mb-4 bg-white text-content-700 border-content-500 border`}
//   ${(props) => props.strength >= 3 && tw`bg-red-100 border-red-100 text-red-600`}
//   ${(props) => props.strength === 2 && tw`bg-orange-100 border-orange-100 text-orange-400`}
//   ${(props) => props.strength === 1 && tw`bg-success-300 border-success-300 text-success-800`}
// `;

function AllergenComponent({ strength, full, title, iconName }: ComponentProps) {
  const { isfoodId } = useSite();

  return (
    <Allergen strength={strength} className={classNames({ 'w-full': full })}>
      <Icon strength={strength} aria-hidden="true">
        <span className={`icon ${isfoodId ? `am-icon-foodId-${iconName}` : `am-icon-${iconName}`}`} />
      </Icon>
      <div className="ml-2 md:ml-3 print:!ml-1">
        {title}
        <span className="hidden print:inline"> ({strings().test_answer.sensitivityShort[strength]})</span>
      </div>
    </Allergen>
  );
}

AllergenComponent.defaultProps = {
  full: false,
};

export default AllergenComponent;
