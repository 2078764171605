import { useContext } from 'react';

import { AuthContext } from '../context/auth';

export const useAuth = () => {
  const context = useContext(AuthContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useAuth was used outside of its Provider');
  }

  return context;
};
