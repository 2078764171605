import React from 'react';

import { ModalKeys } from '../../context/modals';
import ModalText from './text-modal';
import TextTreatmentProtocol from '../text/treatment-protocol';
import { strings } from '../../localization/strings';

export default function ModalTreatmentProtocol() {
  return (
    <ModalText type={ModalKeys.TreatmentProtocol} title={strings().treatmentProtocol.title}>
      <TextTreatmentProtocol />
    </ModalText>
  );
}
