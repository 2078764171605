import React, { useEffect, useState } from 'react';
import { lt } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import * as patientsApi from '../../services/api/patients';
import { useAuth } from '../../hooks/use-auth';
import { strings } from '../../localization/strings';

ChartJS.register(LinearScale, TimeScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface ComponentProps {
  patientHash: string;
}

function TreatmentProgressComponent({ patientHash }: ComponentProps) {
  const { token } = useAuth();
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [progress, setProgress] = useState<number>(100);

  useEffect(() => {
    const fetchPatientScore = async () => {
      const res = await patientsApi.getPatientTreatmentScore(token, patientHash);

      if (res) {
        setData(res.data);
        setProgress(res.progressScore);
      }
    };

    fetchPatientScore();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 120,
        ticks: {
          stepSize: 20,
        },
        grid: {
          color: 'rgb(59 130 246 / 0.5)',
        },
        title: {
          display: true,
          text: strings().immunotherapy.treatmentProgress.chartText,
        },
      },
      x: {
        type: 'time' as const,
        adapters: {
          date: {
            locale: lt,
          },
        },
        time: {
          unit: 'month' as const,
          minUnit: 'month' as const,
          displayFormats: {
            month: 'LLLL',
          },
        },
        grid: {
          color: 'rgb(59 130 246 / 0.5)',
        },
      },
    },
    tension: 0.3,
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <div className="pt-4 md:pt-0">
      <h2 className="md:p-0 text-xl font-bold text-left mb-5">{strings().immunotherapy.treatmentProgress.title}</h2>

      {progress && (
        <>
          <h3 className="md:p-0 text-lg text-left mb-3">{strings().immunotherapy.treatmentProgress.titleSymptoms}</h3>
          <div className="w-[120px] bg-blue-500 text-white rounded-lg p-3">
            <div className="leading-4 text-sm mb-3">{strings().immunotherapy.treatmentProgress.titleProgressBlock}</div>
            <div className="text-xl">
              <b>{progress}%</b>
              <span className="text-sm">/100</span>
            </div>
          </div>
        </>
      )}
      <h3 className="md:p-0 text-lg text-left mb-3 mt-8 md:mt-10">
        {strings().immunotherapy.treatmentProgress.titleChart}
      </h3>
      {data && (
        <div>
          <Line options={options} data={data} />
        </div>
      )}
    </div>
  );
}

TreatmentProgressComponent.defaultProps = {};

export default TreatmentProgressComponent;
