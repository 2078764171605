export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function allWords(text: string, callback: (input: string) => string, locale?: string): string {
  if (locale === 'pl') {
    return text;
  }

  return text
    .split(' ')
    .map((word) => callback(word))
    .join(' ');
}

export function toLatin(text: string): string {
  return text
    .toLowerCase()
    .replace(/\s+/, '')
    .replace(/ą/g, 'a')
    .replace(/č/g, 'c')
    .replace(/ę/g, 'e')
    .replace(/ė/g, 'e')
    .replace(/į/g, 'i')
    .replace(/š/g, 's')
    .replace(/ų/g, 'u')
    .replace(/ū/g, 'u')
    .replace(/ž/g, 'z');
}
