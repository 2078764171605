import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { strings } from '../../localization/strings';
import ContainerMd from '../../components/common/containers/containerMd';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import * as ordersApi from '../../services/api/orders';
import { useAuth } from '../../hooks/use-auth';
import { useSite } from '../../hooks/use-site';

const cookies = new Cookies();

export default function RepeatSuccessPageComponent() {
  const { orderHash } = useParams();
  const { token, tokenAdmin } = useAuth();
  const { locale } = useSite();
  const [error, setError] = useState<string>('');
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const fetchAnswer = async () => {
      if (orderHash) {
        try {
          const res = await ordersApi.getResult(token || tokenAdmin, orderHash, locale === 'en' ? locale : undefined);

          if (res.isReady) {
            setReady(true);
          }
        } catch (e) {
          setError((e as Error).message);
        }
      }
    };

    fetchAnswer();

    cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });
  }, [locale, token, orderHash, tokenAdmin]);

  return (
    <div>
      <ContainerMd>
        <div className="md:w-9/12">
          <div className="mb-6 md:mb-10">
            <AlertPrimary type="success" title={strings().questionnaire.success.title} />

            <div className="mt-10">
              <NavLink
                key="profile"
                to={isReady ? `/profile/results/${orderHash}` : '/'}
                className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
              >
                {strings().checkout.previewProfile}
              </NavLink>
            </div>
          </div>
        </div>
      </ContainerMd>
    </div>
  );
}
