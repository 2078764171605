import React from 'react';
import { useParams } from 'react-router-dom';

import { QuestionnaireContextProvider } from '../../context/questionnaire';
import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';

export default function QuestionnaireOnboardingPageComponent() {
  const { productId, step } = useParams();

  const intProductId = parseInt(productId || '', 10);

  return (
    <QuestionnaireContextProvider
      type={step === '2' ? QuestionnaireType.Full : QuestionnaireType.Onboarding}
      productId={intProductId}
    >
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
