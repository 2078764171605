import React from 'react';
import tw, { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // children: React.ReactNode;
  active?: boolean;
  wrapTexts?: boolean;
  hasCheckbox?: boolean;
}

const Button = styled.button<{ active?: boolean; wrapTexts?: boolean }>`
  ${tw`inline-block text-base rounded-2xl p-4 border font-normal w-full md:w-auto h-full`}
  ${(props) =>
    !props.active
      ? tw`bg-white text-content-800 border-content-500 hover:border-blue-800 hover:bg-blue-600 hover:text-blue-800`
      : tw`bg-blue-600 border-blue-800 text-blue-800`}
  text-align: left;
  min-width: 200px;

  ${up('md')} {
    ${(props) => props.wrapTexts && `max-width: 220px`};
  }
`;

const Checkmark = styled.input`
  ${tw`focus:ring-blue-900 h-6 w-6 text-blue-900 border-content-500 rounded`}
`;

function CommonButtonQuestion({ children, active, hasCheckbox, ...rest }: ComponentProps) {
  if (hasCheckbox) {
    return (
      <Button active={active} {...rest}>
        <div className="relative flex items-start">
          <div className="flex items-center h-6 w-6">
            <Checkmark
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              checked={active}
              readOnly
            />
          </div>
          <div className="ml-3">{children}</div>
        </div>
      </Button>
    );
  }

  return (
    <Button active={active} {...rest}>
      {children}
    </Button>
  );
}

CommonButtonQuestion.defaultProps = {
  active: false,
  wrapTexts: false,
  hasCheckbox: false,
};

export default CommonButtonQuestion;
