import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

import {
  FullAnswer,
  FullAnswerDiseaseRecommendation,
  FullAnswerRecommendationGroup,
} from '../../../entities/full-answer';
import Heading1 from '../../../components/common/headings/heading1';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import IMAGES from '../../../assets/images';
import { strings } from '../../../localization/strings';
import { updateStatus } from '../../../services/api/recommendations';
import { useAuth } from '../../../hooks/use-auth';
import RecommendationComponent from '../../../components/questionnaire/results/recommendation';
import Heading2 from '../../../components/common/headings/heading2';
import DoctorBlock from '../../../components/questionnaire/results/doctor-block';
import * as productsApi from '../../../services/api/products';
import Product from '../../../entities/product';
import Heading3 from '../../../components/common/headings/heading3';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { COOKIE_NAME_ANSWER } from '../../../context/questionnaire';
import { useSite } from '../../../hooks/use-site';
import { currency } from '../../../services/formatter/currency';
import ProfileResultsFoodIdRecommendationsPageComponent from './foodId/recommendations-foodId';
import { Habit } from '../../../entities/foodId/habits';
import { EatingIntolerances } from '../../../entities/foodId/eating-intolerances';

const HasBackground = styled.div<{ theme: boolean }>`
  ${(props) =>
    props.theme === 'allergomedica' &&
    `background: url(${IMAGES.BLOB.BGRESULT}) no-repeat bottom center; overflow: hidden;`}
`;

const NarrowContainer = styled.div`
  ${tw`max-w-4xl px-4 mx-auto pb-10 print:max-w-none`}
`;

function ProfileResultsRecommendationsPageComponent() {
  const { hasConsultation } = useSite();
  const { token } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [consultation, setConsultation] = useState<Product | undefined>();
  const cookies = new Cookies();
  const { locale, theme } = useSite();

  const { answer, setAnswer, isPreview, foodIdHabits, foodIdIntolerances } = useOutletContext<{
    answer: FullAnswer;
    setAnswer: Dispatch<SetStateAction<FullAnswer>>;
    isPreview: boolean;
    foodIdHabits: Habit[];
    foodIdIntolerances: EatingIntolerances[];
  }>();

  const [currentGroup, setCurrentGroup] = useState<FullAnswerRecommendationGroup>(
    answer.recommendationGroups[0] || null,
  );

  const onUpdateStatus = async (recommendation: FullAnswerDiseaseRecommendation, status: string) => {
    const recommendationIdx = answer.recommendations.indexOf(recommendation);

    answer.recommendations[recommendationIdx].status = status;
    answer.recommendations[recommendationIdx].testedAt = `${new Date().toDateString()}`;

    setAnswer({ ...answer });

    await updateStatus(token, recommendation.orderId, recommendation.id, status);
  };

  const onSelectGroup = (group: FullAnswerRecommendationGroup) => {
    setCurrentGroup(group);
  };

  const hasActionableRecommendations = answer.recommendations.filter((r) => r.isAction).length > 0;

  useEffect(() => {
    const fetchProduct = async () => {
      const res = await productsApi.getProducts();

      if (res) {
        const product = res?.find((p) => p.isConsultation);

        setConsultation(product);
      }
    };

    fetchProduct();
  }, []);

  const onSelectProduct = async (id: number | undefined) => {
    setIsLoading(true);
    cookies.remove(COOKIE_NAME_ANSWER);
    navigate(`/questionnaire/onboarding/${id}/2`);
  };

  return (
    <HasBackground theme={theme}>
      {(!answer || isLoading) && <CommonAnimatedLoader />}
      {answer && (answer.isReady || isPreview) && (
        <>
          {theme === 'foodId' && (
            <ProfileResultsFoodIdRecommendationsPageComponent
              habits={foodIdHabits}
              intolerances={foodIdIntolerances}
              answer={answer}
            />
          )}

          {theme === 'allergomedica' && (
            <>
              <NarrowContainer>
                {answer.productKey === 'CONSULTATION' && hasConsultation && (
                  <div className="my-10 sm:my-20 print:!my-3">
                    <DoctorBlock
                      name={answer.doctor.name}
                      position={strings().test_answer.doctorPosition[answer.doctor.key]}
                      image={answer.doctor.key ? IMAGES.DOCTORS[answer.doctor.key].IMAGE : undefined}
                      signature={answer.doctor.key ? IMAGES.DOCTORS[answer.doctor.key].SIGNATURE : undefined}
                      text={answer.comments.final || ''}
                    />
                  </div>
                )}
                {!answer.isConsultation && answer.productKey !== 'IMMUNOTHERAPY_PLAN' && hasConsultation && (
                  <div className="bg-content-300 rounded-xl py-5 px-4 md:my-10 md:px-10 lg:py-10 mt-5">
                    <div className="md:flex md:space-x-12 items-stretch">
                      <div className="md:w-[200px] flex-shrink-0 flex items-center mb-5 md:mb-0">
                        <img src={IMAGES.DOCTORS.LINAS.IMAGE} alt="Linas" className="rounded-3xl relative mx-auto" />
                      </div>
                      <div className="w-full md:w-auto">
                        <div className="relative">
                          <Heading3>{strings().consultationBanner.title}</Heading3>
                          <p className="mt-6 text-gray-500">{strings().consultationBanner.text}</p>
                          {consultation?.price && (
                            <p className="mt-4 flex items-baseline text-blue-900">
                              <span className="leading-normal font-extrabold tracking-tight">
                                {currency(consultation?.price, locale)}
                              </span>
                            </p>
                          )}
                          <CommonButtonDefault
                            wide
                            primary
                            className="mt-8"
                            onClick={() => onSelectProduct(consultation?.id)}
                          >
                            {strings().button.order}
                          </CommonButtonDefault>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {hasActionableRecommendations && (
                  <div className="text-center my-10 md:my-16 print:!my-1">
                    <Heading1>{strings().recommendations.title}</Heading1>
                    <div className="print:hidden">{strings().recommendations.description}</div>
                  </div>
                )}

                {hasActionableRecommendations && (
                  <Heading2 className="text-center print:hidden">{strings().recommendations.titleActions}</Heading2>
                )}

                {answer.recommendations
                  .filter((r) => r.isAction)
                  .map((recommendation) => (
                    <div className="mb-4  max-w-2xl mx-auto print:max-w-none" key={`r-${recommendation.id}`}>
                      <RecommendationComponent
                        isPreview={isPreview}
                        recommendation={recommendation}
                        onChangeStatus={(status: string) => onUpdateStatus(recommendation, status)}
                      />
                    </div>
                  ))}
              </NarrowContainer>

              {answer.recommendationGroups.length > 0 && (
                <>
                  {hasActionableRecommendations && (
                    <Heading2 className="text-center">{strings().recommendations.titleAll}</Heading2>
                  )}

                  {answer.recommendationGroups.length > 1 && (
                    <div className="border-b border-gray-200 px-2 md:px-0 print:hidden">
                      <div className="container max-w-4xl mx-auto print:max-w-none">
                        <nav className="-mb-px flex space-x-2 md:space-x-8 print:hidden" aria-label="Tabs">
                          {answer.recommendationGroups.map((group) => (
                            <button
                              type="button"
                              onClick={() => onSelectGroup(group)}
                              key={group.id}
                              className={classNames(
                                group.id === currentGroup.id
                                  ? 'border-blue-800 text-blue-800'
                                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm no-underline',
                              )}
                            >
                              {group.title}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                  )}

                  <NarrowContainer className="mt-10">
                    {currentGroup.recommendations.map((recommendation) => (
                      <div className="mb-4  max-w-2xl mx-auto print:max-w-none" key={`r-${recommendation.id}`}>
                        <RecommendationComponent
                          isPreview
                          recommendation={recommendation}
                          onChangeStatus={(status: string) => onUpdateStatus(recommendation, status)}
                        />
                      </div>
                    ))}
                  </NarrowContainer>
                </>
              )}
            </>
          )}
        </>
      )}
    </HasBackground>
  );
}

export default ProfileResultsRecommendationsPageComponent;
