import axios from 'axios';

import { parameters } from '../../../localization/strings';
import { OrderHabits } from '../../../entities/foodId/order-habits';

export const getHabitsResults = async (token: string, hash: string, langUrl?: string): Promise<OrderHabits> => {
  let url = `${parameters().api.baseUrl}/orders/habits-result/${hash}`;

  if (langUrl) {
    url += `?language=${langUrl}`;
  }

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
