import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import ReactDOM from 'react-dom';

import IMAGES from '../../assets/images';
import MessagingChat from '../../components/messaging/chat';
import { MessagesContextProvider } from '../../context/messages';
import MessagingSidebar from '../../components/messaging/sidebar';
import TreatmentPlanComponent from '../../components/treatment/plan';
import { treatmentView } from '../../services/api/treatment';
import { useAuth } from '../../hooks/use-auth';
import TreatmentViewProps from '../../entities/treatment-view';
import TreatmentProgressComponent from '../../components/treatment/progress';
import { getPatients } from '../../services/api/patients';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { getUserDoctors } from '../../services/api/users';
import { SimpleObject } from '../../entities/simple-object';
import { useSite } from '../../hooks/use-site';

const HasBackground = styled.div<{ theme: boolean }>`
  ${(props) =>
    props.theme === 'allergomedica' &&
    `background: url(${IMAGES.BLOB.BGRESULT}) no-repeat bottom center; overflow: hidden;`}
`;

const NavButton = styled.button<{ active?: boolean }>`
  ${tw`bg-[#FBFCFE] px-3 py-2 text-content-900 hover:bg-blue-600 text-xs w-1/3 text-center flex flex-col items-center`}
  ${(props) => props.active && tw`bg-blue-600 text-blue-800`}
`;

function ProfileMessagesPageComponent() {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patientHash, setPatientHash] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('messages');
  const [userDoctors, setUserDoctors] = useState<SimpleObject[]>();
  const [treatment, setTreatment] = useState<TreatmentViewProps>();
  const [currTreatmentDay, setCurrTreatmentDay] = useState<number>();

  const { hasConsultation, theme } = useSite();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPatients(token);

      const userDr = await getUserDoctors(token);

      const dr = userDr.map((el) => {
        const doctorInfo = strings().doctors.find((element: any) => element.imageUrl === el.imageKey);

        return Object.assign(el, doctorInfo);
      });

      if (dr) {
        ReactDOM.unstable_batchedUpdates(() => {
          setUserDoctors(dr);
        });
      }

      if (res) {
        const patientWtreatment = res.find((i) => i.treatmentHash !== null);

        if (patientWtreatment) {
          const currTreatment = await treatmentView(token, patientWtreatment?.treatmentHash);

          ReactDOM.unstable_batchedUpdates(() => {
            if (currTreatment.treatment.activatedAt) {
              const dateStarted = new Date(currTreatment.treatment.activatedAt);
              const today = new Date();
              const difference = today.getTime() - dateStarted.getTime();
              let totalDays = Math.floor(difference / (24 * 60 * 60 * 1000));

              if (totalDays > 90) {
                totalDays %= 90;
              }

              setCurrTreatmentDay(totalDays);
            }

            setTreatment(currTreatment);
            setPatientHash(patientWtreatment.hash);
          });
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <HasBackground theme={theme}>
      {isLoading ? (
        <CommonAnimatedLoader />
      ) : (
        <>
          {treatment && (
            <div className="flex mx-auto rounded-md overflow-hidden border border-blue-660 w-[260px] mt-4 mb-5 bg-blue-660 space-x-[1px] lg:invisible lg:hidden">
              <NavButton active={activeTab === 'messages'} onClick={() => setActiveTab('messages')}>
                {activeTab === 'messages' ? (
                  <img className="h-5 w-auto" src={IMAGES.ICONS.TABS.CHATACTIVE} aria-hidden="true" alt="" />
                ) : (
                  <img className="h-5 w-auto" src={IMAGES.ICONS.TABS.CHAT} aria-hidden="true" alt="" />
                )}
                {strings().immunotherapy.tabs.ask}
              </NavButton>
              <NavButton active={activeTab === 'progress'} onClick={() => setActiveTab('progress')}>
                {activeTab === 'progress' ? (
                  <img className="h-5 w-auto" src={IMAGES.ICONS.TABS.CHARTACTIVE} aria-hidden="true" alt="" />
                ) : (
                  <img className="h-5 w-auto" src={IMAGES.ICONS.TABS.CHART} aria-hidden="true" alt="" />
                )}
                {strings().immunotherapy.tabs.progress}
              </NavButton>
              <NavButton active={activeTab === 'plan'} onClick={() => setActiveTab('plan')}>
                {activeTab === 'plan' ? (
                  <img className="h-5 w-auto" src={IMAGES.ICONS.TABS.PLANNINGACTIVE} aria-hidden="true" alt="" />
                ) : (
                  <img className="h-5 w-auto" src={IMAGES.ICONS.TABS.PLANNING} aria-hidden="true" alt="" />
                )}
                {strings().immunotherapy.tabs.plan}
              </NavButton>
            </div>
          )}
          <div className="border-x border-content-500 mx-auto max-w-7xl">
            <div className={`${treatment ? 'lg:flex' : 'md:flex'}`}>
              <div
                className={`md:order-last ${
                  treatment ? 'lg:w-1/2' : 'md:w-1/3 border-b lg:p-4 border-content-500 md:border-0'
                }`}
              >
                {treatment ? (
                  <div className="lg:pl-5 lg:pt-4">
                    <div className="px-4">
                      <div className={`${activeTab === 'progress' ? 'block' : 'hidden lg:block'}`}>
                        <TreatmentProgressComponent patientHash={patientHash} />
                      </div>
                      <div className={`${activeTab === 'plan' ? 'block' : 'hidden lg:block'}`}>
                        <TreatmentPlanComponent
                          currentTreatmentDay={currTreatmentDay || 0}
                          allergens={treatment.sources}
                          symptoms={treatment.symptoms}
                          drugs={treatment.drugs}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  hasConsultation && <MessagingSidebar />
                )}
              </div>
              <div
                className={`md:border-r border-content-500 ${treatment ? 'lg:w-1/2' : 'md:w-2/3'} ${
                  activeTab === 'messages' ? 'block' : 'hidden lg:block'
                }`}
              >
                <MessagesContextProvider>
                  <MessagingChat doctors={userDoctors} />
                </MessagesContextProvider>
              </div>
            </div>
          </div>
        </>
      )}
    </HasBackground>
  );
}

ProfileMessagesPageComponent.defaultProps = {};

export default ProfileMessagesPageComponent;
