import React from 'react';
import { useRoutes, Navigate, Outlet } from 'react-router-dom';
import { styled } from 'twin.macro';

import { useAuth } from '../../hooks/use-auth';
import UsersLoginPageComponent from '../users/login';
import QuestionnaireOnboardingPageComponent from '../questionnaire/onboarding';
import QuestionnaireAnswerPageComponent from '../questionnaire/answer';
import PaymentsCheckoutPageComponent from '../payments/checkout';
import PaymentsSuccessPageComponent from '../payments/success';
import PaymentsCancelPageComponent from '../payments/cancel';
import ProfileDashboardPageComponent from '../profile/dashboard';
import ProfileResultsPageComponent from '../profile/results';
import TextPageComponent from '../text/page';
import ModalPrivacyPolicy from '../../components/modal/privacy-policy';
import ModalTermsConditions from '../../components/modal/terms-conditions';
import ResponsiveUtility from '../../components/common/utilities/responsive';
import { parameters } from '../../localization/strings';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import ProfileBloodRegistrationPageComponent from '../profile/blood-registration';
import LayoutHeadingComponent from '../../components/layout/heading';
import UsersForgotPasswordPageComponent from '../users/forgot-password';
import UsersResetPasswordPageComponent from '../users/reset-password';
import NotFoundPageComponent from '../text/not-found';
import ProfileLayoutComponent from '../../components/profile/layout';
import ProfileResultsAllergenGroupsPageComponent from '../profile/results/allergen-groups';
import ProfileResultsRecommendationsPageComponent from '../profile/results/recommendations';
import ProfileResultsAllergensPageComponent from '../profile/results/allergens';
import QuestionnaireFreePageComponent from '../questionnaire/free';
import ProfileMessagesPageComponent from '../profile/messages';
import ProfileSelectProductPageComponent from '../profile/select-product';
import ModalRemoteConsultation from '../../components/modal/remote-consultation';
import ResearchSpouseSignPageComponent from '../research/spouse-sign';
import QuestionnaireOrderedPageComponent from '../questionnaire/ordered';
import QuestionnaireRepeatPageComponent from '../questionnaire/repeat';
import UsersSelectPatientPageComponent from '../patients/select';
import UsersCreatePatientPageComponent from '../patients/create';
import QuestionnaireLoginPageComponent from '../questionnaire/login';
import QuestionnaireRegisterPageComponent from '../questionnaire/register';
import UsersRegisterPageComponent from '../users/register';
import QuestionnaireFeedbackPageComponent from '../questionnaire/feedback';
import FeedbackSuccessPageComponent from '../questionnaire/success';
import QuestionnaireStartConsultationPageComponent from '../questionnaire/startConsultation';
import ConsultationSuccessPageComponent from '../questionnaire/successConsultation';
import QuestionnaireCalendarPageComponent from '../questionnaire/calendar';
import QuestionnaireCheckAllergyPageComponent from '../questionnaire/checkAllergy';
import QuestionnaireSubscribeAnswerPageComponent from '../questionnaire/subscribeAnswer';
import ImmunotherapyAgreementPageComponent from '../immunotherapy/agreement';
import ModalTreatmentProtocol from '../../components/modal/treatment-protocol';
import ModalTreatmentProtocolChild from '../../components/modal/treatment-protocol-child';
import QuestionnaireWeeklyTreatmentPageComponent from '../questionnaire/weeklyTreatment';
import TreatmentWeeklySuccessPageComponent from '../questionnaire/successTreatmentWeekly';
import QuestionnaireScreenerAsitPageComponent from '../questionnaire/screenerAsit';
import QuestionnaireAnamnesisPageComponent from '../questionnaire/anamnesis/anamnesis';
import ModalClinicTermsConditions from '../../components/modal/clinic-terms-conditions';
import ModalClinicPrivacyPolicy from '../../components/modal/clinic-privacy-policy';
import AnamnesisSubmitPageComponent from '../questionnaire/anamnesis/submitAnamnesis';
import AnamnesisUploadPageComponent from '../questionnaire/anamnesis/uploadFile';
import RedirectToResearchLanding from '../redirect';
import QuestionnaireSelectReviewPageComponent from '../questionnaire/select-review';
import LayoutFooterComponent from '../../components/layout/footer';
import ResultsRedirectComponent from '../profile/results-redirect';
import QuestionnaireSelectPresalePageComponent from '../questionnaire/select-presale';
import RepeatSuccessPageComponent from '../questionnaire/successRepeat';

const PageContainer = styled.div`
  min-height: calc(100vh - 140px);
`;

export default function Root() {
  const { user, loading } = useAuth();

  const isLoggedIn = !loading && !!user;

  const element = useRoutes([
    {
      path: '/',
      element: isLoggedIn ? <Navigate to="/profile/dashboard" /> : <UsersLoginPageComponent />,
    },
    {
      path: '/register/:hash',
      element: isLoggedIn ? <Navigate to="/profile/dashboard" /> : <UsersRegisterPageComponent />,
    },
    {
      path: '/questionnaire/login',
      element: isLoggedIn ? <Navigate to="/questionnaire/patient" /> : <QuestionnaireLoginPageComponent />,
    },
    {
      path: '/questionnaire/register',
      element: isLoggedIn ? <Navigate to="/questionnaire/patient" /> : <QuestionnaireRegisterPageComponent />,
    },
    {
      path: '/questionnaire/patient',
      element: isLoggedIn ? <UsersSelectPatientPageComponent /> : <QuestionnaireLoginPageComponent />,
    },
    {
      path: '/questionnaire/patient/create',
      element: isLoggedIn ? <UsersCreatePatientPageComponent /> : <QuestionnaireLoginPageComponent />,
    },
    {
      path: '/questionnaire/onboarding',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <QuestionnaireOnboardingPageComponent />,
        },
        {
          path: ':productId',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <QuestionnaireOnboardingPageComponent />,
            },
            {
              path: ':step',
              element: <QuestionnaireOnboardingPageComponent />,
            },
          ],
        },
      ],
    },
    {
      path: '/questionnaire/repeat/:orderHash/:productId',
      element: <QuestionnaireRepeatPageComponent />,
      children: [
        {
          path: '',
          element: <QuestionnaireOnboardingPageComponent />,
        },
        {
          path: ':extraSurveyId',
          element: <QuestionnaireOnboardingPageComponent />,
        },
      ],
    },
    {
      path: '/questionnaire/repeat/success/:orderHash',
      element: <RepeatSuccessPageComponent />,
    },
    {
      path: '/questionnaire/research',
      element: <RedirectToResearchLanding />,
      // element: <Outlet />,
      // children: [
      //   {
      //     path: '',
      //     element: <QuestionnaireResearchPageComponent />,
      //   },
      //   {
      //     path: ':productId',
      //     element: <Outlet />,
      //     children: [
      //       {
      //         path: '',
      //         element: <QuestionnaireResearchPageComponent />,
      //       },
      //       {
      //         path: ':step',
      //         element: <QuestionnaireResearchPageComponent />,
      //       },
      //     ],
      //   },
      // ],
    },
    {
      path: '/questionnaire/screener-asic',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <QuestionnaireScreenerAsitPageComponent />,
        },
        {
          path: ':step',
          element: <QuestionnaireScreenerAsitPageComponent />,
        },
      ],
    },
    {
      path: '/questionnaire/anamnesis/:patientHash',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <QuestionnaireAnamnesisPageComponent />,
        },
        {
          path: 'send',
          element: <AnamnesisSubmitPageComponent />,
        },
        {
          path: 'upload',
          element: <AnamnesisUploadPageComponent />,
        },
      ],
    },
    { path: '/questionnaire/ordered/:orderHash/:productId', element: <QuestionnaireOrderedPageComponent /> },

    // with di code
    { path: '/questionnaire/free', element: <QuestionnaireFreePageComponent /> },

    {
      path: '/questionnaire/feedback',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <QuestionnaireFeedbackPageComponent />,
        },
        {
          path: ':feedbackHash/:feedbackValue',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <QuestionnaireFeedbackPageComponent />,
            },
          ],
        },
      ],
    },
    { path: '/questionnaire/success', element: <FeedbackSuccessPageComponent /> },

    { path: '/questionnaire/start/consultation/:answerHash', element: <QuestionnaireStartConsultationPageComponent /> },
    {
      path: '/questionnaire/calendar',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <QuestionnaireCalendarPageComponent />,
        },
        {
          path: ':answerHash',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <QuestionnaireCalendarPageComponent />,
            },
            {
              path: ':allergistId',
              element: <QuestionnaireCalendarPageComponent />,
            },
          ],
        },
      ],
    },
    { path: '/questionnaire/consultation/success', element: <ConsultationSuccessPageComponent /> },

    {
      path: '/questionnaire/checkAllergy',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <QuestionnaireCheckAllergyPageComponent />,
        },
      ],
    },
    { path: '/questionnaire/select-review/:hash/:patientId', element: <QuestionnaireSelectReviewPageComponent /> },

    { path: '/questionnaire/select-presale/:extraProductId', element: <QuestionnaireSelectPresalePageComponent /> },

    { path: '/questionnaire/subscribeAnswer/:hash', element: <QuestionnaireSubscribeAnswerPageComponent /> },

    { path: '/questionnaire/answer/:hash', element: <QuestionnaireAnswerPageComponent /> },

    { path: '/questionnaire/treatment-weekly/:patientHash', element: <QuestionnaireWeeklyTreatmentPageComponent /> },
    {
      path: '/questionnaire/treatment-weekly/success/:patientHash/:answerHash',
      element: <TreatmentWeeklySuccessPageComponent />,
    },

    {
      path: '/payments/checkout/:hash',
      element: <PaymentsCheckoutPageComponent />,
    },
    {
      path: '/payments/checkout/:hash/:patientId',
      element: <PaymentsCheckoutPageComponent />,
    },
    {
      path: '/payments/checkout/:hash/:patientId/:treatmentAgreement',
      element: <PaymentsCheckoutPageComponent />,
    },
    {
      path: '/payments/success/:hash',
      element: <PaymentsSuccessPageComponent />,
    },
    {
      path: '/research/success/:hash/:productId',
      element: <PaymentsSuccessPageComponent />,
    },
    {
      path: '/payments/cancel/:hash',
      element: <PaymentsCancelPageComponent />,
    },
    {
      path: '/research/sign/:hash',
      element: <PaymentsCheckoutPageComponent />,
    },
    {
      path: '/research/spouse/sign/:signToken/:signerToken/:orderHash',
      element: <ResearchSpouseSignPageComponent />,
    },
    {
      path: '/privacy-policy',
      element: <TextPageComponent type="privacy" />,
    },
    {
      path: '/terms-conditions',
      element: <TextPageComponent type="terms" />,
    },
    {
      path: '/forgot-password',
      element: <UsersForgotPasswordPageComponent />,
    },
    {
      path: '/reset-password/:token',
      element: <UsersResetPasswordPageComponent />,
    },

    {
      path: '/results/preview/:hash',
      element: <ProfileResultsPageComponent isPreview />,
      children: [
        {
          path: '',
          element: <ProfileResultsAllergenGroupsPageComponent />,
        },
        {
          path: 'recommendations',
          element: <ProfileResultsRecommendationsPageComponent />,
        },
        {
          path: 'allergens',
          element: <ProfileResultsAllergensPageComponent />,
        },
      ],
    },
    // answer several lang starts
    {
      path: '/:language/results/preview/:hash',
      element: <ProfileResultsPageComponent isPreview />,
      children: [
        {
          path: '',
          element: <ProfileResultsAllergenGroupsPageComponent />,
        },
        {
          path: 'recommendations',
          element: <ProfileResultsRecommendationsPageComponent />,
        },
        {
          path: 'allergens',
          element: <ProfileResultsAllergensPageComponent />,
        },
      ],
    },
    // answer several lang ends
    {
      path: '/profile',
      element: isLoggedIn ? <ProfileLayoutComponent /> : <Navigate to="/" />,
      children: [
        {
          path: 'dashboard',
          element: <ProfileDashboardPageComponent />,
        },
        {
          path: 'messages',
          element: <ProfileMessagesPageComponent />,
        },
        {
          path: 'select-product/:hash',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <ProfileSelectProductPageComponent />,
            },
            {
              path: ':productId',
              element: <ProfileSelectProductPageComponent />,
            },
          ],
        },
        {
          path: 'results/:hash',
          element: <ProfileResultsPageComponent />,
          children: [
            {
              path: '',
              element: <ProfileResultsAllergenGroupsPageComponent />,
            },
            {
              path: 'recommendations',
              element: <ProfileResultsRecommendationsPageComponent />,
            },
            {
              path: 'allergens',
              element: <ProfileResultsAllergensPageComponent />,
            },
          ],
        },
        {
          path: 'blood-registration/:hash',
          element: <ProfileBloodRegistrationPageComponent />,
        },
      ],
    },
    // profile several lang starts
    {
      path: '/:language/profile',
      element: isLoggedIn ? <ProfileLayoutComponent /> : <Navigate to="/" />,
      children: [
        {
          path: 'dashboard',
          element: <ProfileDashboardPageComponent />,
        },
        {
          path: 'messages',
          element: <ProfileMessagesPageComponent />,
        },
        {
          path: 'select-product/:hash',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <ProfileSelectProductPageComponent />,
            },
            {
              path: ':productId',
              element: <ProfileSelectProductPageComponent />,
            },
          ],
        },
        {
          path: 'results/:hash',
          element: <ProfileResultsPageComponent />,
          children: [
            {
              path: '',
              element: <ProfileResultsAllergenGroupsPageComponent />,
            },
            {
              path: 'recommendations',
              element: <ProfileResultsRecommendationsPageComponent />,
            },
            {
              path: 'allergens',
              element: <ProfileResultsAllergensPageComponent />,
            },
          ],
        },
        {
          path: 'blood-registration/:hash',
          element: <ProfileBloodRegistrationPageComponent />,
        },
      ],
    },
    // profile several lang ends
    {
      path: 'purchase/treatment-plan/:patientHash/:productId',
      element: isLoggedIn ? <ImmunotherapyAgreementPageComponent /> : <UsersLoginPageComponent />,
    },
    {
      path: 'results/redirect/:orderId/:token',
      element: <ResultsRedirectComponent />,
    },
  ]);

  // {user && <div>User: {user?.email}</div>}

  return (
    <>
      <PageContainer>
        <LayoutHeadingComponent />

        {loading && <CommonAnimatedLoader />}
        {!loading && (element || <NotFoundPageComponent />)}

        <ModalPrivacyPolicy />
        <ModalTermsConditions />
        <ModalRemoteConsultation />
        <ModalTreatmentProtocol />
        <ModalTreatmentProtocolChild />
        <ModalClinicTermsConditions />
        <ModalClinicPrivacyPolicy />
        {parameters().isDevelopment && <ResponsiveUtility />}
      </PageContainer>
      <LayoutFooterComponent />
    </>
  );
}
