import React from 'react';
import { useParams } from 'react-router-dom';

import { QuestionnaireContextProvider } from '../../context/questionnaire';
import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';

export default function QuestionnaireCheckAllergyPageComponent() {
  const { productId } = useParams();

  const intProductId = parseInt(productId || '', 10);

  return (
    <QuestionnaireContextProvider type={QuestionnaireType.CheckAllergy} productId={intProductId}>
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
