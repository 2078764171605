import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';

import * as questionnaireApi from '../../services/api/questionnaire';
import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import StyledError from '../../components/common/form/styled-error';
import CommonButtonDefault from '../../components/common/buttons/default';
import { setAnswerCookie } from '../../context/questionnaire';

type Inputs = {
  code: string;
};

function QuestionnaireFreePageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);

      const res = await questionnaireApi.startFree(data.code);

      setAnswerCookie(res.hash);

      navigate(`/questionnaire/onboarding`);
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.response);

        setIsLoading(false);
        setError('code', { message: e.message });
      }
    }
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <Heading2 className="mb-6">{strings().diForm.title}</Heading2>
        {isLoading && <CommonAnimatedLoader />}
        {errors.code && <StyledError>{errors.code.message}</StyledError>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2">
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex text-lg items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 border-gray-300">
                DI
              </span>
              <input
                pattern="[0-9]+"
                type="number"
                {...register('code', { required: true })}
                className={classNames(
                  'flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-blue-500 focus:border-blue-500 text-lg',
                  {
                    'border-red-500': errors.code,
                    'border-gray-300': !errors.code,
                  },
                )}
                placeholder="000000"
              />
            </div>
          </div>

          <CommonButtonDefault primary className="mt-5 md:mt-7" type="submit">
            {strings().button.continue}
          </CommonButtonDefault>
        </form>
      </div>
    </div>
  );
}

export default QuestionnaireFreePageComponent;
