import React from 'react';
import tw, { styled } from 'twin.macro';

import NarrowContainer from '../../../../components/common/containers/container-narrow';
import Heading2 from '../../../../components/common/headings/heading2';
import GreyBlock from '../../../../components/questionnaire/results/grey-block';
import { Habit } from '../../../../entities/foodId/habits';
import images from '../../../../assets/images';
import { strings } from '../../../../localization/strings';
import Allergen from '../../../../components/questionnaire/results/allergen';
import { EatingIntolerances } from '../../../../entities/foodId/eating-intolerances';
import HeroEmptyState from './hero-empty-state';
import { FullAnswer } from '../../../../entities/full-answer';

interface ComponentProps {
  habits?: Habit[];
  intolerances?: EatingIntolerances[];
  answer?: FullAnswer;
}

const Title4 = styled.h4`
  ${tw`font-bold mb-3`}
`;

function ProfileResultsFoodIdRecommendationsPageComponent({ habits, intolerances, answer }: ComponentProps) {
  return !answer?.surveyFinished ? (
    <HeroEmptyState
      name={answer?.name}
      productId={answer?.productId}
      extraSurveyId={answer?.extraSurveyId}
      hash={answer?.orderHash}
      title={strings().FOOD_ID.test_answer.emptyStateRecommendationsTitle}
      text={strings().FOOD_ID.test_answer.emptyStateRecommendationsText}
    />
  ) : (
    <>
      <div className="bg-primary py-10 md:py-14">
        <div className="container max-w-4xl px-4 mx-auto">
          <div className="text-white text-center ">
            <div className="text-2xl sm:text-4xl lg:text-5xl leading-snug">
              {strings().FOOD_ID.recommendations.title}
            </div>
            <div className="text-xl mt-5">{strings().FOOD_ID.recommendations.description}</div>
          </div>
          {intolerances && intolerances.length > 0 && (
            <div className="mt-5 md:mt-12 p-4 md:px-5 md:py-7 rounded-lg md:rounded-xl bg-white drop-shadow-xl print:drop-shadow-none print:rounded-none print:!p-0">
              <div className="text-2xl font-bold mb-4">{strings().FOOD_ID.recommendations.titleProducts}</div>
              {/* <div>{strings().FOOD_ID.recommendations.recomendationsProducts}</div> */}

              <div className="mt-7">
                <div className="flex flex-wrap items-center">
                  {intolerances.map((item, index) => (
                    <div key={`intolerance-${index + 1}`} className="mb-3 mr-3 md:mb-4 md:mr-4">
                      <Allergen title={item.title} strength={item.strength} iconName={item.iconName} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <NarrowContainer>
        <Heading2 className="mt-10 md:mt-16 text-center">{strings().recommendations.otherRecommendations}</Heading2>
        {habits && (
          <div>
            {habits.map((habit, key) => (
              <div
                key={`habit-${habit.habitTitle}-${key + 1}`}
                className="p-4 md:px-5 md:py-7 rounded-lg md:rounded-xl bg-white drop-shadow-xl print:drop-shadow-none print:rounded-none print:!p-0 mb-7"
              >
                <h3 className="text-center sm:text-left text-lg md:text-2xl print:!text-base font-bold mb-3 leading-5">
                  {habit.habitHeader}
                </h3>
                <div className="md:flex mt-7">
                  <div className="md:border-r border-gray-200 md:pr-8">
                    <Title4>{habit.habitTitle}</Title4>
                    {habit.habitMechanisms.map((mechanism, num) => (
                      <div className="flex items-center mb-1" key={`Mechanism-${num + 1}`}>
                        <img className="h-3 w-auto mr-2" src={images.ICONS.ARROWRIGHTBLACK} alt="" aria-hidden="true" />
                        {mechanism.mechanismTitle}
                      </div>
                    ))}
                  </div>
                  <div className="md:pl-8 mt-10 md:mt-0 md:min-w-[280px]">
                    <Title4>{strings().FOOD_ID.recommendations.titleProductSymptoms}</Title4>
                    {habit.habitSymptoms.map((item, index) => (
                      <div className="flex items-center mb-1" key={`mechanism-${index + 1}`}>
                        <img className="h-3 w-auto mr-2" src={images.ICONS.ARROWRIGHTBLACK} alt="" aria-hidden="true" />
                        {item.symptomTitle}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-10 mb-3">
                  <Title4>{strings().FOOD_ID.recommendations.titleHowToAvoid}</Title4>
                  {habit.habitComment}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-3 md:mt-7 print:!mt-1">
          <GreyBlock
            size="md"
            title={strings().FOOD_ID.recommendations.titleHowToFollowRecommendations}
            text={strings().FOOD_ID.recommendations.textHowToFollowRecommendations}
          />
        </div>
      </NarrowContainer>
    </>
  );
}

ProfileResultsFoodIdRecommendationsPageComponent.defaultProps = {
  habits: [],
  intolerances: [],
  answer: undefined,
};

export default ProfileResultsFoodIdRecommendationsPageComponent;
