import tw, { styled } from 'twin.macro';

const SourceGroupIcon = styled.div<{ strength: number; size?: string }>`
  ${tw`bg-content-500 border-content-500 text-content-700 rounded-full text-4xl sm:text-5xl md:text-7xl inline-flex justify-center items-center text-center flex-shrink-0`};

  ${(props) => props.strength >= 3 && tw`bg-senseHigh-500 border-senseHigh-500 text-white`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-500 border-senseMedium-500 text-white`}
    ${(props) => props.strength === 1 && tw`bg-senseLow-500 border-senseLow-500 text-white`}
  
  ${(props) =>
    props.size === 'md'
      ? tw`w-[40px] h-[40px] mr-3 print:hidden`
      : tw`w-[46px] h-[46px] sm:w-[60px] sm:h-[60px] md:w-[100px] md:h-[100px]`}
`;

export default SourceGroupIcon;
