import React from 'react';

import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import UsersResetPasswordForm from '../../components/users/reset-password-form';

function UsersResetPasswordPageComponent() {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <Heading2 className="mb-6">{strings().forgotPassword.finishPasswordReset}</Heading2>
        <UsersResetPasswordForm />
      </div>
    </div>
  );
}

export default UsersResetPasswordPageComponent;
