import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme } from 'styled-breakpoints';
import { ThemeProvider } from 'styled-components';

import { AuthContextProvider } from '../context/auth';
import Root from './root/root';
import { ModalsContextProvider } from '../context/modals';
import { LayoutContextProvider } from '../context/layout';
import { SiteContextProvider } from '../context/site';

const queryClient = new QueryClient();

const breakpointsTheme = createTheme({
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
});

export default function App() {
  // if (typeof document !== 'undefined') {
  //   document.querySelector('meta[name="description"]')?.setAttribute('content', strings().meta.description);
  //   document.title = strings().meta.title;
  //   document.querySelector('meta[property="og:description"]')?.setAttribute('content', strings().meta.description);
  //   document.querySelector('meta[property="og:title"]')?.setAttribute('content', strings().meta.title);
  //   document.querySelector('meta[property="og:url"]')?.setAttribute('content', strings().meta.url);
  //   document.querySelector('meta[property="og:image"]')?.setAttribute('content', '/share-image.png');
  // }

  return (
    <QueryClientProvider client={queryClient}>
      <SiteContextProvider>
        <AuthContextProvider>
          <ModalsContextProvider>
            <LayoutContextProvider>
              <BrowserRouter>
                <ThemeProvider theme={breakpointsTheme}>
                  <Root />
                </ThemeProvider>
              </BrowserRouter>
            </LayoutContextProvider>
          </ModalsContextProvider>
        </AuthContextProvider>
      </SiteContextProvider>
    </QueryClientProvider>
  );
}
