import SEB from './images/banks/seb.png';
import SWEDBANK from './images/banks/swedbank.gif';
import LUMINOR from './images/banks/luminor.png';
import CITADELE from './images/banks/citadele.png';
import MEDICINOS from './images/banks/medicinos.png';
import REVOLUT from './images/banks/revolut.png';
import SIAULIU from './images/banks/siauliu-bankas.png';
import BLOB_FIRST from './images/blob1.svg';
import BLOB_SECOND from './images/blob2.svg';
import BLOB_THIRD from './images/blob3.svg';
import BG_RESULT from './images/bg_result.svg';
import LOGOTYPE from './images/allergomedica_logo.svg';
import ARROW_RIGHT from './images/icons/icon-arrow-right.svg';
import ICON_CHECKED from './images/icons/icon-checked.svg';
import ALLERGO_EXPERT from './images/products/allergo_expert.png';
import DASH from './images/dash.svg';
import LINAS from './images/doctors/linas.png';
import KESTUTIS from './images/doctors/kestutis.png';
import ANA from './images/doctors/ana.png';
import AGNE from './images/doctors/agne.jpg';
import EGLE from './images/doctors/egle.png';
import LINAS_SIGNATURE from './images/doctors/signature/linas.svg';
import ANA_SIGNATURE from './images/doctors/signature/ana.svg';
import AGNE_SIGNATURE from './images/doctors/signature/agne.svg';
import EGLE_SIGNATURE from './images/doctors/signature/egle.svg';
import SCREENSHOT from './images/screenshot.jpg';
import CHART from './images/icons/tabs/chart.svg';
import CHARTACTIVE from './images/icons/tabs/chart-active.svg';
import CHAT from './images/icons/tabs/chat.svg';
import CHATACTIVE from './images/icons/tabs/chat-active.svg';
import PLANNING from './images/icons/tabs/planning.svg';
import PLANNINGACTIVE from './images/icons/tabs/planning-active.svg';
import SUCCESS from './images/anamnesis/img-send-success.svg';
import SEND from './images/anamnesis/img-send.svg';
import UPLOAD from './images/anamnesis/img-upload.svg';
import BLIK from './images/banks/pl_blik.png';
import CARD from './images/banks/card.png';
import PL_PRZELEWY24 from './images/banks/pl_przelewy24.png';
import VISA from './images/banks/visa.png';
import MAESTRO from './images/banks/maestro.png';
import MASTERCARD from './images/banks/mastercard.png';
import FOODID_LOGO from './images/food_id_logo.svg';
import ARROWRIGHTBLACK from './images/icons/arrow-right-black.svg';
import LAIMA from './images/doctors/laima.jpg';
import LAIMA_SIGNATURE from './images/doctors/signature/laima.svg';
import GABIJA from './images/doctors/gabija.png';
import GABIJA_SIGNATURE from './images/doctors/signature/gabija.svg';

const IMAGES = {
  BANKS: {
    SEB,
    SWEDBANK,
    LUMINOR,
    CITADELE,
    MEDICINOS,
    REVOLUT,
    SIAULIU,
    BLIK,
    CARD,
    PL_PRZELEWY24,
    VISA,
    MAESTRO,
    MASTERCARD,
  },
  BLOB: {
    FIRST: BLOB_FIRST,
    SECOND: BLOB_SECOND,
    THIRD: BLOB_THIRD,
    BGRESULT: BG_RESULT,
  },
  ICONS: {
    ARROWRIGHT: ARROW_RIGHT,
    ARROWRIGHTBLACK,
    CHECKED: ICON_CHECKED,
    TABS: {
      CHART,
      CHARTACTIVE,
      CHAT,
      CHATACTIVE,
      PLANNING,
      PLANNINGACTIVE,
    },
  },
  LOGOTYPES: {
    PRIMARY: LOGOTYPE,
    FOODID_LOGO,
  },
  PRODUCTS: {
    ALLERGO_EXPERT,
    SCREENSHOT,
  },
  PATTERN: {
    DASH,
  },
  DOCTORS: {
    LINAS: {
      IMAGE: LINAS,
      SIGNATURE: LINAS_SIGNATURE,
    },
    EGLE: {
      IMAGE: EGLE,
      SIGNATURE: EGLE_SIGNATURE,
    },
    KESTUTIS: {
      IMAGE: KESTUTIS,
      SIGNATURE: LINAS_SIGNATURE,
    },
    ANA: {
      IMAGE: ANA,
      SIGNATURE: ANA_SIGNATURE,
    },
    AGNE: {
      IMAGE: AGNE,
      SIGNATURE: AGNE_SIGNATURE,
    },
    LAIMA: {
      IMAGE: LAIMA,
      SIGNATURE: LAIMA_SIGNATURE,
    },
    GABIJA: {
      IMAGE: GABIJA,
      SIGNATURE: GABIJA_SIGNATURE,
    },
  },
  ANAMNESIS: {
    SUCCESS,
    SEND,
    UPLOAD,
  },
};

export default IMAGES;
