import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import Cookies from 'universal-cookie';

import { current } from '../services/api/users';
import { AuthToken } from '../entities/auth-token';
import { Customer } from '../entities/customer';
import { Order } from '../entities/order';

const COOKIE_NAME_TOKEN_ADMIN = 'admin_token';
const COOKIE_NAME_TOKEN = 'access_token';
const COOKIE_NAME_PUBLIC = 'is_public';
const cookies = new Cookies();

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  token: string;
  tokenAdmin: string;
  loading: boolean;
  user?: Customer;
  setUserToken: (authToken: AuthToken, publicState: boolean) => void;
  updateOrder: (orderHash: string, fields: Partial<Order>) => void;
  signout: () => void;
  updateUserFeedbackStatus: () => void;
}

const AuthContext = createContext<ContextValue>({
  token: '',
  tokenAdmin: '',
  loading: true,
  setUserToken: () => null,
  updateOrder: () => null,
  updateUserFeedbackStatus: () => null,
  signout: () => null,
});

function AuthContextProvider({ children }: ComponentProps) {
  const tokenAdmin = cookies.get(COOKIE_NAME_TOKEN_ADMIN);
  const [token, setToken] = useState<string>(cookies.get(COOKIE_NAME_TOKEN));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Customer>();

  const signout = useCallback(() => {
    setToken('');
    setUser(undefined);
    cookies.remove(COOKIE_NAME_TOKEN, { path: '/' });
    cookies.remove(COOKIE_NAME_PUBLIC, { path: '/' });
  }, []);

  const setUserToken = useCallback(
    (authToken: AuthToken, publicState: boolean) => {
      cookies.set(COOKIE_NAME_TOKEN, authToken.token, {
        expires: authToken.expiredAt,
        path: '/',
      });

      cookies.set(COOKIE_NAME_PUBLIC, publicState ? 1 : 0, {
        expires: authToken.expiredAt,
        path: '/',
      });

      setToken(authToken.token);
      setIsLoading(true);
    },
    [setToken],
  );

  const updateOrder = useCallback(
    (orderHash: string, fields: Partial<Order>) => {
      if (user) {
        const order = user.orders.find((o) => o.hash === orderHash);

        if (order) {
          const index = user.orders.indexOf(order);

          user.orders[index] = { ...order, ...fields };
        }
      }
    },
    [user],
  );

  const updateUserFeedbackStatus = useCallback(() => {
    if (user) {
      user.providedFeedback = true;
    }
  }, [user]);

  useEffect(() => {
    const fetchUser = async (currToken: string) => {
      try {
        const currUser = await current(currToken);

        setUser(currUser);
        setIsLoading(false);
      } catch (e) {
        setUser(undefined);
        setIsLoading(false);
      }
    };

    if (!user && token) {
      fetchUser(token);
    }

    if (!token) {
      setIsLoading(false);
    }
  }, [token, setUserToken, user]);

  const contextValue = useMemo(
    () => ({
      token,
      tokenAdmin,
      signout,
      user,
      setUserToken,
      updateOrder,
      updateUserFeedbackStatus,
      loading: isLoading,
    }),
    [token, tokenAdmin, user, updateOrder, signout, setUserToken, isLoading, updateUserFeedbackStatus],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthContextProvider };
