import React from 'react';
import { useParams } from 'react-router-dom';

import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';
import { QuestionnaireContextProvider } from '../../context/questionnaire';

export default function QuestionnaireCalendarPageComponent() {
  const { answerHash, allergistId } = useParams();

  // čia tik laiko pasirinkimas, nereikia produkto, dėl to nulis. Iš žinutės patenka į šitą klausimyną
  const intProductId = 0;

  return (
    <QuestionnaireContextProvider
      calendarAnswerHash={answerHash}
      allergist={allergistId}
      type={QuestionnaireType.Calendar}
      productId={intProductId}
    >
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
