import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';

import { login } from '../../services/api/users';
import { useAuth } from '../../hooks/use-auth';
import { strings, parameters } from '../../localization/strings';
import CommonAnimatedLoader from '../common/animated/loader';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';

interface ComponentProps {
  orderHash?: string;
  prefillEmail?: string;
  isQuestionnaire?: boolean;
}

type Inputs = {
  common: string;
  email: string;
  password: string;
};

function UsersLoginForm({ orderHash, prefillEmail, isQuestionnaire }: ComponentProps) {
  const { setUserToken } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      const token = await login(data.email, data.password, orderHash);

      setUserToken(token, false);
      if (!isQuestionnaire) {
        navigate(parameters().firstPageUrl);
      } else {
        navigate(parameters().selectPatient);
      }
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.response);

        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  useEffect(() => {
    if (prefillEmail) {
      setValue('email', prefillEmail);
    }
  }, [prefillEmail, setValue]);

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {errors.common && <StyledError>{errors.common.message}</StyledError>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2">
          <StyledLabel htmlFor="email">{strings().form.email}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.email && `!border-red-500`} `}
            type="email"
            {...register('email', { required: true })}
          />
          {errors.email && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
        </div>
        <div className="py-2">
          <StyledLabel htmlFor="password">{strings().form.password}</StyledLabel>
          <StyledInput
            fullWidth
            type="password"
            className={` ${errors.password && `!border-red-500`} `}
            {...register('password', { required: true })}
          />
          {errors.password && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
        </div>
        <div className="text-sm">
          <Link to="/forgot-password" className="font-medium">
            {strings().login.forgotYourPasswordLink}
          </Link>
        </div>

        <CommonButtonDefault
          primary
          className="mt-5 md:mt-7"
          type="submit"
          onClick={() => {
            clearErrors('common');
          }}
        >
          {strings().button.login}
        </CommonButtonDefault>
      </form>
    </>
  );
}

UsersLoginForm.defaultProps = {
  orderHash: '',
  prefillEmail: '',
  isQuestionnaire: false,
};

export default UsersLoginForm;
