import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useLayout } from '../../hooks/use-layout';

export default function ProfileLayoutComponent() {
  const { setMenuState } = useLayout();

  useEffect(() => {
    setMenuState(true);
  }, [setMenuState]);

  return <Outlet />;
}
