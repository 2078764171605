import React from 'react';
import { NavLink } from 'react-router-dom';

import { strings } from '../../../../localization/strings';
import { kreipinysVardoPavardes } from '../../../../services/grammar/lithuanian';
import { useSite } from '../../../../hooks/use-site';

interface ComponentProps {
  name?: string;
  productId?: number;
  extraSurveyId?: number;
  hash?: string;
  title?: string;
  text?: string;
}

function HeroEmptyState({ name, extraSurveyId, productId, hash, title, text }: ComponentProps) {
  const { locale } = useSite();

  return (
    <div className="bg-primary py-10 md:py-14 print:py-0">
      <div className="container max-w-3xl px-4 mx-auto print:px-0">
        <div className="text-white print:!text-black text-center">
          {!title ? (
            <div className="font-bold text-2xl sm:text-4xl lg:text-5xl print:!text-lg leading-snug">
              {strings().FOOD_ID.test_answer.hello}{' '}
              {name && <span>{locale === 'pl' ? name : kreipinysVardoPavardes(name)}.</span>}
            </div>
          ) : (
            <div className="font-bold text-2xl sm:text-4xl lg:text-5xl print:!text-lg leading-snug">{title}</div>
          )}
          <div className="lg:text-2xl print:!text-lg font-light mt-4">
            {text || strings().FOOD_ID.test_answer.emptyState}
          </div>
          {extraSurveyId && productId && (
            <NavLink
              to={`/questionnaire/repeat/${hash}/${productId}/${extraSurveyId}`}
              className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-2.5 md:py-3.5 font-bold mt-5"
            >
              {strings().checkout.fillQuestionnaire}
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}
HeroEmptyState.defaultProps = {
  name: '',
  extraSurveyId: '',
  hash: '',
  title: '',
  text: '',
  productId: '',
};

export default HeroEmptyState;
