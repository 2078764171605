import React, { useEffect, useState } from 'react';
import Select, { OnChangeValue } from 'react-select';

import { strings } from '../../../localization/strings';
import * as doctorsApi from '../../../services/api/doctors';
import QuestionnaireFooterButtons from '../footer-buttons';
import { Doctor } from '../../../entities/doctor';

interface ComponentProps {
  onSelect?: (doctor: Doctor) => void;
}

function QuestionnaireQuestionDoctorComponent({ onSelect }: ComponentProps) {
  const [currentDoctor, setCurrentDoctor] = useState<Doctor | null>(null);
  const [doctors, setDoctors] = useState<Doctor[]>([]);

  useEffect(() => {
    const fetchDoctors = async () => {
      const res = await doctorsApi.getList();

      setDoctors(res.sort((a, b) => (a.name > b.name ? 1 : -1)));
    };

    fetchDoctors();
  }, [setDoctors]);

  const onSelectDoctor = (option: OnChangeValue<Doctor, false>) => {
    setCurrentDoctor(option);
  };

  const onContinue = () => {
    if (onSelect && currentDoctor) {
      onSelect(currentDoctor);
    }
  };

  return (
    <div>
      <Select
        className="pb-4"
        options={[{ id: 0, name: strings().doctorSelection.doNotWantToShare, lastName: '' }, ...doctors]}
        placeholder={strings().doctorSelection.pickerPlaceholder}
        getOptionValue={(option: Doctor) => `${option.id}`}
        getOptionLabel={(option: Doctor) => `${option.name} ${option.lastName}`}
        onChange={onSelectDoctor}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      <QuestionnaireFooterButtons disableContinue={!currentDoctor} onContinue={() => onContinue()} />
    </div>
  );
}

QuestionnaireQuestionDoctorComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionDoctorComponent;
