import tw, { styled } from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { useNavigate, useParams } from 'react-router-dom';
import { up } from 'styled-breakpoints';

import { Question, QuestionType } from '../../entities/question';
import QuestionnaireQuestionSingleComponent from './question/single';
import { QuestionOption } from '../../entities/question-option';
import { storeAnswer } from '../../services/api/questionnaire';
import QuestionnaireQuestionTextShortComponent, { QuestionInputType } from './question/text-short';
import QuestionnaireQuestionDescriptionComponent from './question/description';
import { AnswerOption } from '../../entities/answer-option';
import QuestionnaireQuestionMultipleComponent from './question/multiple';
import QuestionnaireQuestionScaleComponent from './question/scale';
import { useQuestionnaire } from '../../hooks/use-questionnaire';
import IMAGES from '../../assets/images';
import { useAuth } from '../../hooks/use-auth';
import CommonAnimatedLoader from '../common/animated/loader';
import QuestionnaireQuestionClinicComponent from './question/clinic';
import QuestionnaireQuestionCalendarComponent from './question/calendar';
import { Location } from '../../entities/location';
import { initiatePayment } from '../../services/api/payments';
import * as questionnaireApi from '../../services/api/questionnaire';
import { QuestionnaireType } from '../../entities/questionnaire-type';
import { Appointment } from '../../entities/appointment';
import QuestionnaireQuestionTextLongComponent from './question/text-long';
import QuestionnaireQuestionRatingComponent from './question/rating';
import { parameters } from '../../localization/strings';
import QuestionnaireQuestionInfoComponent from './question/info';
import { useSite } from '../../hooks/use-site';
import QuestionnaireQuestionParcelTerminalComponent from './question/parcel-terminal';
import { ParcelTerminal } from '../../entities/parcel-terminal';
import QuestionnaireQuestionDoctorComponent from './question/doctor';
import { Doctor } from '../../entities/doctor';
import { finishSurvey } from '../../services/api/orders';

const QuestionContainer = styled.div`
  ${tw`px-4 text-center overflow-x-hidden`}
  margin-top: 0.4rem;
  min-height: 100vh;

  ${up('xl')} {
    margin-top: 3.375rem;
  }

  ${up('md')} {
    margin-top: 2.375rem;
  }
`;

const QuestionCentering = styled.div<{ width?: number }>`
  display: inline-block;
  text-align: left;
  padding-top: 2.4rem;
  padding-bottom: 2rem;
  position: relative;
  margin: 0 auto;

  ${(props) => props.width === 1 && `width: 100%;`}
  ${up('md')} {
    padding-top: 4rem;
    ${(props) => props.width === 1 && `width: 40rem`}
  }

  ${up('xl')} {
    ${(props) => props.width === 1 && `width: 32rem`}
    ${(props) => props.width === 2 && `width: 50rem`}
        ${(props) => props.width === 3 && `width: 75rem`}
  }

  ${up('xl')} {
    padding-top: 4rem;
    min-height: 31rem;
  }
`;

const BgImage = styled.img`
  left: -8.125rem;
  top: 0;
  width: 20.938rem;
  ${tw`absolute z-0`};
`;

const MainTitle = styled.h2<{ type: string }>`
  ${tw`text-3xl md:text-5xl font-bold relative z-10 leading-tight`};
  ${(props) => props.type === QuestionType.Description && `text-align: center`}
`;

const QuestionTitle = styled.h3<{ width?: number }>`
  ${tw`text-xl md:text-3xl leading-10 font-bold relative z-10`};
  line-height: 1.4;
  ${(props) => props.width && `width: ${props.width}rem`}
  ${(props) => (props.width === 3 ? tw`mt-0` : tw`mt-5 md:mt-9`)}
`;

const QuestionBody = styled.div<{ width?: number }>`
  ${(props) => (props.width === 3 ? tw`pt-3` : tw`pt-8 md:pt-12`)}
  ${tw`relative z-10`}
`;

interface ComponentProps {
  question: Question;
  productId: number;
}

export default function QuestionnaireQuestion({ question, productId }: ComponentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    answerQuestion,
    type,
    orderHash,
    repeatOrderHash,
    answerHash,
    feedbackValue,
    feedbackCustomerHash,
    patientHashContext,
    extraProductId,
    extraSurveyId,
  } = useQuestionnaire();
  const { updateOrder, updateUserFeedbackStatus, token } = useAuth();
  const swiper = useSwiper();
  const navigate = useNavigate();
  const { isAllergomedica } = useSite();

  const { patientHash } = useParams();

  useEffect(() => {
    const storeFeedback = async (answerText: string) => {
      setLoading(true);
      const answerNew: Partial<AnswerOption> = { text: answerText };

      const res = await storeAnswer(
        question.id,
        {
          ...answerNew,
        },
        token,
        feedbackCustomerHash,
      );

      answerQuestion(question, answerNew, res.hash);

      setLoading(false);
    };

    // kai ateina atsakymas iš email
    if (feedbackValue && feedbackCustomerHash && question.position === 1) {
      storeFeedback(feedbackValue);
      swiper.slideNext();
    }

    const storeEmptyAnswer = async () => {
      setLoading(true);
      const answerNew: Partial<AnswerOption> = { skipped: true, productId };

      const res = await storeAnswer(
        question.id,
        {
          ...answerNew,
        },
        token,
      );

      answerQuestion(question, answerNew, res.hash);

      setLoading(false);
    };

    if (productId === parameters().product.repeatedConsultationId) {
      storeEmptyAnswer().then(() => navigate(`/questionnaire/register`));
    }
  }, []);

  const onStoreAnswer = async (answer: Partial<AnswerOption>) => {
    if (orderHash) {
      updateOrder(orderHash, { answerStatus: 'completed' });
    }

    setLoading(true);

    const res = await storeAnswer(
      question.id,
      {
        ...answer,
        productId,
        isLast: swiper.isEnd,
        answerHash,
        orderHash,
        repeatOrderHash,
      },
      token,
    );

    answerQuestion(question, answer, res.hash);

    window.scrollTo(0, 0);
    if (swiper.isEnd) {
      if (orderHash) {
        setLoading(false);
        navigate(parameters().firstPageUrl);
      } else {
        let shortResult;

        if (answerHash) {
          shortResult = await questionnaireApi.answer(answerHash);
        }

        if (shortResult?.researchMemberId) {
          setLoading(true);

          const payment = await initiatePayment(
            {
              firstName: '',
              lastName: '',
              agreeMarketing: true,
              agreeResearch: true,
              email: '',
              bank: '',
            },
            answerHash,
          );

          document.location.href = payment.url;
        } else {
          setLoading(false);

          if (repeatOrderHash && extraSurveyId) {
            await finishSurvey(token, repeatOrderHash);
            navigate(`/questionnaire/repeat/success/${repeatOrderHash}`);
          } else if (repeatOrderHash) {
            navigate(`/research/success/${repeatOrderHash}/${productId}`);
          } else if (productId && productId === parameters().product.freeScreenerAsitId) {
            document.location.href = parameters().freeResearchUrl;
          } else if (productId && productId === parameters().product.researchId) {
            document.location.href = parameters().freeResearchUrl;
          } else if (extraProductId) {
            navigate(`/questionnaire/select-presale/${extraProductId}`);
            // navigate(`/questionnaire/register`);
          } else if (type === QuestionnaireType.Onboarding) {
            // navigate(`/questionnaire/answer/${res.hash}`);
            navigate(`/questionnaire/register`);
          } else if (type === QuestionnaireType.Feedback) {
            updateUserFeedbackStatus();
            navigate(`/questionnaire/success`);
          } else if (type === QuestionnaireType.WeeklyTreatment) {
            if (patientHashContext && answerHash) {
              navigate(`/questionnaire/treatment-weekly/success/${patientHashContext}/${answerHash}`);
            }
          } else if (type === QuestionnaireType.Calendar) {
            navigate(`/questionnaire/consultation/success`);
          } else if (type === QuestionnaireType.CheckAllergy) {
            navigate(`/questionnaire/subscribeAnswer/${res.hash}`);
          } else if (type === QuestionnaireType.Anamnesis) {
            navigate(`/questionnaire/anamnesis/${patientHash}/send`);
          } else {
            // navigate(`/payments/checkout/${res.hash}`);
            navigate(`/questionnaire/register`);
          }
        }

        // const payment = await initiatePayment(data, answerHash);
        // document.location.href = payment.url;
      }
    } else {
      setLoading(false);
      swiper.slideNext();
    }
  };

  const onSelectMultiOptions = async (options: QuestionOption[]) => {
    return onStoreAnswer({ optionIds: options.map((o) => o.id) });
  };

  const onSelectOption = async (option: QuestionOption) => {
    return onStoreAnswer({ optionId: option.id });
  };

  const onSelectLocation = async (location: Location) => {
    return onStoreAnswer({ locationId: location.id });
  };

  const onSelectParcelTerminal = async (parcelTerminal: ParcelTerminal) => {
    return onStoreAnswer({ parcelTerminalId: parcelTerminal.id });
  };

  const onSkipQuestion = async () => {
    return onStoreAnswer({ skipped: true });
  };

  const onContinueWithText = async (text: string) => {
    return onStoreAnswer({ text });
  };

  const onTimeSelect = async (appointment: Appointment) => {
    return onStoreAnswer({ allergistId: appointment.allergistId, reservationTime: appointment.startTime });
  };

  const onSelectDoctor = async (doctor: Doctor) => {
    if (!doctor.id) return onSkipQuestion();

    return onStoreAnswer({ doctorId: doctor.id });
  };

  const highlightStars = (text: string) => {
    return {
      __html: text.replace(/\*(.*?)\*/g, '<span class="text-blue-800">$1</span>'),
    };
  };

  return (
    <QuestionContainer>
      {loading && <CommonAnimatedLoader absolute />}
      <QuestionCentering width={question.width || undefined}>
        {question.heading && (
          <MainTitle type={question.type} dangerouslySetInnerHTML={highlightStars(question.heading)} />
        )}
        {question.title && <QuestionTitle dangerouslySetInnerHTML={highlightStars(question.title)} />}
        {/* eslint-disable react/no-danger */}
        {question.description && question.type !== QuestionType.Description && (
          <div
            className="z-10 relative text-content-700 mt-5 sm:mt-7"
            dangerouslySetInnerHTML={highlightStars(question.description)}
          />
        )}
        {isAllergomedica && (
          <BgImage
            src={IMAGES.BLOB.FIRST}
            className={`${
              question.type === QuestionType.Description &&
              QuestionnaireType.Anamnesis === type &&
              '!left-1/2 ml-[-9rem]'
            }`}
            alt=""
            aria-hidden="true"
          />
        )}
        <QuestionBody width={question.width || undefined}>
          {question.type === QuestionType.Description && (
            <QuestionnaireQuestionDescriptionComponent
              onContinue={() => onSkipQuestion()}
              question={question}
              hasProduct={!!productId}
              hideSteps={!!repeatOrderHash}
              isConsultation={productId === parameters().product.consultationId}
              isResearch={
                productId === parameters().product.researchId || productId === parameters().product.freeScreenerAsitId
              }
              isImmunotherapy={productId === parameters().product.stepsImmunotherapyId}
              isImmunotherapyConsultation={productId === parameters().product.stepsImmunotherapyConsultationId}
              isCheckAllergy={QuestionnaireType.CheckAllergy === type}
              isAnamnesis={QuestionnaireType.Anamnesis === type}
            />
          )}
          {question.type === QuestionType.Single && (
            <QuestionnaireQuestionSingleComponent onSelect={(option) => onSelectOption(option)} question={question} />
          )}
          {question.type === QuestionType.Clinic && (
            <QuestionnaireQuestionClinicComponent
              onSelect={(location) => onSelectLocation(location)}
              productId={productId}
            />
          )}
          {question.type === QuestionType.Doctor && (
            <QuestionnaireQuestionDoctorComponent onSelect={(doctor) => onSelectDoctor(doctor)} />
          )}
          {question.type === QuestionType.ParcelTerminal && (
            <QuestionnaireQuestionParcelTerminalComponent
              onSelect={(parcelTerminal) => onSelectParcelTerminal(parcelTerminal)}
            />
          )}
          {question.type === QuestionType.TextShort && (
            <QuestionnaireQuestionTextShortComponent
              question={question}
              onContinue={(text) => onContinueWithText(text)}
            />
          )}
          {question.type === QuestionType.Number && (
            <QuestionnaireQuestionTextShortComponent
              type={QuestionInputType.Number}
              question={question}
              onContinue={(text) => onContinueWithText(text)}
              minValue="1"
            />
          )}
          {question.type === QuestionType.TextLong && (
            <QuestionnaireQuestionTextLongComponent
              question={question}
              onContinue={(text) => onContinueWithText(text)}
            />
          )}
          {question.type === QuestionType.Multiple && (
            <QuestionnaireQuestionMultipleComponent
              onSelect={(options) => onSelectMultiOptions(options)}
              question={question}
            />
          )}
          {question.type === QuestionType.Scale && (
            <QuestionnaireQuestionScaleComponent
              onSelect={(value) => onContinueWithText(`${value}`)}
              question={question}
            />
          )}
          {question.type === QuestionType.Rating && (
            <QuestionnaireQuestionRatingComponent
              onSelect={(value) => onContinueWithText(`${value}`)}
              question={question}
            />
          )}
          {question.type === QuestionType.Calendar && (
            <QuestionnaireQuestionCalendarComponent
              // questionId={question.id}
              onSelect={(appointment) => onTimeSelect(appointment)}
            />
          )}
          {question.type === QuestionType.InfoScreen && (
            <QuestionnaireQuestionInfoComponent onContinue={() => onSkipQuestion()} />
          )}
        </QuestionBody>
      </QuestionCentering>
    </QuestionContainer>
  );
}
