import axios, { AxiosRequestConfig } from 'axios';

import { AnswerOption } from '../../entities/answer-option';
import { parameters } from '../../localization/strings';
import { QuestionnaireResponse } from '../../entities/questionnaire-response';
import { ShortAnswer, ShortAnswerEvaluation } from '../../entities/short-answer';
import { AnswerHash } from '../../entities/answer-hash';

export const onboarding = async (
  hash?: string,
  step?: number,
  productId?: number,
  ticketId?: string,
  extraSurveyId?: number,
): Promise<QuestionnaireResponse> => {
  const url = `${parameters().api.baseUrl}/questionnaire/onboarding/${hash || ''}`;
  const config: AxiosRequestConfig = {
    params: {},
  };

  if (step) {
    config.params.step = step;
  }

  if (productId) {
    config.params.productId = productId;
  }

  if (ticketId) {
    config.params.ticketId = ticketId;
  }

  if (extraSurveyId) {
    config.params.extraSurveyId = extraSurveyId;
  }

  const result = await axios.get(url, config);

  return result.data;
};

export const feedback = async (): Promise<QuestionnaireResponse> => {
  const url = `${parameters().api.baseUrl}/questionnaire/feedback`;
  const config: AxiosRequestConfig = {
    params: {},
  };

  const result = await axios.get(url, config);

  return result.data;
};

export const weeklyTreatment = async (patientHash?: string): Promise<QuestionnaireResponse> => {
  const url = `${parameters().api.baseUrl}/questionnaire/treatment/${patientHash}`;
  const config: AxiosRequestConfig = {
    params: {},
  };

  const result = await axios.get(url, config);

  return result.data;
};

export const storeMessagesCalendarAnswer = async (orderAnswerHash: string, hash: string): Promise<string> => {
  const url = `${parameters().api.baseUrl}/calendar/create-event`;

  const result = await axios.post(url, {
    orderAnswerHash,
    answerHash: hash,
  });

  return result.data;
};

export const full = async (token: string, hash: string): Promise<QuestionnaireResponse> => {
  const url = `${parameters().api.baseUrl}/questionnaire/full/${hash}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const storeAnswer = async (
  questionId: number,
  answer: Partial<AnswerOption>,
  token?: string,
  customerHash?: string,
  calendarAnswerHash?: string,
): Promise<AnswerHash> => {
  const url = `${parameters().api.baseUrl}/questionnaire/store-answer`;

  const result = await axios.post(
    url,
    {
      ...answer,
      questionId,
      customerHash,
      calendarAnswerHash,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};

export const answer = async (hash: string): Promise<ShortAnswer> => {
  const url = `${parameters().api.baseUrl}/questionnaire/answer/${hash}`;

  const result = await axios.get(url);

  return result.data;
};

export const answerEvaluation = async (hash: string): Promise<ShortAnswerEvaluation> => {
  const url = `${parameters().api.baseUrl}/questionnaire/evaluation-answer/${hash}`;

  const result = await axios.get(url);

  return result.data;
};

export const selectProduct = async (
  hash: string,
  productId?: number,
  extraProductId?: number,
): Promise<ShortAnswer> => {
  const url = `${parameters().api.baseUrl}/questionnaire/select-product/${hash}`;

  const result = await axios.post(url, { productId, extraProductId });

  return result.data;
};

export const startFree = async (researchMemberId?: string, orderHash?: string): Promise<AnswerHash> => {
  const url = `${parameters().api.baseUrl}/questionnaire/start-free`;

  const result = await axios.post(url, {
    researchMemberId,
    orderHash,
  });

  return result.data;
};

export const answerSubscription = async (hash: string, email: string) => {
  try {
    const url = `${parameters().api.baseUrl}/answers/email`;

    const result = await axios.post(url, {
      hash,
      email,
    });

    return result.data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const storeAnamnesisAnswer = async (patientHash: string, answerHash: string): Promise<AnswerHash> => {
  const url = `${parameters().api.baseUrl}/patients/store-answer`;

  const result = await axios.post(url, {
    patientHash,
    answerHash,
  });

  return result.data;
};

export const anamnesis = async (hash?: string): Promise<QuestionnaireResponse> => {
  const url = `${parameters().api.baseUrl}/questionnaire/clinic-anamnesis/${hash || ''}`;
  const config: AxiosRequestConfig = {
    params: {},
  };

  const result = await axios.get(url, config);

  return result.data;
};
