import React from 'react';
import tw, { styled } from 'twin.macro';

import { useSite } from '../../../hooks/use-site';

interface ComponentProps {
  title: string;
  strength: number;
  iconName: string;
  // symptoms?: FullAnswerSymptom[];
}

const Icon = styled.div<{ strength: number }>`
  ${tw`h-6 w-6 flex-shrink-0 sm:h-12 sm:w-12 text-lg sm:text-4xl rounded-full text-white justify-center inline-flex items-center border print:hidden`}
  ${(props) => props.strength >= 3 && tw`bg-senseHigh-500 border-senseHigh-500`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-500 border-senseMedium-500`}
  ${(props) => props.strength === 1 && tw`bg-senseLow-500 border-senseLow-500`}
  ${(props) => props.strength <= 0 && tw`text-content-700 border-content-500`}
`;

const Container = styled.div<{ strength: number }>`
  ${tw`flex items-center text-left rounded-lg md:rounded-2xl p-2 sm:p-3 sm:pr-7 sm:mb-4 bg-white text-content-700 border-content-500 border w-full`}
  ${tw`print:!p-0 print:!bg-white print:border-0 print:!text-black`}
  ${(props) => props.strength >= 3 && tw`bg-senseHigh-50 border-senseHigh-50 text-senseHigh-500`}
  ${(props) => props.strength === 2 && tw`bg-senseMedium-50 border-senseMedium-50 text-senseMedium-500`}
  ${(props) => props.strength === 1 && tw`bg-senseLow-50 border-senseLow-50 text-senseLow-500`}
`;

const Heading = styled.div`
  ${tw`font-semibold text-md print:!text-md`}
`;

function FoodGroup({ strength, title, iconName }: ComponentProps) {
  const { isfoodId } = useSite();

  return (
    <Container strength={strength}>
      <Icon strength={strength} aria-hidden="true">
        <span className={`icon ${isfoodId ? `am-icon-foodId-${iconName}` : `am-icon-${iconName}`}`} />
      </Icon>
      <div className="ml-1 sm:ml-3 print:!ml-0">
        <Heading>{title}</Heading>
      </div>
    </Container>
  );
}

// FoodGroup.defaultProps = {
//   symptoms: [],
// };

export default FoodGroup;
