import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { useAuth } from '../../hooks/use-auth';
import * as usersService from '../../services/api/users';
import StyledInput from '../common/form/styled-input';
import { strings, parameters } from '../../localization/strings';
import StyledLabel from '../common/form/styled-label';
import Heading2 from '../common/headings/heading2';
import StyledError from '../common/form/styled-error';
import CommonAnimatedLoader from '../common/animated/loader';
import CommonButtonDefault from '../common/buttons/default';
import { ModalKeys } from '../../context/modals';
import { useModal } from '../../hooks/use-modal';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import * as questionnaireApi from '../../services/api/questionnaire';
import { useSite } from '../../hooks/use-site';

interface ComponentProps {
  isQuestionnaire?: boolean;
  orderHash?: string;
}

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  agreeMarketing: boolean;
  agreeResearch: boolean;
  agreeTerms: boolean;
  agreeRemoteConsultation: boolean;
};

function UsersRegisterFormComponent({ isQuestionnaire, orderHash }: ComponentProps) {
  const { setUserToken } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setVisible } = useModal();

  const [firstName, setFirstName] = useState<string>();
  const cookies = new Cookies();

  const hash = cookies.get(COOKIE_NAME_ANSWER);

  const { hasConsultation } = useSite();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      const token = await usersService.register({
        ...data,
        orderHash,
      });

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      window.dataLayer.push({
        event: 'CompleteRegistration',
      });

      setUserToken(token, false);

      if (isQuestionnaire) {
        navigate(parameters().selectPatient);
      } else {
        navigate('/profile/dashboard');
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError('email', { message: e.message });
      }
    }
  };

  useEffect(() => {
    const fetchAnswer = async (h: string) => {
      setIsLoading(true);
      const res = await questionnaireApi.answer(h);

      if (res.customerName) {
        setFirstName(res.customerName);
        setValue('firstName', res.customerName);
      }
      setIsLoading(false);
    };

    if (hash) {
      fetchAnswer(hash);
    }
  }, []);

  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <Heading2 className="mb-6">{strings().registration.title}</Heading2>
      <div className="md:w-9/12 mb-6 md:mb-10 my-5">{strings().registration.text}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <StyledLabel htmlFor="name">{strings().form.name}</StyledLabel>
          <StyledInput
            {...register('firstName', { required: true })}
            fullWidth
            id="firstName"
            name="firstName"
            defaultValue={firstName}
            autoComplete="given-name"
            className={` ${errors.firstName && `!border-red-500`} `}
            type="text"
          />
          {errors.firstName && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
        </div>
        <div className="mt-5">
          <StyledLabel>{strings().form.surname}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.lastName && `!border-red-500`} `}
            type="text"
            autoComplete="family-name"
            {...register('lastName', { required: true })}
          />
          {errors.lastName && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
        </div>
        <div className="mt-5">
          <StyledLabel>{strings().form.email}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.email && `!border-red-500`} `}
            type="email"
            autoComplete="email"
            {...register('email', {
              required: true,
            })}
          />
          {errors.email && (
            <StyledError>
              {errors.email.message ? strings().error.email.isUsed : strings().error.email.required}
            </StyledError>
          )}
        </div>
        <div className="mt-5">
          <StyledLabel htmlFor="phone">{strings().form.phone}</StyledLabel>
          <StyledInput
            fullWidth
            type="tel"
            id="phone"
            inputMode="tel"
            autoComplete="tel"
            className={` ${errors.phone && `!border-red-500`} `}
            {...register('phone', { required: true })}
          />
          {errors.phone && <StyledError>{strings().error.phone.required}</StyledError>}
        </div>
        <div className="mt-5">
          <StyledLabel>{strings().form.password}</StyledLabel>
          <StyledInput
            fullWidth
            className={` ${errors.password && `!border-red-500`} `}
            type="password"
            {...register('password', { required: true, minLength: parameters().password.minLength })}
          />
          {errors.password && (
            <StyledError>
              {errors.password.type === 'required' && strings().error.fieldIsRequired}
              {errors.password.type === 'minLength' &&
                strings().error.password.minLength.replace('{length}', `${parameters().password.minLength}`)}
            </StyledError>
          )}
          <div className="text-gray-400 text-sm mt-2">{strings().form.atLeastEightSymbols}</div>
        </div>

        <div className="mt-5">
          <div className="flex items-center">
            <input
              className="h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded checked:bg-blue-900 checked:hover:bg-blue-900"
              id="agreeTerms"
              type="checkbox"
              {...register('agreeTerms', { required: true })}
            />
            <label htmlFor="agreeTerms" className="ml-2 block text-sm align-middle leading-6 text-gray-900">
              {strings().checkout.checkboxIHaveReadAnd}{' '}
              <CommonButtonDefault className="!text-sm" link onClick={() => onOpenModal(ModalKeys.Privacy)}>
                {strings().checkout.checkboxPrivacyPolicy}
              </CommonButtonDefault>{' '}
              {strings().checkout.checkboxAnd}{' '}
              <CommonButtonDefault className="!text-sm" link onClick={() => onOpenModal(ModalKeys.Terms)}>
                {strings().checkout.checkBoxTermsOfService}
              </CommonButtonDefault>
            </label>
          </div>
        </div>
        {errors.agreeTerms && <StyledError>{strings().error.agreeTerms.required}</StyledError>}

        <div className="flex items-center mt-3">
          <input
            className="h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded checked:bg-blue-900 checked:hover:bg-blue-900"
            id="agreeResearch"
            type="checkbox"
            {...register('agreeResearch', { required: true })}
          />
          <label htmlFor="agreeResearch" className="ml-2 block text-sm align-middle leading-6 text-gray-900">
            {strings().checkout.checkboxIAgreeThatImunodiagnostika}
          </label>
        </div>
        {errors.agreeResearch && <StyledError>{strings().error.required}</StyledError>}

        {hasConsultation && (
          <div className="mt-5">
            <div className="flex space-x-2">
              <div className="flex items-center h-5">
                <input
                  {...register('agreeRemoteConsultation', { required: true })}
                  id="agreeRemoteConsultation"
                  name="agreeRemoteConsultation"
                  type="checkbox"
                  className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 checked:bg-blue-900 checked:hover:bg-blue-900"
                />
              </div>
              <label htmlFor="agreeRemoteConsultation" className="text-sm text-gray-900">
                {strings().checkout.checkboxAgreeRemoteConsultation}
                <CommonButtonDefault
                  type="button"
                  className="!text-sm"
                  link
                  onClick={() => onOpenModal(ModalKeys.RemoteConsultation)}
                >
                  {strings().checkout.checkboxAgreeRemoteConsultationSuffix}
                </CommonButtonDefault>
              </label>
            </div>
            {errors.agreeRemoteConsultation && (
              <StyledError>{strings().error.agreeRemoteConsultation.required}</StyledError>
            )}
          </div>
        )}

        <div className="flex items-center mt-3">
          <div className="flex items-center h-5">
            <input
              className="h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded checked:bg-blue-900 checked:hover:bg-blue-900"
              id="agreeMarketing"
              type="checkbox"
              {...register('agreeMarketing', {})}
            />
          </div>
          <label htmlFor="agreeMarketing" className="ml-2 text-sm text-gray-900">
            {strings().checkout.checkboxIAgreeToGetDoctorAdvice}
          </label>
        </div>

        <CommonButtonDefault primary className="mt-5 md:mt-7" type="submit">
          {strings().button.createAccount}
        </CommonButtonDefault>
      </form>
    </div>
  );
}

UsersRegisterFormComponent.defaultProps = {
  isQuestionnaire: false,
  orderHash: '',
};

export default UsersRegisterFormComponent;
