import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useParams, useNavigate } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';

export default function ResultsRedirectComponent() {
  const COOKIE_NAME_TOKEN = 'admin_token';
  const cookies = new Cookies();
  const { orderId, token } = useParams();
  const navigate = useNavigate();
  const date = new Date();
  const halfHour = new Date(date.getTime() + 30 * 60 * 60 * 1000);

  cookies.set(COOKIE_NAME_TOKEN, token, {
    expires: halfHour,
    domain: window.location.hostname,
    path: '/',
  });

  useEffect(() => {
    if (cookies.get(COOKIE_NAME_TOKEN)) {
      navigate(`/results/preview/${orderId}`);
    }
  }, [orderId]);

  return <CommonAnimatedLoader />;
}
