import { defaultStrings } from '../localization/strings';

export const OrderStatus = {
  New: 'new',
  Paid: 'paid',
  BloodPending: 'blood-pending',
  BloodReceived: 'blood-received',
  Completed: 'completed',
  SampleReceived: 'sampleReceived',
};

export const SignStatus = {
  Pending: 'pending',
  Completed: 'completed',
};

export interface Order {
  id: number;
  sessionId?: number;
  customerId?: number;
  firstName: string;
  lastName: string;
  agreeMarketing: boolean;
  agreeResearch: boolean;
  email: string;
  phone: string;
  hash: string;
  status: string;
  answerStatus: string;
  isBloodRegistered: boolean;
  amount: number;
  locationName: string;
  locationAddress: string;
  locationPhone: string;
  researchMemberId: string;
  paymentMethod?: string;
  paidAt?: string;
  answerHash: string;
  productKey: keyof typeof defaultStrings.products;
  allergensCount: string;
  productId?: number;
  couponCode: string;
  productTitle: string;
  pdfId?: string;
  surveyFinished?: boolean;
}
