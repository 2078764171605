import React, { useRef, useState } from 'react';
import {
  ChatContainer,
  MainContainer,
  Message,
  MessageGroup,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react';
import * as uuid from 'uuid';
import classNames from 'classnames';

import { useAuth } from '../../hooks/use-auth';
import { useMessages } from '../../hooks/use-messages';
import * as messagesApi from '../../services/api/messages';
import { strings } from '../../localization/strings';
import ScreenHeightContainer from '../common/containers/screen-height';
import CommonButtonDefault from '../common/buttons/default';
import { pluralize } from '../../services/grammar/pluralize';
import IMAGES from '../../assets/images';
import { SimpleObject } from '../../entities/simple-object';
import { useSite } from '../../hooks/use-site';

interface ComponentProps {
  doctors?: SimpleObject[];
}

function MessagingChat({ doctors }: ComponentProps) {
  const messageListRef = useRef<MessageList>(null);
  const { token } = useAuth();
  const { messageGroups, isLoading, addMessage } = useMessages();
  const { hasConsultation, theme } = useSite();

  const [value, setValue] = useState('');

  const handleSend = async (messageText: string) => {
    const messageId = uuid.v4();

    addMessage(messageId, messageText);

    setValue('');

    if (messageListRef.current) {
      messageListRef.current.scrollToBottom('smooth');
    }

    await messagesApi.sendMessage(token, {
      messageId,
      message: messageText,
    });
  };

  const handleChange = (htmlContent: string) => {
    setValue(htmlContent);
  };

  const handlePaste = (event: ClipboardEvent) => {
    if (event.clipboardData) {
      event.preventDefault();
      setValue(event.clipboardData.getData('text'));
    }
  };

  const openQuestionnaire = async (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <ScreenHeightContainer>
      <MainContainer>
        <ChatContainer>
          <MessageList loading={isLoading} ref={messageListRef} autoScrollToBottomOnMount autoScrollToBottom>
            <MessageGroup className="cs-message-group--custom">
              <MessageGroup.Messages>
                <Message model={{ type: 'custom' }}>
                  <Message.CustomContent>
                    {doctors && doctors?.length > 0 && hasConsultation ? (
                      <div>
                        <div className="text-xl font-bold">{strings().chat.headingHasDr}</div>
                        <div className="text-lg mb-4 mt-1">
                          {pluralize(doctors?.length, strings().chat.descriptionHasDrPlural)}
                        </div>

                        <div className="space-y-6 mb-5">
                          {doctors.map((person) => (
                            <div key={person.name}>
                              <div className="flex items-center">
                                <img
                                  src={IMAGES.DOCTORS[person.imageUrl as keyof typeof IMAGES.DOCTORS].IMAGE}
                                  alt=""
                                  className="w-16 h-16 rounded-full lg:w-20 lg:h-20 relative"
                                />

                                <div className="font-medium leading-6 pl-4 space-y-1">
                                  <span className="font-bold">{person.name}</span>
                                  <p className="text-md">{person.role}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="text-xl font-bold">
                          {theme === 'allergomedica' ? strings().chat.heading : strings().products.FOOD_ID.chat.heading}
                        </div>
                        <div>{strings().chat.description}</div>
                        <div className="text-sm text-content-700 mb-16">{strings().chat.replyTimeDescription}</div>
                      </>
                    )}
                  </Message.CustomContent>
                </Message>
              </MessageGroup.Messages>
            </MessageGroup>

            {messageGroups.map((g) => (
              <MessageGroup key={g.id} direction={g.direction}>
                <MessageGroup.Messages>
                  {g.messages.map((m) =>
                    m.showConsultationBooking ? (
                      <Message
                        key={m.messageId}
                        className={classNames({
                          'is-important': !!m.isImportant,
                        })}
                        model={{ type: 'custom' }}
                      >
                        <Message.CustomContent>
                          <div>{m.message}</div>
                          <CommonButtonDefault primary chat onClick={() => openQuestionnaire(m.calendarUrl)}>
                            {strings().shortTestAnswer.bookConsultation}
                          </CommonButtonDefault>
                        </Message.CustomContent>
                      </Message>
                    ) : (
                      <Message
                        key={m.messageId}
                        className={classNames({
                          'is-important': !!m.isImportant,
                        })}
                        model={{
                          type: 'html',
                          payload: m.message,
                        }}
                      />
                    ),
                  )}
                </MessageGroup.Messages>
              </MessageGroup>
            ))}
          </MessageList>

          <MessageInput
            attachButton={false}
            value={value}
            onChange={handleChange}
            onSend={handleSend}
            onPaste={handlePaste}
            placeholder={strings().chat.inputPlaceholder}
          />
        </ChatContainer>
      </MainContainer>
    </ScreenHeightContainer>
  );
}

MessagingChat.defaultProps = { doctors: [] };

export default MessagingChat;
