import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { Question } from '../../../entities/question';
import QuestionnaireFooterButtons from '../footer-buttons';
import { strings } from '../../../localization/strings';
import StyledError from '../../common/form/styled-error';
import { useSite } from '../../../hooks/use-site';

const QuestionButton = styled.button<{ current: number; value: number; isLast: boolean }>`
  ${tw`w-full block py-3 leading-8 pl-2 pr-2 text-gray-300 text-lg focus:outline-none border`}
  ${(props) => props.current < props.value && tw`bg-white border-content-500`}
  ${(props) => props.current > 8 && props.current >= props.value && tw`bg-success-800 text-white`}
 
  ${(props) => props.current <= 8 && props.current >= props.value && tw`bg-yellow-400 text-white`}
  ${(props) => props.current <= 6 && props.current >= props.value && tw`bg-amber-500 text-white`}
  ${(props) => props.current <= 4 && props.current >= props.value && tw`bg-orange-500 text-white`}
  ${(props) => props.current <= 2 && props.current >= props.value && tw`bg-red-500 text-white`}
  ${(props) => props.isLast && tw`rounded-r-lg`}
  ${(props) => props.value === 1 && tw`rounded-l-lg`}
`;

const QuestionButtonContainer = styled.div<{ value: number }>`
  ${tw`relative -ml-px`}
  padding-top: 72px;
  ${(props) => props.value === 1 && tw`ml-0`}
`;

const ThreeColumns = styled.div`
  ${tw`flex gap-7 items-end sm:mb-4`}
`;

const TwoColumns = styled.div`
  ${tw`grid grid-cols-2 sm:hidden pt-3 mb-3`}
`;

const ButtonsContainer = styled.div<{ itemsAmount: number }>`
  ${tw`grid mx-auto sm:w-7/12 md:w-8/12 sm:mx-0`}
  ${(props) => props.itemsAmount === 7 && tw`grid-cols-7`}
  ${(props) => props.itemsAmount === 10 && tw`grid-cols-10`}
`;

interface ComponentProps {
  question: Question;
  onSelect?: (value: number) => void;
}

const generateRange = (min: number, max: number): number[] => {
  const res = [];

  let start = min;

  while (start <= max) {
    res.push(start);
    start += 1;
  }

  return res;
};

function QuestionnaireQuestionRatingComponent({ question, onSelect }: ComponentProps) {
  const [current, setCurrent] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const { locale } = useSite();

  const onSelectValue = (value: number) => {
    setCurrent(value);
    setIsError(false);
  };

  const onContinue = () => {
    if (!current) {
      setIsError(true);
    } else if (onSelect) {
      setIsError(false);
      onSelect(current);
    }
  };

  const options = generateRange(question.scaleMinValue, question.scaleMaxValue);

  return (
    <>
      <ThreeColumns>
        <div className={`${locale === 'pl' ? 'w-32' : 'w-20'} hidden sm:block`}>{question.scaleTitleLeft}</div>
        <ButtonsContainer itemsAmount={options.length}>
          {options.map((option) => (
            <QuestionButtonContainer value={option} className="group" key={`${question.id}-${option}`}>
              <QuestionButton
                isLast={option === options.length}
                current={current}
                value={option}
                onClick={() => onSelectValue(option)}
              >
                {option}
              </QuestionButton>
            </QuestionButtonContainer>
          ))}
        </ButtonsContainer>
        <div className="sm:w-3/12 md:w-2/12 hidden sm:block">{question.scaleTitleRight}</div>
      </ThreeColumns>
      <TwoColumns>
        <div className="text-sm leading-4">
          <div className="w-1/2">{question.scaleTitleLeft}</div>
        </div>
        <div className="text-right leading-4 text-sm">{question.scaleTitleRight}</div>
      </TwoColumns>

      {isError && <StyledError>{strings().error.youMustSelect}</StyledError>}

      <QuestionnaireFooterButtons onContinue={() => onContinue()} />
    </>
  );
}

QuestionnaireQuestionRatingComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionRatingComponent;
