import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { strings } from '../../localization/strings';
import ContainerMd from '../../components/common/containers/containerMd';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';

const cookies = new Cookies();

export default function FeedbackSuccessPageComponent() {
  useEffect(() => {
    cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });
  }, []);

  return (
    <div>
      <ContainerMd>
        <div className="md:w-9/12">
          <div className="mb-6 md:mb-10">
            <AlertPrimary
              type="success"
              title={strings().feedback.success_title}
              text={strings().feedback.success_text}
            />

            <div className="mt-10">
              <NavLink
                key="profile"
                to="/"
                className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
              >
                {strings().checkout.previewProfile}
              </NavLink>
            </div>
          </div>
        </div>
      </ContainerMd>
    </div>
  );
}
