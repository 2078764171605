import React from 'react';

import { parameters, strings } from '../../localization/strings';
import Heading1 from '../../components/common/headings/heading1';
import ContainerMd from '../../components/common/containers/containerMd';
import ExternalText from '../../components/text/external-text';

interface ComponentProps {
  type: string;
}

export default function TextPageComponent({ type }: ComponentProps) {
  if (type === 'privacy') {
    return (
      <ContainerMd>
        <Heading1>{strings().textPage.privacyPolicy.title}</Heading1>

        <ExternalText url={parameters().textPage.privacyPolicy} />
      </ContainerMd>
    );
  }

  if (type === 'terms') {
    return (
      <ContainerMd>
        <Heading1>{strings().textPage.termsAndConditions.title}</Heading1>
        <ExternalText url={parameters().textPage.terms} />
      </ContainerMd>
    );
  }

  return <div>Empty page</div>;
}
