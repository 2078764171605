import React from 'react';
import tw, { styled } from 'twin.macro';

import UsersLoginForm from '../../components/users/login-form';
import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';
import { useSite } from '../../hooks/use-site';

const BgImage = styled.img`
  top: 0;
  width: 18rem;
  height: auto;
  ${tw`absolute z-0 left-[-5rem]`};
`;

function UsersLoginPageComponent() {
  const { isAllergomedica } = useSite();

  return (
    <div className="min-h-full flex items-center justify-center mx-auto w-full max-w-sm lg:w-96 py-12 px-4 relative">
      {isAllergomedica && <BgImage src={IMAGES.BLOB.FIRST} alt="" />}
      <div className="relative z-10 w-full">
        {/* Mes po sekmingo pirkimo turim sita pacia prisijungimo forma jeigu pagaunam kad useris registruotas */}
        <Heading2 className="mb-16">{strings().login.title}</Heading2>
        <UsersLoginForm />
      </div>
    </div>
  );
}

export default UsersLoginPageComponent;
