import React from 'react';
import { useParams } from 'react-router-dom';

import { QuestionnaireContextProvider } from '../../context/questionnaire';
import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';

export default function QuestionnaireWeeklyTreatmentPageComponent() {
  const { patientHash } = useParams();

  const intProductId = 0;

  return (
    <QuestionnaireContextProvider
      type={QuestionnaireType.WeeklyTreatment}
      productId={intProductId}
      patientHash={patientHash}
    >
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
