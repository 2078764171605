import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import TextClinicPrivacyPolicy from '../text/clinic-privacy-policy';

export default function ModalClinicPrivacyPolicy() {
  return (
    <ModalText type={ModalKeys.ClinicPrivacyPolicy} title={strings().textPage.privacyPolicy.title}>
      <TextClinicPrivacyPolicy />
    </ModalText>
  );
}
