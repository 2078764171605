import React from 'react';
import classNames from 'classnames';
import tw, { styled } from 'twin.macro';
import { CheckIcon } from '@heroicons/react/solid';

import { parameters, strings } from '../../localization/strings';

const StepName = styled.div`
  ${tw`relative mt-2 text-center sm:text-left sm:max-w-[110px]`}
`;

interface ComponentProps {
  current: number;
  isConsultation?: boolean;
  isResearch?: boolean;
  isImmunotherapy?: boolean;
  isImmunotherapyConsultation?: boolean;
}

function OrderProgressComponent({
  current,
  isConsultation,
  isResearch,
  isImmunotherapy,
  isImmunotherapyConsultation,
}: ComponentProps) {
  let steps = [];

  if (isConsultation) {
    steps = strings().order.stepsConsultation;
  } else if (isResearch) {
    steps = strings().order.stepsResearch;
  } else if (isImmunotherapy) {
    steps = strings().order.stepsImmunotherapy;
  } else if (isImmunotherapyConsultation) {
    steps = strings().order.stepsConsultation;
  } else if (parameters().features.questionnaireAfterSale) {
    steps = strings().order.stepsQuestionnaireAfterSale;
  } else {
    steps = strings().order.steps;
  }

  return (
    <nav className="flex justify-center">
      <ol className="w-full sm:w-auto flex flex-col sm:flex-row justify-center">
        {steps.map((step: any, stepIdx: number) => (
          <li
            key={step}
            className={`flex sm:block pb-[20px] sm:pb-0 w-full sm:w-auto ${
              stepIdx + 1 === steps.length && 'sm:w-[130px]'
            }`}
          >
            <div className="relative w-auto sm:w-full">
              {stepIdx + 1 < steps.length && (
                <div
                  className="absolute bottom-[-25px] h-full sm:h-auto left-0.5 sm:inset-0 flex justify-center ml-[-2px] sm:ml-0 items-center w-full"
                  aria-hidden="true"
                >
                  <div
                    className={classNames(
                      'w-0.5 h-[20px] sm:h-0.5 sm:w-full',
                      stepIdx < current ? 'bg-green-500' : 'bg-gray-200',
                      stepIdx + 1 === steps.length && 'sm:hidden',
                    )}
                  />
                </div>
              )}
              <div
                className={classNames(
                  'group relative w-8 h-8 flex items-center justify-center rounded-full border-2 text-center',
                  stepIdx > current ? 'bg-white border-gray-300 text-blue-500' : '',
                  stepIdx === current ? 'bg-blue-500 border-blue-500 text-white' : '',
                  stepIdx < current ? 'bg-green-500 border-green-500 text-white' : '',
                )}
              >
                {stepIdx < current ? <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" /> : ''}
              </div>
            </div>
            <StepName className={classNames('ml-4 sm:ml-0', stepIdx !== steps.length - 1 ? 'relative sm:mr-20' : '')}>
              {step}
            </StepName>
          </li>
        ))}
      </ol>
    </nav>
  );
}

OrderProgressComponent.defaultProps = {
  isConsultation: false,
  isResearch: false,
  isImmunotherapy: false,
  isImmunotherapyConsultation: false,
};

export default OrderProgressComponent;
