import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import CheckoutFormComponent from '../../components/checkout/form';
import { FormCheckout } from '../../entities/forms/checkout';
import { Order } from '../../entities/order';
import { repeatPayment, validatePayment } from '../../services/api/payments';
import { ShortAnswerProduct } from '../../entities/short-answer';

export default function PaymentsCancelPageComponent() {
  const { hash } = useParams<{ hash: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order>();
  const [product, setProduct] = useState<ShortAnswerProduct>();

  useEffect(() => {
    const fetchOrder = async (h: string) => {
      const res = await validatePayment(h);

      setOrder(res.order);
      setProduct(res.product);

      setIsLoading(false);
    };

    if (hash) {
      fetchOrder(hash);
    }
  }, [hash, setOrder]);

  const onComplete = async (data: FormCheckout) => {
    if (hash) {
      const payment = await repeatPayment(data, hash);

      document.location.href = payment.url;
    }
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && hash && product && (
        <CheckoutFormComponent
          isFailed
          onComplete={onComplete}
          firstName={order?.firstName}
          lastName={order?.lastName}
          email={order?.email}
          phone={order?.phone}
          preselectBank={order?.paymentMethod}
          product={product}
        />
      )}
    </>
  );
}
