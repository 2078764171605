import React, { createContext, useState, useCallback, useMemo } from 'react';

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  setMenuState: (state: boolean) => void;
  setMenuLogoState: (state: boolean) => void;
  hasMenu: boolean;
  hasLogo: boolean;
  isSticky: boolean;
  isPageNotFound: boolean;
  hasProgressBar: boolean;
  setStickyState: (state: boolean) => void;
  setPageNotFoundState: (state: boolean) => void;
  setProgressBarState: (state: boolean) => void;
}

const LayoutContext = createContext<ContextValue>({
  setMenuState: () => null,
  setMenuLogoState: () => null,
  hasMenu: false,
  hasLogo: true,
  hasProgressBar: false,
  setStickyState: () => null,
  isSticky: false,
  isPageNotFound: false,
  setPageNotFoundState: () => null,
  setProgressBarState: () => null,
});

function LayoutContextProvider({ children }: ComponentProps) {
  const [hasMenu, setHasMenu] = useState<boolean>(false);
  const [hasLogo, setHasLogo] = useState<boolean>(true);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [hasProgressBar, setHasProgressBar] = useState<boolean>(false);
  const [isPageNotFound, setPageNotFound] = useState<boolean>(false);

  const setMenuState = useCallback(
    (state = true) => {
      setHasMenu(state);
    },
    [setHasMenu],
  );

  const setMenuLogoState = useCallback(
    (state = true) => {
      setHasLogo(state);
    },
    [setHasLogo],
  );

  const setPageNotFoundState = useCallback(
    (state = true) => {
      setPageNotFound(state);
    },
    [setPageNotFound],
  );

  const setStickyState = useCallback(
    (state = true) => {
      setIsSticky(state);
    },
    [setIsSticky],
  );

  const setProgressBarState = useCallback(
    (state = true) => {
      setHasProgressBar(state);
    },
    [setHasProgressBar],
  );

  const contextValue = useMemo(
    () => ({
      setMenuState,
      setMenuLogoState,
      setStickyState,
      setPageNotFoundState,
      setProgressBarState,
      hasProgressBar,
      isPageNotFound,
      hasMenu,
      hasLogo,
      isSticky,
    }),
    [
      setMenuState,
      setMenuLogoState,
      hasProgressBar,
      setProgressBarState,
      isPageNotFound,
      setPageNotFoundState,
      setStickyState,
      isSticky,
      hasLogo,
      hasMenu,
    ],
  );

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
}

export { LayoutContext, LayoutContextProvider };
