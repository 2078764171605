import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as questionnaireApi from '../../services/api/questionnaire';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { setAnswerCookie } from '../../context/questionnaire';

function QuestionnaireOrderedPageComponent() {
  const navigate = useNavigate();
  const { orderHash, productId } = useParams();

  useEffect(() => {
    const createAnswer = async () => {
      const res = await questionnaireApi.startFree(undefined, orderHash);

      setAnswerCookie(res.hash);

      navigate(`/questionnaire/repeat/${orderHash}/${productId}`);
    };

    createAnswer();
  }, []);

  return <CommonAnimatedLoader />;
}

export default QuestionnaireOrderedPageComponent;
