import axios from 'axios';

import { parameters } from '../../localization/strings';
import { FormPatient } from '../../entities/forms/patient';
import { Patient } from '../../entities/patient';
import { PatientTreatmentScore } from '../../entities/patient-treatment-score';

export const createPatient = async (data: FormPatient, token?: string): Promise<Patient> => {
  const url = `${parameters().api.baseUrl}/patients/create`;

  const result = await axios.post(
    url,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};

export const getPatients = async (token?: string): Promise<Patient[]> => {
  const url = `${parameters().api.baseUrl}/patients/list`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getPatient = async (token: string, hash: string): Promise<Patient> => {
  const url = `${parameters().api.baseUrl}/patients/view/${hash}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getPatientTreatmentScore = async (token: string, patientHash: string): Promise<PatientTreatmentScore> => {
  const url = `${parameters().api.baseUrl}/patients/treatment-score/${patientHash}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getPatientDoctor = async (hash: string): Promise<Patient> => {
  const url = `${parameters().api.baseUrl}/patients/doctor-info/${hash}`;

  const result = await axios.get(url);

  return result.data;
};
