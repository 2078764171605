import React from 'react';

import { Question } from '../../../entities/question';
import QuestionnaireFooterButtons from '../footer-buttons';

interface ComponentProps {
  onContinue?: () => void;
}

function QuestionnaireQuestionInfoComponent({ onContinue }: ComponentProps) {
  const onButtonClick = () => {
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <div className="text-center">
      <QuestionnaireFooterButtons onContinue={() => onButtonClick()} />
    </div>
  );
}

QuestionnaireQuestionInfoComponent.defaultProps = {
  onContinue: null,
};

export default QuestionnaireQuestionInfoComponent;
