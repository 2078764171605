import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { XIcon } from '@heroicons/react/solid';
import Cookies from 'universal-cookie';

import { parameters, strings } from '../../../localization/strings';

interface ComponentProps {
  providedFeedback?: boolean;
  hasCompletedOrders?: boolean;
}

function StickyBanner({ providedFeedback, hasCompletedOrders }: ComponentProps) {
  const cookies = new Cookies();

  const [stickyVisible, setStickyVisible] = useState<boolean>(false);

  const hasDisableBannerCookie = cookies.get('disableStickyBanner');

  useEffect(() => {
    if (hasCompletedOrders && providedFeedback === false) {
      setStickyVisible(true);
    }

    if (hasDisableBannerCookie || providedFeedback) {
      setStickyVisible(false);
    }
  }, [hasCompletedOrders, providedFeedback]);

  const disableSticky = () => {
    const expirationTime = new Date();

    expirationTime.setDate(expirationTime.getDate() + 61);
    setStickyVisible(false);
    cookies.set('disableStickyBanner', '1', { expires: expirationTime, path: '/' });
  };

  const onFeedbackClick = () => {
    setStickyVisible(false);
  };

  return stickyVisible ? (
    <div className="fixed inset-x-0 bottom-0 z-10">
      <div className="bg-blue-800">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-7 xl:px-0">
          <div className="flex justify-center items-center flex-wrap">
            <div className="flex flex-col sm:text-center sm:block w-full md:w-auto mr-4 md:mr-7 text-[16px] text-white leading-tight">
              <span className="inline">
                <span className="font-medium mr-2 inline-flex">
                  {strings().reviewsBanner.title.replace('{companyName}', parameters().appName)}
                </span>
              </span>
              <span className="inline-block mt-1 sm:mt-0 sm:inline">{strings().reviewsBanner.text}</span>
            </div>

            <div className="mt-3 md:mt-0 flex-shrink-0 w-full sm:w-auto">
              <Link
                to="/questionnaire/feedback"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-blue-900 bg-white hover:bg-indigo-50 w-full sm:w-auto"
                onClick={onFeedbackClick}
              >
                {strings().reviewsBanner.button}
              </Link>
            </div>

            <div className="absolute inset-y-0 sm:inset-y-2.5 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
              <button
                type="button"
                onClick={disableSticky}
                className="flex rounded-md p-2 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">{strings().reviewsBanner.dismiss}</span>
                <XIcon className="w-5 h-5 text-white shrink-0" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

StickyBanner.defaultProps = {
  providedFeedback: false,
  hasCompletedOrders: false,
};

export default StickyBanner;
