import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { useAuth } from '../../hooks/use-auth';
import { validatePayment } from '../../services/api/payments';
import { Order, OrderStatus } from '../../entities/order';
import { strings, parameters } from '../../localization/strings';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import ContainerMd from '../../components/common/containers/containerMd';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import { useSite } from '../../hooks/use-site';
import { ShortAnswerProduct } from '../../entities/short-answer';

const cookies = new Cookies();
let timer: ReturnType<typeof setInterval>;

export default function PaymentsSuccessPageComponent() {
  const { token, user } = useAuth();
  const { hash, productId } = useParams();
  const { locale } = useSite();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>();
  const [product, setProduct] = useState<ShortAnswerProduct>();
  const [isWaiting, setIsWaiting] = useState<boolean>(true);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [isResearch, setIsResearch] = useState<boolean>(false);
  const [hasExtraSurvey, setHasExtraSurvey] = useState<boolean>(false);

  // <!-- Event snippet for Example conversion page -->

  useEffect(() => {
    if (productId) {
      setIsResearch(parseInt(productId, 10) === parameters().product.researchId);
    }
    const checkForPayment = async () => {
      const res = await validatePayment(hash || '');

      setOrder(res.order);
      setIsWaiting(res.order.status === OrderStatus.New);
      setIsRegistered(res.isRegistered);
      setHasExtraSurvey(!!res.product.extraSurveyId);
      setProduct(res.product);

      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-CONVERSION_ID/CONVERSION_LABEL',
          value: res.order.amount,
          currency: locale === 'pl' ? 'PLN' : 'EUR',
        });
      }

      window.dataLayer.push({
        event: 'Purchase',
        ecommerce: {
          purchase: {
            value: res.order.amount,
            currency: locale === 'pl' ? 'PLN' : 'EUR',
            coupon: res.order.couponCode,
            transaction_id: res.order.id,
          },
        },
      });

      if (window.fbq) {
        window.fbq('track', 'Purchase', { value: res.order.amount, currency: locale === 'pl' ? 'PLN' : 'EUR' });
      }

      return res.order;
    };

    if (!timer) {
      timer = setInterval(async () => {
        const o = await checkForPayment();

        if (o.status !== OrderStatus.New) {
          cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });
          clearInterval(timer);
          // if (user) {
          //   navigate(parameters().firstPageUrl);
          // }
        }
      }, 2000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [hash, navigate, token, user]);

  return (
    <div>
      {isWaiting && (
        <div className="min-h-screen text-center pt-10 flex flex-col items-center justify-center relative text-lg">
          <div className="mb-3">
            <CommonAnimatedSpinner />
          </div>

          {!isResearch && <span>{strings().loading.wait_until_check}</span>}
          {isResearch && <span>{strings().loading.wait_until_sign_check}</span>}
        </div>
      )}
      {!isWaiting && order && (
        <ContainerMd>
          <div className="md:w-9/12">
            <div className="mb-6 md:mb-10">
              {!isResearch && (
                <AlertPrimary
                  type="success"
                  title={strings().checkout.success_title}
                  text={strings().checkout.success_text}
                />
              )}
              {isResearch && (
                <AlertPrimary
                  type="success"
                  title={strings().checkout.sign_success_title}
                  text={strings().checkout.sign_success_text}
                />
              )}

              {!hasExtraSurvey && (
                <div className="mt-10">
                  <NavLink
                    key="profile"
                    to="/"
                    className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
                  >
                    {strings().checkout.previewProfile}
                  </NavLink>
                </div>
              )}
              {hasExtraSurvey && (
                <div className="mt-10">
                  <NavLink
                    to={`/questionnaire/repeat/${order.hash}/${order.productId}/${product?.extraSurveyId}`}
                    className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
                  >
                    {strings().checkout.fillQuestionnaire}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </ContainerMd>
      )}
    </div>
  );
}
