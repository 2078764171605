import React from 'react';

import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import UsersForgotPasswordForm from '../../components/users/forgot-password-form';

function UsersForgotPasswordPageComponent() {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <Heading2 className="mb-6">{strings().forgotPassword.requestPasswordReset}</Heading2>
        <UsersForgotPasswordForm />
      </div>
    </div>
  );
}

export default UsersForgotPasswordPageComponent;
