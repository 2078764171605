import React from 'react';

import { strings } from '../../../localization/strings';
import { FullAnswerDiseaseRecommendation } from '../../../entities/full-answer';
import CommonButtonDefault from '../../common/buttons/default';
import { getDate } from '../../../services/formatter/date';

interface ComponentProps {
  recommendation: FullAnswerDiseaseRecommendation;
  isPreview: boolean;
  onChangeStatus: (status: string) => void;
}

function RecommendationComponent({ recommendation, onChangeStatus, isPreview }: ComponentProps) {
  return (
    <div className="p-4 md:px-5 md:py-7 rounded-lg md:rounded-xl bg-white drop-shadow-xl print:drop-shadow-none print:rounded-none print:!p-0">
      <div className="text-xl md:text-2xl print:!text-base font-bold mb-3 leading-5">{recommendation.title}</div>
      {/* eslint-disable react/no-danger */}
      <div
        className="print:text-sm"
        dangerouslySetInnerHTML={{ __html: recommendation.comment || recommendation.longDescription }}
      />
      {!isPreview && (
        <div className="mt-3 print:hidden">
          {recommendation.status === 'new' ? (
            <div className="flex items-center justify-center sm:justify-end space-x-5">
              <CommonButtonDefault onClick={() => onChangeStatus('bad')}>
                {strings().recommendations.button.notWork}
              </CommonButtonDefault>
              <CommonButtonDefault onClick={() => onChangeStatus('good')}>
                {strings().recommendations.button.work}
              </CommonButtonDefault>
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row sm:items-center justify-center sm:justify-end sm:space-x-5 ">
              <div className="text-content-700 text-sm text-center mb-3 sm:mb-0 sm:text-right">
                {recommendation.status === 'good'
                  ? strings().recommendations.testedGood
                  : strings().recommendations.testedBad}
                <br />
                {recommendation.testedAt &&
                  strings().recommendations.testedTime.replace('{date}', getDate(recommendation.testedAt))}
              </div>
              <CommonButtonDefault onClick={() => onChangeStatus('new')}>
                {recommendation.status === 'good'
                  ? strings().recommendations.button.testedWork
                  : strings().recommendations.button.testedNotWork}
              </CommonButtonDefault>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

RecommendationComponent.defaultProps = {};

export default RecommendationComponent;
