import axios, { AxiosError } from 'axios';

import { parameters } from '../../localization/strings';
import { AnswerHash } from '../../entities/answer-hash';
import { ApiError } from '../../entities/api-error';

export const createAnswer = async (token: string, productId: number): Promise<AnswerHash> => {
  const url = `${parameters().api.baseUrl}/answers/create`;

  try {
    const result = await axios.post(
      url,
      {
        productId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};
