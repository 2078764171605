import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps {
  title: string;
  activeItem: boolean;
}

const Checkmark = styled.input`
  ${tw`h-3 w-3 md:h-4 md:w-4 mt-0.5 sm:mt-1 print:!h-3 print:!w-3 text-content-800 border-content-500 rounded-full`}

  &:checked, &:checked:hover {
    ${tw`bg-content-800`}
  }
`;

const CheckmarkPlaceholder = styled.div`
  ${tw`h-3 w-3 md:h-4 md:w-4 flex-shrink-0 rounded-full border border-content-600 print:!w-3 print:!h-3`}
`;

const Trigger = styled.div<{ active: boolean }>`
  ${tw`relative flex flex-shrink-0 items-center md:inline-flex`}
  ${(props) => (!props.active ? tw`text-content-700` : tw`text-content-800`)}
`;

function SymptomComponent({ activeItem, title }: ComponentProps) {
  return (
    <Trigger active={activeItem}>
      {activeItem ? (
        <Checkmark
          id="comments"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          checked
          readOnly
        />
      ) : (
        <CheckmarkPlaceholder />
      )}
      <div className="text-sm ml-2 md:ml-3 md:text-base print:!text-sm">{title}</div>
    </Trigger>
  );
}

export default SymptomComponent;
