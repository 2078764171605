import React from 'react';
import tw, { styled } from 'twin.macro';
import { useSwiper } from 'swiper/react';

import CommonButtonDefault from '../common/buttons/default';
import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';

const ButtonsContainer = styled.div`
  ${tw`flex flex-row pt-1 md:pt-7 justify-end`}
`;

const SpacedButton = styled(CommonButtonDefault)`
  ${tw`ml-5`}
`;

interface ComponentProps {
  disableContinue?: boolean;
  disableBack?: boolean;
  onContinue: () => void;
}

function QuestionnaireFooterButtons({ disableContinue, disableBack, onContinue }: ComponentProps) {
  const swiper = useSwiper();

  const onGoBack = () => {
    window.scrollTo(0, 0);
    swiper.slidePrev();
  };

  return (
    <ButtonsContainer>
      <CommonButtonDefault type="button" disabled={disableBack} onClick={() => onGoBack()}>
        {strings().button.goBack}
      </CommonButtonDefault>
      <SpacedButton
        className="group relative ml-5"
        type="submit"
        primary
        disabled={disableContinue}
        onClick={() => onContinue()}
      >
        <span className="inline-block pr-3"> {strings().button.continue}</span>
        <img
          src={IMAGES.ICONS.ARROWRIGHT}
          className="transition-all absolute right-5 top-1/2 -mt-2 group-hover:right-4"
          alt=""
        />
      </SpacedButton>
    </ButtonsContainer>
  );
}

QuestionnaireFooterButtons.defaultProps = {
  disableContinue: false,
  disableBack: false,
};

export default QuestionnaireFooterButtons;
