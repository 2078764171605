import React from 'react';
import tw, { styled } from 'twin.macro';

import CommonAnimatedSpinner from './spinner';

const LoaderBackdrop = styled.div<{ absolute?: boolean }>`
  ${(props) => (!props.absolute ? tw`fixed` : tw`absolute`)}
  ${tw`top-0 left-0 w-full h-full bg-white opacity-75`}
  z-index: 900
`;

const LoaderContainer = styled.div<{ absolute?: boolean }>`
  ${(props) => (!props.absolute ? tw`fixed` : tw`absolute`)}
  ${tw`top-0 left-0 w-screen h-screen flex justify-center items-center `}
  z-index: 950;
`;

interface ComponentProps {
  absolute?: boolean;
}

function CommonAnimatedLoader({ absolute }: ComponentProps) {
  return (
    <>
      <LoaderBackdrop absolute={absolute} />
      <LoaderContainer absolute={absolute}>
        <CommonAnimatedSpinner />
      </LoaderContainer>
    </>
  );
}

CommonAnimatedLoader.defaultProps = {
  absolute: false,
};

export default CommonAnimatedLoader;
