import axios from 'axios';

import { parameters } from '../../localization/strings';
import { Coupon } from '../../entities/coupon';

export const validate = async (code: string, productId: number | undefined): Promise<Coupon> => {
  const url = `${parameters().api.baseUrl}/coupons/validate`;

  try {
    const result = await axios.post(url, { code, productId });

    return result.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};
