import React, { useEffect, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css/bundle';

import { useQuestionnaire } from '../../hooks/use-questionnaire';
import QuestionnaireQuestion from './question';
import CommonAnimatedLoader from '../common/animated/loader';
import QuestionnaireProgressBar from './progress-bar';
import { QuestionnaireType } from '../../entities/questionnaire-type';
import { parameters, strings } from '../../localization/strings';
import AlertPrimary from '../common/alerts/alert-primary';
import ContainerMd from '../common/containers/containerMd';

interface ComponentProps {
  productId: number;
}

function QuestionnaireSliderComponent({ productId }: ComponentProps) {
  const {
    type,
    questions,
    initialSlide,
    isLast,
    answerHash,
    orderHash,
    isQuotaReached,
    patientHashContext,
    extraProductId,
  } = useQuestionnaire();
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [current, setCurrent] = useState<number>(0);
  const navigate = useNavigate();

  const { patientHash } = useParams();

  if (productId && productId === parameters().product.freeScreenerAsitId) {
    document.location.href = parameters().freeResearchUrl;
  }

  if (productId && productId === parameters().product.researchId) {
    document.location.href = parameters().freeResearchUrl;
  }

  if (isLast) {
    if (orderHash) {
      navigate(`/research/success/${orderHash}/${productId}`);
    } else if (productId && productId === parameters().product.freeScreenerAsitId) {
      document.location.href = parameters().freeResearchUrl;
    } else if (productId && productId === parameters().product.researchId) {
      document.location.href = parameters().freeResearchUrl;
      // navigate(`/questionnaire/register`);
    } else if (extraProductId) {
      navigate(`/questionnaire/select-presale/${extraProductId}`);
    } else if (type === QuestionnaireType.Onboarding) {
      // navigate(`/questionnaire/answer/${answerHash}`);
      navigate(`/questionnaire/register`);
    } else if (type === QuestionnaireType.Feedback) {
      navigate(`/questionnaire/success`);
    } else if (type === QuestionnaireType.WeeklyTreatment) {
      if (patientHashContext && answerHash) {
        navigate(`/questionnaire/treatment-weekly/success/${patientHashContext}/${answerHash}`);
      }
    } else if (type === QuestionnaireType.Anamnesis) {
      navigate(`/questionnaire/anamnesis/${patientHash}/send`);
    } else {
      // navigate(`/payments/checkout/${answerHash}`);
      navigate(`/questionnaire/register`);
    }
  }

  useEffect(() => {
    if (swiper && initialSlide) {
      swiper.slideTo(initialSlide);
    }
  }, [swiper, initialSlide]);

  useEffect(() => {
    if (productId && (productId === 2 || productId === 3)) {
      if (window.fbq) {
        window.fbq('track', 'AddToCart');
      }
    }
  }, []);

  const initializeSwiper = (s: SwiperClass) => {
    setSwiper(s);
  };

  if (isQuotaReached) {
    return (
      <ContainerMd>
        <AlertPrimary
          type="failed"
          title={type === QuestionnaireType.Feedback ? strings().error.feedback.title : strings().error.common.title}
          text={
            type === QuestionnaireType.Feedback
              ? strings().error.feedback.description
              : strings().error.common.description
          }
        />
      </ContainerMd>
    );
  }

  return questions.length ? (
    <>
      <QuestionnaireProgressBar total={questions.filter((q) => q.visible).length} current={current} />
      <Swiper
        slidesPerView={1}
        noSwiping
        onSwiper={initializeSwiper}
        onSlideChange={(s) => setCurrent(s.activeIndex)}
        initialSlide={initialSlide}
        allowTouchMove={false}
      >
        {questions
          .filter((q) => q.visible)
          .map((question) => {
            return (
              <SwiperSlide key={question.id}>
                <QuestionnaireQuestion productId={productId} question={question} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  ) : (
    <CommonAnimatedLoader />
  );
}

export default QuestionnaireSliderComponent;
