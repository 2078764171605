import React from 'react';
import tw, { styled } from 'twin.macro';

import { strings } from '../../../localization/strings';
import { useSite } from '../../../hooks/use-site';

const Circle = styled.div<{ state: string }>`
  ${tw`h-4 w-4 lg:h-6 lg:w-6 flex-shrink-0 border border-content-700 rounded-full mr-3`}
  ${(props) => props.state === 'high' && tw`bg-senseHigh-500 border-senseHigh-500`}
  ${(props) => props.state === 'medium' && tw`bg-senseMedium-500 border-senseMedium-500`}
  ${(props) => props.state === 'low' && tw`bg-senseLow-500 border-senseLow-500`}
  ${(props) => props.state === 'default' && tw`border-content-600`}
`;

interface ComponentProps {
  hasNumValue?: boolean;
}

function LegendBlock({ hasNumValue }: ComponentProps) {
  const { isAllergomedica } = useSite();

  return (
    <div className="bg-content-300 print:rounded-0 rounded-lg md:rounded-2xl p-3 md:px-6 print:py-3 md:py-5 print:!text-xs print:!px-3">
      <div className="md:flex items-center">
        <div className="md:w-6/12 lg:w-7/12 xl:w-8/12 sm:pr-5">
          <div className="font-bold mb-2">{strings().legend.title}</div>
          <div className="text-sm print:!text-xs xl:max-w-[40rem]">
            {isAllergomedica ? strings().legend.description : strings().products.FOOD_ID.legend.description}
          </div>
        </div>
        <div className="md:w-6/12 lg:w-5/12 xl:w-4/12 md:pl-5 md:border-l border-content-500 mt-4 md:mt-0 sm:text-[15px] print:!text-xs">
          <div className="flex items-center mb-1 lg:mb-3 md:pl-1">
            <Circle state="high" />
            {hasNumValue ? strings().legend.senseHigh : strings().legend.noNum.senseHigh}
          </div>
          <div className="flex items-center mb-1 lg:mb-3 md:pl-1 print:!text-xs">
            <Circle state="medium" />
            {hasNumValue ? strings().legend.senseMedium : strings().legend.noNum.senseMedium}
          </div>
          <div className="flex items-center mb-1 lg:mb-3 md:pl-1 print:!text-xs">
            <Circle state="low" />
            {hasNumValue ? strings().legend.senseLow : strings().legend.noNum.senseLow}
          </div>
          <div className="flex items-center md:pl-1 print:!text-xs">
            <Circle state="default" />
            {hasNumValue ? strings().legend.senseNone : strings().legend.noNum.senseNone}
          </div>
        </div>
      </div>
    </div>
  );
}

LegendBlock.defaultProps = {
  hasNumValue: true,
};

export default LegendBlock;
