import { capitalizeFirstLetter } from './text';

const monthFormatter = new Intl.DateTimeFormat('lt', { month: 'long' });

const dateFormatter = new Intl.DateTimeFormat('lt', { day: '2-digit', month: '2-digit', year: 'numeric' });

export const getLongMonthName = (month: number, locale = 'lt'): string => {
  return capitalizeFirstLetter(new Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(2000, month - 1, 1)));
};

export const getShortMonthName = (month: number, locale = 'lt', length = 3): string => {
  return getLongMonthName(month, locale).substring(0, length);
};

export const getDate = (timestamp: string): string => {
  return dateFormatter.format(new Date(timestamp));
};
