import React from 'react';
import { Disclosure } from '@headlessui/react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { ChevronRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';

function MessagingSidebar() {
  const isLargerThanMd = useBreakpoint(up('md')) || false;

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="text-md p-3 md:p-0 md:text-xl w-full font-bold text-left flex">
            <div className="mr-auto">{strings().chat.doctorsTitle}</div>
            <div className="md:hidden">
              <ChevronRightIcon className={classNames('w-4 h-4', { 'transform rotate-90': open })} />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel static={isLargerThanMd} className="p-4 md:p-0 md:pt-4">
            <ul className="space-y-6">
              {strings().doctors.map((person: any) => (
                <li key={person.name}>
                  <div className="flex items-center">
                    <img
                      src={IMAGES.DOCTORS[person.imageUrl as keyof typeof IMAGES.DOCTORS].IMAGE}
                      alt=""
                      className="w-16 h-16 rounded-full lg:w-20 lg:h-20 relative"
                    />

                    <div className="font-medium leading-6 pl-4 space-y-1">
                      <span className="font-bold">{person.name}</span>
                      <p className="text-md">
                        {person.role}
                        <br />
                        {person.subrole}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default MessagingSidebar;
