import { useContext } from 'react';

import { MessagesContext } from '../context/messages';

export const useMessages = () => {
  const context = useContext(MessagesContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useMessages was used outside of its Provider');
  }

  return context;
};
