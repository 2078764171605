import axios, { AxiosRequestConfig } from 'axios';

import TreatmentViewProps from '../../entities/treatment-view';
import { parameters } from '../../localization/strings';

export const treatmentView = async (token: string, hash: string): Promise<TreatmentViewProps> => {
  const url = `${parameters().api.baseUrl}/treatments/view/${hash}`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const calculateWeeklyQuestionnaireScore = async (patientHash: string, answerHash: string): Promise<string> => {
  const url = `${parameters().api.baseUrl}/treatments/calculate-score/${patientHash}/${answerHash}`;
  const config: AxiosRequestConfig = {
    params: {},
  };

  const result = await axios.post(url, config);

  return result.data;
};
