import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { requestPasswordReset } from '../../services/api/users';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../common/animated/loader';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import StyledError from '../common/form/styled-error';
import CommonButtonDefault from '../common/buttons/default';
import AlertPrimary from '../common/alerts/alert-primary';

type Inputs = {
  common: string;
  email: string;
};

function UsersForgotPasswordForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);
      await requestPasswordReset(data.email);

      setIsSuccess(true);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.response);

        setIsLoading(false);
        setError('common', { message: e.message });
      }
    }
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}

      {isSuccess && (
        <div className="mb-6 md:mb-10">
          <AlertPrimary type="success" text={strings().forgotPassword.successTitle} />
        </div>
      )}

      {errors.common && <StyledError>{errors.common.message}</StyledError>}

      {!isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2">
            <StyledLabel htmlFor="email">{strings().form.email}</StyledLabel>
            <StyledInput
              fullWidth
              className={` ${errors.email && `!border-red-500`} `}
              type="email"
              {...register('email', { required: true })}
            />
            {errors.email && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
          </div>

          <CommonButtonDefault
            primary
            className="mt-5 md:mt-7"
            type="submit"
            onClick={() => {
              clearErrors('common');
            }}
          >
            {strings().button.sendReminder}
          </CommonButtonDefault>
        </form>
      )}
    </>
  );
}

export default UsersForgotPasswordForm;
