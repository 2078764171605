import classNames from 'classnames';
import React from 'react';

import { strings } from '../../localization/strings';
import { currency } from '../../services/formatter/currency';
import { ShortAnswerProduct } from '../../entities/short-answer';
import { useSite } from '../../hooks/use-site';

interface ComponentProps {
  children: React.ReactNode;
  isRecommended: boolean;
  product: ShortAnswerProduct;
}

export default function QuestionnaireProductsListItem({ children, isRecommended, product }: ComponentProps) {
  const { locale } = useSite();

  return (
    <div
      className={classNames(
        'relative p-8 mb-10 border rounded-2xl shadow-sm flex flex-grow-0 flex-shrink-0 flex-col',
        isRecommended ? 'bg-white border-brand-orange order-first lg:order-none' : 'bg-gray-50 border-gray-200',
      )}
    >
      <div className="flex-1">
        <h3 className={classNames('text-2xl font-semibold', product.comingSoon ? 'text-gray-500' : 'text-gray-900')}>
          {strings().products[product.key].title}
          {product.review && product.review === 'written' && strings().written}{' '}
          {product.review && product.review === 'consultation' && strings().consultation}{' '}
          {product.isConsultation && strings().consultation}
        </h3>
        {isRecommended && (
          <p className="absolute top-0 py-1.5 px-4 bg-brand-orange rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
            {strings().product.recommended}
          </p>
        )}

        {product.comingSoon && (
          <p className="absolute top-0 py-1.5 px-4 bg-blue-900 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
            {strings().product.comingSoon}
          </p>
        )}
        <p className={classNames('mt-4 flex items-baseline', product.comingSoon ? 'text-blue-300' : 'text-blue-900')}>
          <span className="text-[32px] font-extrabold tracking-tight">{currency(product.price, locale)}</span>
          {product.priceOriginal && (
            <span className="text-[#979797] inline-block ml-2 line-through">
              {currency(product.priceOriginal, locale)}
            </span>
          )}
        </p>
        <p className="mt-6 text-gray-500 text-left">
          {strings().products[product.key].description.replace('{allergensCount}', product.allergensCount)}
        </p>
      </div>

      {children}
    </div>
  );
}
