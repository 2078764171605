import axios from 'axios';

import { TextPage } from '../../entities/text-page';

export const getTextContent = async (slug: string): Promise<TextPage> => {
  // const url = `${parameters().api.websiteUrl}/api/info/${slug}`;
  const url = `https://allergomedica.lt/api/info/${slug}`;

  const res = await axios.get(url);

  return res.data;
};
