import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { AuthToken } from '../../entities/auth-token';
import { parameters } from '../../localization/strings';
import { Customer } from '../../entities/customer';
import { RegistrationData } from '../../entities/registration-data';
import { StatusResponse } from '../../entities/status-response';
import Allergist from '../../entities/allergist';
import { ApiError } from '../../entities/api-error';

export const register = async (data: RegistrationData): Promise<AuthToken> => {
  const cookies = new Cookies();
  const url = `${parameters().api.baseUrl}/users/register`;

  const result = await axios.post(url, { ...data, cookies: cookies.getAll() });

  return {
    token: result.data.token,
    expiredAt: new Date(result.data.expiredAt),
  };
};

export const login = async (email: string, password: string, orderHash?: string): Promise<AuthToken> => {
  const url = `${parameters().api.baseUrl}/users/login`;

  try {
    const result = await axios.post(url, {
      email,
      password,
      orderHash,
    });

    return {
      token: result.data.token,
      expiredAt: new Date(result.data.expiredAt),
    };
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const requestPasswordReset = async (email: string): Promise<AuthToken> => {
  const url = `${parameters().api.baseUrl}/users/request-password-reset`;

  try {
    const result = await axios.post(url, {
      email,
    });

    return result.data.status;
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const validatePasswordReset = async (token: string): Promise<Customer> => {
  const url = `${parameters().api.baseUrl}/users/validate-password-token/${token}`;

  try {
    const result = await axios.get(url);

    return result.data;
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const completePasswordReset = async (token: string, password: string): Promise<StatusResponse> => {
  const url = `${parameters().api.baseUrl}/users/complete-password-reset`;

  try {
    const result = await axios.post(url, {
      token,
      password,
    });

    return result.data.status;
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const current = async (token: string): Promise<Customer> => {
  const url = `${parameters().api.baseUrl}/users/current`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const updateUser = async (token: string, address: string, city: string, zipCode: string): Promise<Customer> => {
  const url = `${parameters().api.baseUrl}/users/update`;

  try {
    const result = await axios.post(
      url,
      {
        address,
        city,
        zipCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};

export const getUserDoctors = async (token: string): Promise<Allergist[]> => {
  const url = `${parameters().api.baseUrl}/users/allergists-list`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
