import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps {
  title: string;
  activeItem: boolean;
}

const Checkmark = styled.input`
  ${tw`focus:ring-blue-900 h-4 w-4 md:h-6 md:w-6 text-content-800 border-content-500 rounded`}
`;

const Trigger = styled.div<{ active: boolean }>`
  flex-shrink: 0;
  ${tw`relative flex md:inline-flex items-center text-base rounded-lg md:rounded-2xl p-3 md:p-4 border font-normal mb-3 md:mb-4`}
  ${tw`print:bg-white print:!p-0 print:!mb-1 print:border-0`}
  ${(props) =>
    !props.active
      ? tw`bg-white text-content-700 border-content-500`
      : tw`bg-content-300 border-content-300 text-content-800`}
`;

function TriggerComponent({ activeItem, title }: ComponentProps) {
  return (
    <Trigger active={activeItem}>
      <Checkmark
        className="print:hidden"
        id="comments"
        aria-describedby="comments-description"
        name="comments"
        type="checkbox"
        checked={activeItem}
        readOnly
      />
      <div className="text-sm ml-2 md:ml-3 md:text-base print:!ml-0">{title}</div>
    </Trigger>
  );
}

export default TriggerComponent;
