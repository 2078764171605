import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps {
  sm?: boolean;
}

const LoaderSvg = styled.svg<ComponentProps>`
  ${tw`animate-spin h-10 w-10 text-preloader`}
  ${(props) => (props.sm ? tw`w-5 h-5` : tw`w-10 h-10`)}
`;

function CommonAnimatedSpinner({ sm }: ComponentProps) {
  return (
    <LoaderSvg sm={sm} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </LoaderSvg>
  );
}

CommonAnimatedSpinner.defaultProps = {
  sm: false,
};

export default CommonAnimatedSpinner;
