import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckCircleIcon } from '@heroicons/react/outline';

import { strings } from '../../localization/strings';
import * as couponsService from '../../services/api/coupons';
import CommonAnimatedSpinner from '../common/animated/spinner';
import { Coupon } from '../../entities/coupon';

interface ComponentProps {
  onChange?: (coupon?: Coupon) => void;
  productId?: number;
}

function CheckoutDiscountFormComponent({ onChange, productId }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const { register, setError, clearErrors, watch, formState } = useForm<{ code: string }>();

  const onValidateDiscountCode = async () => {
    const discountCode = watch('code');

    clearErrors('code');
    setIsValid(false);
    setIsLoading(true);

    try {
      const coupon = await couponsService.validate(discountCode, productId);

      if (onChange) {
        onChange(coupon);
      }

      setIsValid(true);
      setIsLoading(false);
    } catch (e) {
      if (onChange) {
        onChange();
      }
      setIsLoading(false);
      setError('code', { message: (e as Error).message });
    }
  };

  return (
    <div>
      <label htmlFor="discountCode" className="block text-sm font-medium text-gray-700">
        {strings().checkout.discountCode}
      </label>
      <div className="flex space-x-4 mt-1">
        <div className="relative w-full">
          <input
            type="text"
            {...register('code')}
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
          {isLoading && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <CommonAnimatedSpinner sm />
            </div>
          )}
          {!isLoading && isValid && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
            </div>
          )}
        </div>
        <button
          type="button"
          className="bg-gray-200 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
          onClick={() => onValidateDiscountCode()}
        >
          {strings().button.check}
        </button>
      </div>
      {formState.errors.code && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {formState.errors.code?.message}
        </p>
      )}
    </div>
  );
}

CheckoutDiscountFormComponent.defaultProps = {
  onChange: () => null,
  productId: null,
};

export default CheckoutDiscountFormComponent;
