import React, { useEffect, useState } from 'react';
import Select, { OnChangeValue } from 'react-select';

import { parameters, strings } from '../../../localization/strings';
import * as locationsApi from '../../../services/api/locations';
import { Location } from '../../../entities/location';
import QuestionnaireFooterButtons from '../footer-buttons';
import { useQuestionnaire } from '../../../hooks/use-questionnaire';

interface ComponentProps {
  onSelect?: (location: Location) => void;
  productId?: number;
}

function QuestionnaireQuestionClinicComponent({ onSelect, productId }: ComponentProps) {
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const { setExtraProductId } = useQuestionnaire();

  useEffect(() => {
    const fetchLocations = async () => {
      let res;

      if (
        (productId && productId === parameters().product.stepsImmunotherapyId) ||
        (productId && productId === parameters().product.foodIntolerance)
      ) {
        res = await locationsApi.getListByProduct(productId);
      } else {
        res = await locationsApi.getList();
      }

      setLocations(res.sort((a, b) => (a.title > b.title ? 1 : -1)));
    };

    fetchLocations();
  }, [setLocations, productId]);

  const onSelectLocation = (option: OnChangeValue<Location, false>) => {
    if (option?.upsellProductId) {
      setExtraProductId(option?.upsellProductId);
    }

    setCurrentLocation(option);
  };

  const onContinue = () => {
    if (onSelect && currentLocation) {
      onSelect(currentLocation);
    }
  };

  return (
    <div>
      <Select
        className="pb-4"
        options={locations}
        placeholder={strings().bloodRegistration.pickerPlaceholder}
        getOptionValue={(option: Location) => `${option.id}`}
        getOptionLabel={(option: Location) => `${option.title}, ${option.partnerName}`}
        onChange={onSelectLocation}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      {currentLocation && (
        <div className="mb-5">
          {strings().locations.workingHours}
          <div
            dangerouslySetInnerHTML={{
              __html: currentLocation.workingHours && currentLocation.workingHours.replace('\\n', '<br />'),
            }}
          />
        </div>
      )}

      <QuestionnaireFooterButtons disableContinue={!currentLocation} onContinue={() => onContinue()} />
    </div>
  );
}

QuestionnaireQuestionClinicComponent.defaultProps = {
  onSelect: null,
  productId: null,
};

export default QuestionnaireQuestionClinicComponent;
