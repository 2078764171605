import * as noun from './noun';

export const genitive = (input: string, isMasculine = true): string => {
  const ending = noun.ending(input);

  switch (ending) {
    // case '':
    //   return input;

    case 'a':
      return `${input.substring(0, input.length - 1)}os`;

    case 'ė':
    case 'tė':
      return `${input.substring(0, input.length - 1)}ės`;

    case 'as':
    case 'ias':
    case 'čias':
    case 'jas':
      return `${input.substring(0, input.length - 2)}o`;

    case 'us':
    case 'ius':
      return `${input.substring(0, input.length - 2)}aus`;

    case 'is':
      return `${input.substring(0, input.length - 2)}io`;

    case 'tis':
      if (isMasculine) {
        return `${input.substring(0, input.length - 3)}čio`;
      }

      return `${input.substring(0, input.length - 2)}ies`;

    case 'ys':
      return `${input.substring(0, input.length - 2)}io`;

    case 'jys':
      return `${input.substring(0, input.length - 3)}jo`;

    case 'sis':
      return `${input.substring(0, input.length - 3)}sies`;

    case 'dis':
    case 'dys':
      return `${input.substring(0, input.length - 2)}žio`;

    case 'duo':
      return `${input.substring(0, input.length - 3)}dens`;

    case 'muo':
      return `${input.substring(0, input.length - 3)}mens`;

    case 'šuo':
      return `${input.substring(0, input.length - 3)}šuns`;

    default:
      return input;
  }
};

// export const dative = (input: string, isMasculine: boolean = true): string => {
//
// 	$galune = Daiktavardis::galune($zodis);
//
// 	switch ($galune) {
//
// 		case '':
//
// 			return $zodis;
// 		break;
//
// 		case 'a'://ona
//
// 			return mb_substr($zodis, 0, -1).'ai';
// 		break;
//
// 		case 'ė'://Jonė
// 		case 'tė'://marytė
//
// 			return mb_substr($zodis, 0, -1).'ei';
// 		break;
//
// 		case 'as'://gandras
// 		case 'ias'://kelias
// 		case 'čias'://svečias
// 		case 'jas'://vejas
//
// 			return mb_substr($zodis, 0, -2).'ui';
// 		break;
//
// 		case 'us'://dangus
// 		case 'ius'://aktorius
//
// 			return mb_substr($zodis, 0, -2).'ui';
// 		break;
//
// 		case 'is'://svainis
//
// 			return mb_substr($zodis, 0, -2).'iui';
// 		break;
//
// 		case 'tis':
//
// 			if ($vyriskaGimine) {//tėtis
//
// 				return mb_substr($zodis, 0, -3).'čiui';
// 			} else {//valtis
//
// 			  	return mb_substr($zodis, 0, -3).'čiai';
// 			}
// 		break;
//
// 		case 'ys'://rainys
//
// 			return mb_substr($zodis, 0, -2).'iui';
// 		break;
//
// 		case 'sis'://debsis
//
// 			return mb_substr($zodis, 0, -3).'siui';
// 		break;
//
// 		case 'jys'://žvejys
//
// 			return mb_substr($zodis, 0, -3).'jui';
// 		break;
//
// 		case 'dis'://kandis
// 		case 'dys'://gaidys
//
// 			return mb_substr($zodis, 0, -3).'džiui';
// 		break;
//
// 		case 'duo'://vanduo
//
// 			return mb_substr($zodis, 0, -3).'deniui';
// 		break;
//
// 		case 'muo'://akmuo
//
// 			return mb_substr($zodis, 0, -3).'meniui';
// 		break;
//
// 		case 'šuo'://šuo
//
// 			return mb_substr($zodis, 0, -3).'šuniui';
// 		break;
// 	}
// };
// galininkas
export const accusative = (input: string, isMasculine = true): string => {
  const ending = noun.ending(input);

  switch (ending) {
    case 'a': // ona
      return `${input.substring(0, input.length - 1)}ą`;

    case 'ė': // Jonė
    case 'tė': // marytė
      return `${input.substring(0, input.length - 1)}ę`;

    case 'as': // gandras
    case 'ias': // kelias
    case 'čias': // svečias
    case 'jas': // vejas
      return `${input.substring(0, input.length - 2)}ą`;

    case 'us': // dangus
    case 'ius': // aktorius
      return `${input.substring(0, input.length - 2)}ų`;

    case 'is': // svainis
      return `${input.substring(0, input.length - 2)}į`;

    case 'tis':
      if (isMasculine) {
        return `${input.substring(0, input.length - 3)}tį`; // tėtis
      }

      return `${input.substring(0, input.length - 3)}tį`; // valtis

    case 'ys': // rainys
      return `${input.substring(0, input.length - 2)}į`;

    case 'sis': // debsis
      return `${input.substring(0, input.length - 3)}sį`;

    case 'jys': // žvejys
      return `${input.substring(0, input.length - 3)}jį`;

    case 'dis': // kandis
    case 'dys': // gaidys
      return `${input.substring(0, input.length - 3)}dį`;

    case 'duo': // vanduo
      return `${input.substring(0, input.length - 3)}denį`;

    case 'muo': // akmuo
      return `${input.substring(0, input.length - 3)}menį`;

    case 'šuo': // šuo
      return `${input.substring(0, input.length - 3)}šunį`;

    default:
      return input;
  }
};

export const instrumental = (input: string, isMasculine = true): string => {
  const ending = noun.ending(input);

  switch (ending) {
    case 'a': // ona
      return `${input.substring(0, input.length - 1)}a`;

    case 'ė': // Jonė
    case 'tė': // marytė
      return `${input.substring(0, input.length - 1)}e`;

    case 'as': // gandras
    case 'ias': // kelias
    case 'čias': // svečias
    case 'jas': // vejas
      return `${input.substring(0, input.length - 2)}u`;

    case 'us': // dangus
    case 'ius': // aktorius
      return `${input.substring(0, input.length - 2)}umi`;

    case 'is': // svainis
      return `${input.substring(0, input.length - 2)}iu`;

    case 'tis':
      if (isMasculine) {
        // tėtis
        return `${input.substring(0, input.length - 3)}čiu`;
      } // valtis

      return `${input.substring(0, input.length - 3)}timi`;

    case 'ys': // rainys
      return `${input.substring(0, input.length - 2)}iu`;

    case 'sis': // debsis
      return `${input.substring(0, input.length - 3)}siu`;

    case 'jys': // žvejys
      return `${input.substring(0, input.length - 3)}ju`;

    case 'dis': // kandis
    case 'dys': // gaidys
      return `${input.substring(0, input.length - 3)}džiu`;

    case 'duo': // vanduo
      return `${input.substring(0, input.length - 3)}deniu`;

    case 'muo': // akmuo
      return `${input.substring(0, input.length - 3)}meniu`;

    case 'šuo': // šuo
      return `${input.substring(0, input.length - 3)}šunimi`;
    default:
      return input;
  }
};

/*
    export const instrumental = (input: string, isMasculine: boolean = true): string => {

		$galune = Daiktavardis::galune($zodis);

		switch ($galune) {

			case '':

				return $zodis;
			break;

			case 'a'://ona

				return mb_substr($zodis, 0, -1).'a';
			break;

			case 'ė'://Jonė
			case 'tė'://marytė

				return mb_substr($zodis, 0, -1).'e';
			break;

			case 'as'://gandras
			case 'ias'://kelias
			case 'čias'://svečias
			case 'jas'://vejas

				return mb_substr($zodis, 0, -2).'u';
			break;

			case 'us'://dangus
			case 'ius'://aktorius

				return mb_substr($zodis, 0, -2).'umi';
			break;

			case 'is'://svainis

				return mb_substr($zodis, 0, -2).'iu';
			break;

			case 'tis':

				if ($vyriskaGimine) {//tėtis

					return mb_substr($zodis, 0, -3).'čiu';
				} else {//valtis

				  	return mb_substr($zodis, 0, -3).'timi';
				}
			break;

			case 'ys'://rainys

				return mb_substr($zodis, 0, -2).'iu';
			break;

			case 'sis'://debsis

				return mb_substr($zodis, 0, -3).'siu';
			break;

			case 'jys'://žvejys

				return mb_substr($zodis, 0, -3).'ju';
			break;

			case 'dis'://kandis
			case 'dys'://gaidys

				return mb_substr($zodis, 0, -3).'džiu';
			break;

			case 'duo'://vanduo

				return mb_substr($zodis, 0, -3).'deniu';
			break;

			case 'muo'://akmuo

				return mb_substr($zodis, 0, -3).'meniu';
			break;

			case 'šuo'://šuo

				return mb_substr($zodis, 0, -3).'šunimi';
			break;
		}
	}

    export const locative = (input: string, isMasculine: boolean = true): string => {

		$galune = Daiktavardis::galune($zodis);

		switch ($galune) {

			case '':

				return $zodis;
			break;

			case 'a'://ona

				return mb_substr($zodis, 0, -1).'oje';
			break;

			case 'ė'://Jonė
			case 'tė'://marytė

				return mb_substr($zodis, 0, -1).'ėje';
			break;

			case 'as'://gandras

				return mb_substr($zodis, 0, -2).'e';
			break;

			case 'ias'://kelias

				return mb_substr($zodis, 0, -3).'yje';
			break;

			case 'čias'://svečias

				return mb_substr($zodis, 0, -4).'tyje';
			break;

			case 'jas'://vėjas

				return mb_substr($zodis, 0, -3).'juje';
			break;

			case 'us'://dangus
			case 'ius'://aktorius

				return mb_substr($zodis, 0, -2).'uje';
			break;

			case 'is'://svainis

				return mb_substr($zodis, 0, -2).'yje';
			break;

			case 'tis':

				if ($vyriskaGimine) {//tėtis

					return mb_substr($zodis, 0, -3).'tyje';
				} else {//valtis

				  	return mb_substr($zodis, 0, -3).'tyje';
				}
			break;

			case 'ys'://rainys

				return mb_substr($zodis, 0, -2).'yje';
			break;

			case 'sis'://debsis

				return mb_substr($zodis, 0, -3).'syje';
			break;

			case 'jys'://žvejys

				return mb_substr($zodis, 0, -3).'jyje';
			break;

			case 'dis'://kandis
			case 'dys'://gaidys

				return mb_substr($zodis, 0, -3).'dyje';
			break;

			case 'duo'://vanduo

				return mb_substr($zodis, 0, -3).'denyje';
			break;

			case 'muo'://akmuo

				return mb_substr($zodis, 0, -3).'menyje';
			break;

			case 'šuo'://šuo

				return mb_substr($zodis, 0, -3).'šunyje';
			break;
		}
	}

    export const vocative = (input: string, isMasculine: boolean = true): string => {

		$galune = Daiktavardis::galune($zodis);
		switch ($galune) {

			case '':

				return $zodis;
			break;

			case 'a'://ona

				return mb_substr($zodis, 0, -1).'a';
			break;

			case 'ė'://Jonė
			case 'tė'://marytė

				return mb_substr($zodis, 0, -1).'e';
			break;

			case 'as'://gandras

				return mb_substr($zodis, 0, -2).'ai';
			break;

			case 'ias'://kelias

				return mb_substr($zodis, 0, -3).'i';
			break;

			case 'čias'://svečias

				return mb_substr($zodis, 0, -4).'ty';
			break;

			case 'jas'://vėjas

				return mb_substr($zodis, 0, -3).'jau';
			break;

			case 'us'://dangus
			case 'ius'://aktorius

				return mb_substr($zodis, 0, -2).'au';
			break;

			case 'is'://svainis

				return mb_substr($zodis, 0, -2).'i';
			break;

			case 'tis':

				if ($vyriskaGimine) {//tėtis

					return mb_substr($zodis, 0, -3).'ti';
				} else {//valtis

				  	return mb_substr($zodis, 0, -3).'tie';
				}
			break;

			case 'ys'://rainys

				return mb_substr($zodis, 0, -2).'y';
			break;

			case 'sis'://debesis

				return mb_substr($zodis, 0, -3).'sie';
			break;

			case 'jys'://žvejys

				return mb_substr($zodis, 0, -3).'jau';
			break;

			case 'dis'://kandis

				return mb_substr($zodis, 0, -3).'di';
			break;

			case 'dys'://gaidys

				return mb_substr($zodis, 0, -3).'dy';
			break;

			case 'duo'://vanduo

				return mb_substr($zodis, 0, -3).'duo';
			break;

			case 'muo'://akmuo

				return mb_substr($zodis, 0, -3).'muo';
			break;

			case 'šuo'://šuo

				return mb_substr($zodis, 0, -3).'šunie';
			break;
		}
	}
*/
