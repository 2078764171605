import React from 'react';

import { strings } from '../../localization/strings';
import DrugProps from '../../entities/drug-prop';
import { SimpleObject } from '../../entities/simple-object';
import { pluralize, pluralizeWithNumber } from '../../services/grammar/pluralize';

// const ProgressBar = styled.div<{ width: number }>`
//   ${tw`absolute bg-blue-500 left-0 top-0 h-full z-10`}
//   width:  ${(props) => props.width}%;
// `;

interface ComponentProps {
  currentTreatmentDay: number;
  allergens: SimpleObject[];
  symptoms: SimpleObject[];
  drugs?: DrugProps[];
}

function TreatmentPlanComponent({ currentTreatmentDay, allergens, symptoms, drugs }: ComponentProps) {
  return (
    <div className="pt-4 md:pt-12">
      <h2 className="md:p-0 text-xl font-bold text-left mb-5">{strings().immunotherapy.treatmentPlan.title}</h2>
      <div>
        <div>
          <div className="text-lg mb-3 sm:flex sm:justify-between">
            <div>{strings().immunotherapy.treatmentPlan.titleDuration}</div>{' '}
            <div className="text-right text-blue-500">
              {currentTreatmentDay < 90 ? (
                <b>{pluralizeWithNumber(90 - currentTreatmentDay, strings().immunotherapy.treatmentDayPlural)}</b>
              ) : (
                <b>{pluralizeWithNumber(0, strings().immunotherapy.treatmentDayPlural)}</b>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-y-4 mt-7 text-sm">
          <div>
            {pluralize(allergens.length === 1 ? 1 : 2, strings().immunotherapy.treatmentPlan.subtitleAllergenPlural)}
          </div>
          <div className="text-right">
            {allergens.length > 0 && allergens.map((item) => <div key={`allergen-${item.sourceId}`}>{item.title}</div>)}
          </div>
          <div>{strings().immunotherapy.treatmentPlan.subtitleSymptoms}</div>
          <div className="text-right">
            {symptoms.map((symptom) => (
              <div key={`symptom-${symptom.symptomId}`}>{symptom.title}</div>
            ))}
          </div>
          {drugs && drugs?.length > 0 && (
            <>
              <div>{strings().immunotherapy.treatmentPlan.subtitleDrugsForSymptoms}</div>
              <div className="text-right">
                {drugs.map((drug, i) => (
                  <div key={`drug-${drug.drugId}-${i + 1}`}>{drug.title}</div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

TreatmentPlanComponent.defaultProps = { drugs: [] };

export default TreatmentPlanComponent;
