import tw, { styled } from 'twin.macro';

const SourceGroupHeading = styled.div<{ strength: number }>`
  ${tw`flex flex-1 flex-wrap px-5 md:px-8 py-4 md:py-6 lg:py-10 items-center bg-content-300`}
  ${tw`print:!p-1 print:!bg-white print:!text-black`}
    ${(props) => props.strength >= 3 && tw`bg-senseHigh-50 border-senseHigh-50 text-senseHigh-500`}
    ${(props) => props.strength === 2 && tw`bg-senseMedium-50 border-senseMedium-50 text-senseMedium-500`}
    ${(props) => props.strength === 1 && tw`bg-senseLow-50 border-senseLow-50 text-senseLow-500`}
`;

export default SourceGroupHeading;
