import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React, { useState } from 'react';
import Sticky from 'react-stickynode';

import { Tab } from '../../entities/tab';
import { strings } from '../../localization/strings';
import CommonButtonDefault from '../common/buttons/default';
import * as ordersApi from '../../services/api/orders';
import AlertPrimary from '../common/alerts/alert-primary';
import { useAuth } from '../../hooks/use-auth';

interface ComponentProps {
  tabs: Tab[];
  pdfId?: string | null;
}

function OrderTabs({ tabs, pdfId }: ComponentProps) {
  const [error, setError] = useState<string>('');
  const { token } = useAuth();
  const [disabled, setDisabled] = useState<boolean>(false);
  const downloadPdf = async () => {
    if (pdfId) {
      try {
        setDisabled(true);
        const res = await ordersApi.getPdf(token, pdfId);

        if (res) {
          setDisabled(false);
          window.location.href = res;
        }
      } catch (e) {
        setError((e as Error).message);
      }
    }
  };

  return (
    <>
      <Sticky innerZ={5} className="print:!hidden">
        <div className="print:border-0 border-b bg-white border-gray-200 px-2 md:px-0">
          <div className="container max-w-4xl mx-auto">
            <div className="flex justify-between">
              <nav className="-mb-px flex space-x-2 md:space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={classNames(
                      tab.current
                        ? 'border-blue-800 text-blue-800'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm no-underline',
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </Link>
                ))}
              </nav>
              {pdfId && (
                <div className="flex items-center">
                  <CommonButtonDefault disabled={disabled} sm primary onClick={downloadPdf}>
                    {strings().button.pdf}
                  </CommonButtonDefault>
                </div>
              )}
            </div>
          </div>
        </div>
      </Sticky>
      {error && (
        <div className="container max-w-4xl mx-auto mt-3">
          <AlertPrimary type="danger" title={error} />
        </div>
      )}
    </>
  );
}

OrderTabs.defaultProps = {
  pdfId: null,
};

export default OrderTabs;
