import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import TextClinicTerms from '../text/clinic-terms-conditions';

export default function ModalClinicTermsConditions() {
  return (
    <ModalText type={ModalKeys.ClinicTerms} title={strings().textPage.termsAndConditions.title}>
      <TextClinicTerms />
    </ModalText>
  );
}
