import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import TextChildTreatmentProtocol from '../text/treatment-protocol-child';

export default function ModalTreatmentProtocolChild() {
  return (
    <ModalText type={ModalKeys.TreatmentProtocolChild} title={strings().treatmentProtocol.title}>
      <TextChildTreatmentProtocol />
    </ModalText>
  );
}
