import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { strings } from '../../localization/strings';
import ContainerMd from '../../components/common/containers/containerMd';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { calculateWeeklyQuestionnaireScore } from '../../services/api/treatment';

const cookies = new Cookies();

export default function TreatmentWeeklySuccessPageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const { patientHash, answerHash } = useParams();

  // const answerHash = cookies.get(COOKIE_NAME_ANSWER);

  useEffect(() => {
    if (patientHash && answerHash) {
      const onCalculateAnswer = async () => {
        setIsLoading(true);
        try {
          const res = await calculateWeeklyQuestionnaireScore(patientHash, answerHash);

          if (res) {
            cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });
          }

          setIsLoading(false);
        } catch (e: any) {
          setIsLoading(false);
          setError(e.message);
        }
      };

      onCalculateAnswer();
    }
  }, []);

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <ContainerMd>
        <div className="md:w-9/12">
          <div className="mb-6 md:mb-10">
            {error ? (
              <AlertPrimary type="failed" title={error} />
            ) : (
              <>
                <AlertPrimary
                  type="success"
                  title={strings().consultationSuccess.success_title}
                  text={strings().immunotherapy.successText}
                />
                <div className="mt-10">
                  <NavLink
                    key="profile"
                    to="/"
                    className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
                  >
                    {strings().checkout.previewProfile}
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </ContainerMd>
    </div>
  );
}
