import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useParams } from 'react-router-dom';

import { strings } from '../../../localization/strings';
import ContainerMd from '../../../components/common/containers/containerMd';
import { useLayout } from '../../../hooks/use-layout';
import Heading2 from '../../../components/common/headings/heading2';
import IMAGES from '../../../assets/images';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { ModalKeys } from '../../../context/modals';
import { useModal } from '../../../hooks/use-modal';
import { instrumental } from '../../../services/grammar/lithuanian/singular';
import { multiWord } from '../../../services/grammar/lithuanian';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { getPatientDoctor } from '../../../services/api/patients';
import { Patient } from '../../../entities/patient';
import FileUploader from '../../../components/common/file-uploader';
import SuccessUploadFileComponent from '../../../components/anamnesis/successUploadFile';

const Image = styled.img`
  width: 250px;
  height: auto;
  ${tw`mx-auto mb-4`};
`;

export default function AnamnesisUploadPageComponent() {
  const { setMenuLogoState } = useLayout();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [patient, setPatient] = useState<Patient>();
  const [success, setSuccess] = useState<boolean>(false);

  const { patientHash } = useParams();

  const { setVisible } = useModal();

  useEffect(() => {
    setMenuLogoState(false);
  }, [setMenuLogoState]);

  useEffect(() => {
    if (patientHash) {
      const getPatient = async () => {
        try {
          setIsLoading(true);
          const res = await getPatientDoctor(patientHash);

          if (res) {
            setPatient(res);
          }
          setIsLoading(false);
        } catch (e: any) {
          setIsLoading(false);
          setError(e.message);
        }
      };

      getPatient();
    }
  }, []);

  const onOpenModal = (key: string) => {
    setVisible(key, true);
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && (
        <div>
          <ContainerMd>
            {success ? (
              <SuccessUploadFileComponent />
            ) : (
              <div className="md:w-8/12 mx-auto">
                <div className="mb-6 md:mb-10">
                  <Image src={IMAGES.ANAMNESIS.SEND} alt="" />
                  <Heading2 className="mb-6 text-center">{strings().anamnesis.questionnaire.upload.title}</Heading2>
                  <div className="text-center">{strings().anamnesis.questionnaire.upload.description}</div>
                  <div className="flex justify-center">
                    <ul className="list-disc pl-5 mt-3">
                      {strings().anamnesis.questionnaire.upload.list.map((listItem: any, index: number) => (
                        <li key={`item-${index + 1}`}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                  {error && (
                    <div className="mb-5 text-left">
                      <AlertPrimary type="danger" title={strings().anamnesis.questionnaire.error} text={error} />
                    </div>
                  )}

                  {patientHash && patient ? (
                    <>
                      <div className="mt-7 mb-5">
                        <div className="mx-auto">
                          <FileUploader onSuccess={setSuccess} patientHash={patientHash} />
                        </div>
                      </div>
                      <div className="text-center">
                        {strings().anamnesis.questionnaire.submit.privacypolicy1}{' '}
                        {patient && multiWord(`${patient.doctorFirstName} ${patient.doctorLastName}`, instrumental)}{' '}
                        {strings().anamnesis.questionnaire.submit.privacypolicy2}{' '}
                        <CommonButtonDefault link onClick={() => onOpenModal(ModalKeys.ClinicPrivacyPolicy)}>
                          {strings().anamnesis.questionnaire.submit.privacypolicyLinkTex}
                        </CommonButtonDefault>{' '}
                        {strings().anamnesis.questionnaire.submit.termsAndConditions1}{' '}
                        <CommonButtonDefault link onClick={() => onOpenModal(ModalKeys.ClinicTerms)}>
                          {strings().anamnesis.questionnaire.submit.termsAndConditionsLinkText}
                        </CommonButtonDefault>
                      </div>
                    </>
                  ) : (
                    <div className="mt-7 mb-5">
                      <AlertPrimary
                        type="danger"
                        title={strings().anamnesis.questionnaire.upload.noPatientTitle}
                        text={strings().anamnesis.questionnaire.upload.noPatientText}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </ContainerMd>
        </div>
      )}
    </>
  );
}
