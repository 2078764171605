import React, { useEffect, useState } from 'react';

import { getTextContent } from '../../services/api/texts';
import CommonAnimatedSpinner from '../common/animated/spinner';

interface ComponentProps {
  url: string;
}

export default function ExternalText({ url }: ComponentProps) {
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadText = async () => {
      // const content = await getTextContent('privatumo-politika');
      const content = await getTextContent(url);

      setText(content.content);
      setIsLoading(false);
    };

    loadText();
  }, [setText]);

  return (
    <div>
      {isLoading && (
        <div className="mt-4">
          <CommonAnimatedSpinner />
        </div>
      )}
      {text && (
        <div
          className="mt-8 text-lg text-gray-500 leading-8 max-w-prose text-page px-4"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
}
