import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { Question } from '../../../entities/question';
import QuestionnaireFooterButtons from '../footer-buttons';
import { strings } from '../../../localization/strings';
import StyledError from '../../common/form/styled-error';

const QuestionButton = styled.button<{ current: number; value: number }>`
  ${tw`w-full block py-3 leading-8 pl-5 pr-5 text-gray-300 text-lg focus:outline-none border`}
  ${(props) => props.current < props.value && tw`bg-white border-content-500`}
  ${(props) => props.current === 1 && props.current >= props.value && tw`bg-success-800 border-success-800 text-white`}
 
  ${(props) => props.current === 2 && props.current >= props.value && tw`bg-yellow-400 text-white`}
  ${(props) => props.current === 3 && props.current >= props.value && tw`bg-amber-500 text-white`}
  ${(props) => props.current === 4 && props.current >= props.value && tw`bg-orange-500 text-white`}
  ${(props) => props.current === 5 && props.current >= props.value && tw`bg-red-500 text-white`}
  ${(props) => props.value === 5 && tw`rounded-r-lg`}
  ${(props) => props.value === 1 && tw`rounded-l-lg`}
`;

const QuestionButtonContainer = styled.div<{ value: number }>`
  ${tw`relative -ml-px`}
  padding-top: 72px;
  ${(props) => props.value === 1 && tw`ml-0`}
`;

const ButtonTooltip = styled.div<{ value: number }>`
  width: 94px;
  left: 50%;
  margin-left: -47px;
  ${tw`absolute hidden top-0 h-16 text-sm leading-4 text-center rounded-lg items-center justify-center p-1 focus:outline-none`}
  ${(props) => props.value === 1 && tw`bg-success-300 text-success-800 `}
  ${(props) => props.value === 2 && tw`bg-yellow-100 text-yellow-500`}
  ${(props) => props.value === 3 && tw`bg-amber-100 text-amber-600`}
  ${(props) => props.value === 4 && tw`bg-orange-100 text-orange-500`}
  ${(props) => props.value === 5 && tw`bg-red-100 text-red-600`}}
`;

const ThreeColumns = styled.div`
  ${tw`flex sm:gap-7 items-end sm:mb-7`}
`;

const TwoColumns = styled.div`
  ${tw`grid grid-cols-2 sm:hidden pt-3 mb-7`}
`;

const ButtonsContainer = styled.div`
  ${tw`grid grid-cols-5 sm:w-72`}
`;

interface ComponentProps {
  question: Question;
  onSelect?: (value: number) => void;
}

const generateRange = (min: number, max: number): number[] => {
  const res = [];

  let start = min;

  while (start <= max) {
    res.push(start);
    start += 1;
  }

  return res;
};

function QuestionnaireQuestionScaleComponent({ question, onSelect }: ComponentProps) {
  const [current, setCurrent] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const onSelectValue = (value: number) => {
    setCurrent(value);
    setIsError(false);
  };

  const onContinue = () => {
    if (!current) {
      setIsError(true);
    } else if (onSelect) {
      setIsError(false);
      onSelect(current);
    }
  };

  const options = generateRange(question.scaleMinValue, question.scaleMaxValue);

  return (
    <>
      <ThreeColumns>
        <div className="w-20 hidden sm:block">{question.scaleTitleLeft}</div>
        <ButtonsContainer className="w-full sm:w-7/12 md:w-8/12">
          {options.map((option) => (
            <QuestionButtonContainer value={option} className="group" key={`${question.id}-${option}`}>
              <ButtonTooltip value={option} className="group-hover:flex">
                {strings().short_test.scale_text[option - 1]}
              </ButtonTooltip>
              <QuestionButton current={current} value={option} onClick={() => onSelectValue(option)}>
                {option}
              </QuestionButton>
            </QuestionButtonContainer>
          ))}
        </ButtonsContainer>
        <div className="sm:w-3/12  lg:w-3/12  xl:w-2/12 hidden sm:block">{question.scaleTitleRight}</div>
      </ThreeColumns>
      <TwoColumns>
        <div className="text-sm leading-4">
          <div className="w-1/2">{question.scaleTitleLeft}</div>
        </div>
        <div className="text-right leading-4 text-sm">{question.scaleTitleRight}</div>
      </TwoColumns>

      {isError && <StyledError>{strings().error.youMustSelect}</StyledError>}

      <QuestionnaireFooterButtons onContinue={() => onContinue()} />
    </>
  );
}

QuestionnaireQuestionScaleComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionScaleComponent;
