import { QuestionOption } from './question-option';

export const QuestionType = {
  Single: 'single',
  TextShort: 'text-short',
  TextLong: 'text-long',
  Number: 'number',
  Description: 'description',
  Multiple: 'multiple',
  Scale: 'scale',
  Rating: 'rating',
  Clinic: 'clinic',
  Calendar: 'calendar',
  InfoScreen: 'info-screen',
  ParcelTerminal: 'parcel-terminal',
  Doctor: 'doctor',
};

export interface Question {
  id: number;
  surveyId: number;
  triggerQuestionOptionId: number;
  step: number;
  position: number;
  key: string;
  type: keyof typeof QuestionType;
  heading?: string;
  description?: string;
  fieldName?: string;
  width?: number;
  title?: string;
  scaleMinValue: number;
  scaleMaxValue: number;
  scaleTitleLeft: string;
  scaleTitleRight: string;
  wrap: boolean;
  triggerQuestionOptionIds: string[];

  answerOptionId?: number;
  answerText?: string;
  answerSkipped?: boolean;
  answerMultiOptionsIds?: number[];
  answerReservationTime?: string;
  answerAllergistId?: number;
  answerReservationDate?: string;

  visible: boolean;

  options: QuestionOption[];
}
