import axios from 'axios';

import { parameters } from '../../localization/strings';
import { FormCheckout } from '../../entities/forms/checkout';

export const initiateAgreementSign = async (
  data: Partial<FormCheckout>,
  answerHash?: string,
  token?: string,
  patientId?: string,
): Promise<{ orderHash: string; fileToken: string }> => {
  const url = `${parameters().api.baseUrl}/agreements/initiate-sign`;

  const result = await axios.post(
    url,
    {
      ...data,
      answerHash,
      patientId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};

export const checkUploadStatus = async (fileToken: string, orderHash: string): Promise<{ status: string }> => {
  const url = `${parameters().api.baseUrl}/agreements/upload-status/${fileToken}/${orderHash}`;

  const result = await axios.get(url);

  return result.data;
};

export const getSignLink = async (
  orderHash: string,
  fileToken: string,
): Promise<{ sign_url: string; sign_token: string }> => {
  const url = `${parameters().api.baseUrl}/agreements/signing-url/${orderHash}/${fileToken}`;

  const result = await axios.get(url);

  return result.data;
};

export const checkDocumentStatus = async (signToken: string): Promise<{ status: string }> => {
  const url = `${parameters().api.baseUrl}/agreements/sign-status/${signToken}`;

  const result = await axios.get(url);

  return result.data;
};
