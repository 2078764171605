import React from 'react';
import tw, { styled } from 'twin.macro';

import { strings } from '../../localization/strings';
import { Coupon } from '../../entities/coupon';
import { currency } from '../../services/formatter/currency';
import { useSite } from '../../hooks/use-site';

const Discount = styled.span`
  ${tw`rounded-full bg-blue-600 border border-white text-xs text-gray-600 py-1.5 px-3`}
`;

interface ComponentProps {
  subtotal: number;
  discount?: Coupon;
  total: number;
  showTotal?: boolean;
}

function CheckoutTotalsComponent({ subtotal, discount, total, showTotal }: ComponentProps) {
  const { locale } = useSite();

  return (
    <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
      <div className="flex justify-between">
        <dt>{strings().checkout.subTotal}</dt>
        <dd className="text-gray-900">{currency(subtotal, locale)}</dd>
      </div>
      {!!discount && (
        <div className="flex justify-between">
          <dt className="flex items-center">
            {strings().checkout.discount}
            {discount.amountPercentage && <span>&nbsp;({discount.amountPercentage}%)</span>}
            <Discount className="ml-2">{discount.code}</Discount>
          </dt>
          <dd className="text-gray-900">
            -{currency(discount.amountExact ? discount.amountExact : subtotal - total, locale)}
          </dd>
        </div>
      )}
      {/*
        <div className="flex justify-between">
          <dt>Taxes</dt>
          <dd className="text-gray-900">{taxes}</dd>
        </div>
        */}
      {showTotal && (
        <div className="flex items-center justify-between border-t font-bold border-gray-200 text-gray-900 pt-6">
          <dt>{strings().checkout.amount}</dt>
          <dd className="text-base">{currency(total, locale)}</dd>
        </div>
      )}
    </dl>
  );
}

CheckoutTotalsComponent.defaultProps = {
  discount: null,
  showTotal: true,
};

export default CheckoutTotalsComponent;
