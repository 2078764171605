import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader';

import { uploadFile } from '../../services/api/anamnesis';
import { strings } from '../../localization/strings';
import AlertPrimary from './alerts/alert-primary';
import { SimpleObject } from '../../entities/simple-object';

function Layout({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) {
  return (
    <div>
      {previews}

      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

      {files.length > 0 && submitButton}
    </div>
  );
}

interface ComponentProps {
  patientHash: string;
  onSuccess: (isSuccess: boolean) => void;
}

function FileUploader({ patientHash, onSuccess }: ComponentProps) {
  const [errors, setErrors] = useState<SimpleObject>();
  const fileUpload = async (file: File): Promise<{ status: boolean; message: string }> => {
    try {
      const res = await uploadFile(file.name, patientHash);

      if (res) {
        await fetch(res.uploadUrl, {
          method: 'PUT',
          mode: 'cors',
          body: file,
        });
      }

      return {
        status: true,
        message: '',
      };
    } catch (e: any) {
      return {
        status: false,
        message: e.message,
      };
    }
  };
  const handleSubmit: IDropzoneProps['onSubmit'] = async (files, allFiles) => {
    if (files) {
      const results = await Promise.all(
        files.map(async (f) => {
          return fileUpload(f.file);
        }),
      );

      const hasErrors = results.reduce((e, file) => e || !file.status, false);
      const filesWithError = results.filter((r) => !r.status);

      if (filesWithError) {
        setErrors(filesWithError);
      }

      if (!hasErrors) {
        allFiles.forEach((f) => {
          f.remove();
        });
        onSuccess(true);
      }
    }
  };

  return (
    <>
      {errors &&
        errors.map((error: SimpleObject, index: number) => (
          <div key={`alert-${index + 1}`} className="mb-5 text-left">
            <AlertPrimary type="danger" title={error.message} />
          </div>
        ))}

      <Dropzone
        onSubmit={handleSubmit}
        canCancel={false}
        inputContent={strings().button.upload}
        inputWithFilesContent={strings().button.upload}
        submitButtonContent={strings().button.send}
        LayoutComponent={Layout}
        accept=".jpg, .jpeg, .png, .pdf"
        styles={{
          dropzone: {
            minHeight: 50,
            maxHeight: 50,
            border: 'none',
          },
          inputLabel: {
            color: 'white',
            fontSize: 16,
            borderRadius: 10,
            background: '#0375FF',
            width: 200,
            display: 'inline-flex',
          },
          preview: {
            paddingTop: 20,
            paddingBottom: 20,
            marginBottom: 20,
          },
          inputLabelWithFiles: {
            fontSize: 16,
            height: 50,
            minHeight: 50,
            width: 200,
            borderRadius: 10,
            display: 'inline-flex',
          },
          submitButtonContainer: {
            textAlign: 'center',
          },
          submitButton: {
            borderRadius: 10,
            paddingTop: 13,
            paddingBottom: 13,
            fontSize: 16,
            width: 200,
          },
        }}
      />
    </>
  );
}

export default FileUploader;
