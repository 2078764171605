import React from 'react';
import { useParams } from 'react-router-dom';

import { QuestionnaireContextProvider } from '../../context/questionnaire';
import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';

export default function QuestionnaireFeedbackPageComponent() {
  const { feedbackHash, feedbackValue } = useParams();

  const intProductId = 0;

  return (
    <QuestionnaireContextProvider
      feedbackCustomerHash={feedbackHash}
      feedbackValue={feedbackValue}
      type={QuestionnaireType.Feedback}
      productId={intProductId}
    >
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
