import React from 'react';

interface ComponentProps {
  title?: string;
  text: string;
  size?: string;
}

function GreyBlock({ text, title, size }: ComponentProps) {
  return (
    <div
      className={`bg-content-300 rounded-lg md:rounded-2xl print:!p-0 print:bg-white ${
        size === 'md' ? 'md:px-6 md:py-7' : 'p-3 md:px-5 md:py-3.5'
      }`}
    >
      {title && <div className="font-bold mb-2">{title}</div>}
      {/* eslint-disable react/no-danger */}
      <div className="text-sm" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

GreyBlock.defaultProps = {
  title: '',
  size: '',
};

export default GreyBlock;
