import React from 'react';

import { ModalKeys } from '../../context/modals';
import { strings } from '../../localization/strings';
import ModalText from './text-modal';
import TextRemoteConsultation from '../text/remote-consultation';

export default function ModalRemoteConsultation() {
  return (
    <ModalText type={ModalKeys.RemoteConsultation} title={strings().textPage.remoteConsultation.title}>
      <TextRemoteConsultation />
    </ModalText>
  );
}
