import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';
import { SubmitHandler, useForm } from 'react-hook-form';

import * as questionnaireApi from '../../services/api/questionnaire';
import CommonButtonDefault from '../../components/common/buttons/default';
import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import StyledError from '../../components/common/form/styled-error';
import { FormSubscribe } from '../../entities/forms/subscribe';
import Heading1 from '../../components/common/headings/heading1';

const ContainerLg = styled.div`
  ${tw`mx-auto container px-4`}
  ${up('lg')} {
    max-width: 842px;
  }
`;

const PurchaseBlockContainer = styled.div`
  position: relative;
  font-size: 16px;
  margin-top: 2rem;
`;

const Hero = styled.div`
  text-align: center;
  padding-top: 5rem;
  position: relative;
  background: url(${IMAGES.BLOB.SECOND}) no-repeat top right;
  background-size: 240px auto;
  padding-bottom: 4rem;

  ${up('md')} {
    padding-top: 8rem;
    background-size: 400px auto;
  }
`;

export default function QuestionnaireSubscribeAnswerPageComponent() {
  const { hash } = useParams<{ hash: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [subscribeError, setSubscribeError] = useState<string>('');
  const [subscribeState, setSubscribeState] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<FormSubscribe>();

  const onSubscribeClick: SubmitHandler<FormSubscribe> = async (data) => {
    try {
      setIsLoading(true);
      if (hash && data.email) {
        await questionnaireApi.answerSubscription(hash, data.email);

        navigate(`/questionnaire/answer/${hash}`);
      } else {
        setIsLoading(false);
        setSubscribeError(strings().form.alertDangerAnswer.title);
      }
    } catch (e) {
      setSubscribeError((e as Error).message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && (
        <PurchaseBlockContainer>
          <ContainerLg>
            {subscribeState && (
              <AlertPrimary
                title={strings().form.alertSuccessAnswer.title}
                text={strings().form.alertSuccessAnswer.text}
                type="success"
              />
            )}

            <Hero>
              <Heading1 className="relative z-10 print:!text-lg">
                {strings().allergyEvaluation.subscribeAnswer.title}
              </Heading1>
              <div className="md:w-4/5 mx-auto leading-snug">
                <div className="mt-3 text-gray-500 text-base">
                  {strings().allergyEvaluation.subscribeAnswer.description}
                </div>
              </div>
              <div className="mt-5 lg:mt-7 text-left mb-4">
                {subscribeError && (
                  <div className="mb-5 text-left">
                    <AlertPrimary type="danger" title={strings().form.alertDangerAnswer.title} text={subscribeError} />
                  </div>
                )}
                <label htmlFor="email" className="text-content-700 font-medium">
                  {strings().form.email}
                </label>
              </div>

              <form onSubmit={handleSubmit(onSubscribeClick)}>
                <div className="md:flex md:space-x-4 items-center">
                  <input
                    {...register('email', { required: true })}
                    type="email"
                    id="email"
                    name="email"
                    className={`mb-5 md:mb-0 block w-full border border-gray-300 rounded-md shadow-sm py-2 h-[54px] px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                      errors.email && `!border-red-500`
                    } `}
                  />

                  <CommonButtonDefault className="w-full md:w-auto" primary type="submit">
                    {strings().allergyEvaluation.subscribeAnswer.buttonText}
                  </CommonButtonDefault>
                </div>
                {errors.email && <StyledError>{strings().error.email.required}</StyledError>}
              </form>
            </Hero>
          </ContainerLg>
        </PurchaseBlockContainer>
      )}
    </>
  );
}
