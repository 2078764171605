import React, { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSwiperSlide } from 'swiper/react';

import { Question } from '../../../entities/question';
import { strings } from '../../../localization/strings';
import QuestionnaireFooterButtons from '../footer-buttons';
import useFocus from '../../../hooks/use-focus';

const DefaultInput = styled.input`
  ${tw`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full mb-3 mt-1 border-content-500 rounded-md`}// max-width: 20rem;
`;

const Label = styled.label`
  ${tw`block text-sm font-medium text-content-700 font-bold pl-2`}
`;

export const QuestionInputType = {
  Text: 'text',
  Number: 'number',
};

interface ComponentProps {
  question: Question;
  type?: string;
  onContinue?: (text: string) => void;
  minValue?: string;
}

type Input = {
  text: string;
};

function QuestionnaireQuestionTextShortComponent({ question, type, onContinue, minValue }: ComponentProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Input>();

  const slide = useSwiperSlide();
  const [inputRef, setInputFocus] = useFocus();

  const onSubmit: SubmitHandler<Input> = async (data) => {
    if (onContinue) {
      onContinue(data.text);
    }
  };

  if (question.answerText) {
    setValue('text', question.answerText);
  }

  useEffect(() => {
    if (slide.isActive) {
      setTimeout(() => {
        setInputFocus();
      }, 500);
    }
  }, [slide, setInputFocus]);

  const { ref, ...questionInputProps } = register('text', { required: true });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {question.fieldName && <Label>{question.fieldName}</Label>}
      <DefaultInput
        className={`${errors.text ? 'border-rose-700' : ''}`}
        autoComplete="off"
        type={type}
        min={minValue}
        pattern={type === QuestionInputType.Number ? '[0-9]*' : '[A-Za-z0-9 -]+'}
        {...questionInputProps}
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
      />
      {errors.text && <span className="text-red-600">{strings().error.fieldIsRequired}</span>}
      <QuestionnaireFooterButtons onContinue={() => handleSubmit(onSubmit)} />
    </form>
  );
}

QuestionnaireQuestionTextShortComponent.defaultProps = {
  onContinue: () => null,
  type: QuestionInputType.Text,
  minValue: undefined,
};

export default QuestionnaireQuestionTextShortComponent;
