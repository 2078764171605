import React from 'react';
import { useParams } from 'react-router-dom';

import UsersRegisterFormComponent from '../../components/users/register-form';

function UsersRegisterPageComponent() {
  const { hash } = useParams();

  return (
    <div className="min-h-full md:flex items-center justify-center py-10 md:py-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl relative">
      <div className="md:w-3/5 z-10 relative border-b md:border-b-0 pb-10 md:pb-0">
        <UsersRegisterFormComponent orderHash={hash} />
      </div>
    </div>
  );
}

export default UsersRegisterPageComponent;
