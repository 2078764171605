import React, { useEffect } from 'react';

import ContainerMd from '../components/common/containers/containerMd';
import { strings, parameters } from '../localization/strings';
import Heading3 from '../components/common/headings/heading3';
import CommonAnimatedSpinner from '../components/common/animated/spinner';

function RedirectToResearchLanding() {
  const url = parameters().freeResearchUrl;

  useEffect(() => {
    document.location.href = url;
  }, []);

  return (
    <ContainerMd>
      <Heading3>{strings().redirecting}</Heading3>
      <div className="px-5 flex justify-center items-center">
        <CommonAnimatedSpinner />
      </div>
    </ContainerMd>
  );
}

export default RedirectToResearchLanding;
