import axios from 'axios';

import { parameters } from '../../localization/strings';
import { FullAnswer } from '../../entities/full-answer';
import { AllergensAnswer } from '../../entities/allergens-answer';
import { StatusResponse } from '../../entities/status-response';

export const getResult = async (token: string, hash: string, langUrl?: string): Promise<FullAnswer> => {
  let url = `${parameters().api.baseUrl}/orders/result/${hash}`;

  if (langUrl) {
    url += `?language=${langUrl}`;
  }

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getAllergens = async (token: string, hash: string, langUrl?: string): Promise<AllergensAnswer> => {
  let url = `${parameters().api.baseUrl}/orders/allergens/${hash}`;

  if (langUrl) {
    url += `?language=${langUrl}`;
  }

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const storeBloodLocation = async (
  token: string,
  orderHash: string,
  bloodLocationId: number,
): Promise<StatusResponse> => {
  const url = `${parameters().api.baseUrl}/orders/store-location`;

  const result = await axios.post(
    url,
    {
      orderHash,
      bloodLocationId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};

export const getPdf = async (token: string, pdfId: string): Promise<string> => {
  const url = `${parameters().api.baseUrl}/orders/download-pdf/${pdfId}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const finishSurvey = async (token: string, hash: string): Promise<string> => {
  const url = `${parameters().api.baseUrl}/orders/finish-survey/${hash}`;

  const result = await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};
