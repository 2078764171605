import axios from 'axios';
import Cookies from 'universal-cookie';

import { parameters } from '../../localization/strings';
import { Order } from '../../entities/order';
import { FormCheckout } from '../../entities/forms/checkout';
import { ShortAnswerProduct } from '../../entities/short-answer';

export const initiatePayment = async (
  data: Partial<FormCheckout>,
  answerHash?: string,
  token?: string,
  patientId?: string,
  treatmentAgreement?: boolean,
): Promise<{ url: string }> => {
  const cookies = new Cookies();
  const url = `${parameters().api.baseUrl}/payments/initiate`;

  const result = await axios.post(
    url,
    {
      ...data,
      answerHash,
      patientId,
      treatmentAgreement,
      cookies: cookies.getAll(),
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data;
};

export const repeatPayment = async (data: Partial<FormCheckout>, hash: string): Promise<{ url: string }> => {
  const cookies = new Cookies();
  const url = `${parameters().api.baseUrl}/payments/repeat`;

  const result = await axios.post(url, {
    ...data,
    hash,
    cookies: cookies.getAll(),
  });

  return result.data;
};

export const validatePayment = async (
  hash: string,
): Promise<{ order: Order; product: ShortAnswerProduct; isRegistered: boolean }> => {
  const url = `${parameters().api.baseUrl}/payments/validate/${hash}`;

  const result = await axios.get(url);

  return result.data;
};
