import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import * as questionnaireApi from '../../services/api/questionnaire';
import CheckoutFormComponent from '../../components/checkout/form';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { initiatePayment } from '../../services/api/payments';
import {
  initiateAgreementSign,
  checkUploadStatus,
  getSignLink,
  checkDocumentStatus,
} from '../../services/api/agreements';
import { FormCheckout } from '../../entities/forms/checkout';
import { useLayout } from '../../hooks/use-layout';
import { ShortAnswerProduct } from '../../entities/short-answer';
import { useAuth } from '../../hooks/use-auth';
import { useModal } from '../../hooks/use-modal';
import ModalSignAgreement from '../../components/modal/sign-agreement';
import { OrderStatus } from '../../entities/order';
import { parameters } from '../../localization/strings';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';

const cookies = new Cookies();

export default function PaymentsCheckoutPageComponent() {
  const { token, user } = useAuth();
  const { hash, patientId, treatmentAgreement } =
    useParams<{ hash: string; patientId: string; treatmentAgreement: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [hasPurchased, setHasPurchased] = useState<boolean>(false);
  const [isChild, setIsChild] = useState<boolean>(false);
  const [age, setAge] = useState<number>(0);
  const [product, setProduct] = useState<ShortAnswerProduct>();
  const [productPresale, setProductPresale] = useState<ShortAnswerProduct>();
  const [isTreatmentAgreement, setIsTreatmentAgreement] = useState<boolean>();

  const navigate = useNavigate();
  const [signLink, setSignLink] = useState<string>('');
  const { setStickyState } = useLayout();
  const { setVisible } = useModal();

  useEffect(() => {
    setStickyState(true);
  }, [setStickyState]);

  useEffect(() => {
    if (treatmentAgreement === '1') {
      setIsTreatmentAgreement(true);
    }
  }, [treatmentAgreement]);

  useEffect(() => {
    if (user) {
      if (user.firstName) {
        setFirstName(user.firstName);
      }
      if (user.lastName) {
        setLastName(user.lastName);
      }
      if (user.email) {
        setEmail(user.email);
      }
      if (user.phone) {
        setPhone(user.phone);
      }
      setHasPurchased(user.orders.length > 0);
    }
  }, [user]);

  useEffect(() => {
    const fetchAnswer = async (h: string) => {
      const res = await questionnaireApi.answer(h);

      if (!res.product) {
        navigate(`/questionnaire/answer/${h}`);
      } else {
        setProduct(res.product);
        setProductPresale(res.extraProduct);
      }

      setIsChild(res.isChild);
      setAge(res.age);

      setIsLoading(false);
    };

    if (hash) {
      fetchAnswer(hash);
    }
  }, [hash, navigate, setFirstName]);

  async function checkSignState(signToken: string, orderHash: string): Promise<any> {
    setTimeout(async () => {
      const o = await checkDocumentStatus(signToken);

      if (o.status === OrderStatus.New) {
        setTimeout(async () => {
          await checkSignState(signToken, orderHash);
        }, 2000);
      } else if (o.status !== OrderStatus.New) {
        setIsLoading(true);
        setVisible('SignAgreement', false);
        if (user) {
          navigate(parameters().firstPageUrl);
        }

        cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });
        navigate(`${parameters().researchSuccess}/${orderHash}/${parameters().product.researchId}`);
      }
    }, 2000);
  }

  async function checkUploadedFile(orderHash: string, fileToken: string): Promise<any> {
    const result = await checkUploadStatus(fileToken, orderHash);

    if (result.status === 'uploaded') {
      const signResponse = await getSignLink(orderHash, fileToken);

      setSignLink(signResponse.sign_url);
      setVisible('SignAgreement', true);
      setIsLoading(false);
      await checkSignState(signResponse.sign_token, orderHash);
    } else if (result.status === 'pending') {
      setTimeout(async () => {
        await checkUploadedFile(orderHash, fileToken);
      }, 2000);
    }
  }

  const onComplete = async (data: FormCheckout) => {
    setIsLoading(true);
    if (!data.isResearch) {
      const payment = await initiatePayment(data, hash, token, patientId, isTreatmentAgreement);

      document.location.href = payment.url;
    } else {
      const response = await initiateAgreementSign(data, hash, token, patientId);

      if (response.fileToken) {
        setTimeout(async () => {
          await checkUploadedFile(response.orderHash, response.fileToken);
        }, 2000);
      }
    }
  };

  return (
    <>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && hash && product && (
        <CheckoutFormComponent
          onComplete={onComplete}
          lastName={lastName}
          firstName={firstName}
          email={email}
          phone={phone}
          hasPurchased={hasPurchased}
          product={product}
          extraProduct={productPresale}
          isChild={isChild}
          age={age}
        />
      )}
      <ModalSignAgreement link={signLink} />
    </>
  );
}
