import React, { createContext, useMemo, PropsWithChildren, useState, useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';

import { Question } from '../entities/question';
import { SimpleObject } from '../entities/simple-object';
import { useAuth } from '../hooks/use-auth';
import * as QuestionnaireService from '../services/api/questionnaire';
import { AnswerOption } from '../entities/answer-option';
import { QuestionnaireType } from '../entities/questionnaire-type';
import { QuestionnaireResponse } from '../entities/questionnaire-response';
import { kreipinysVardoPavardes } from '../services/grammar/lithuanian';
import * as singularDeclination from '../services/grammar/lithuanian/singular';

interface ProviderProps {
  type: string;
  productId: number;
  hash?: string;
  repeatOrderHash?: string;
  feedbackValue?: string;
  feedbackCustomerHash?: string;
  calendarAnswerHash?: string;
  allergist?: string;
  patientHash?: string;
  extraSurveyId?: number;
}

interface ContextValue {
  questions: Question[];
  initialSlide: number;
  isLast: boolean;
  questionValues: SimpleObject;
  isQuotaReached: boolean;
  type: string;
  orderHash?: string;
  answerHash?: string;
  repeatOrderHash?: string;
  answerQuestion: (question: Question, answer: Partial<AnswerOption>, hash: string) => void;
  feedbackValue?: string;
  feedbackCustomerHash?: string;
  calendarAnswerHash?: string;
  allergistId?: string;
  patientHashContext?: string;
  extraProductId?: number | undefined;
  setExtraProductId: (id: number | undefined) => void;
  extraSurveyId?: number;
}

export const COOKIE_NAME_ANSWER = 'answer';

export const COOKIE_NAME_ORDER_ANSWER = 'order_answer_hash';

export const COOKIE_NAME_TICKET = 'ticket';
const cookies = new Cookies();

const QuestionnaireContext = createContext<ContextValue>({
  questions: [],
  initialSlide: 0,
  isLast: false,
  questionValues: {},
  isQuotaReached: false,
  type: 'Onboarding',
  orderHash: '',
  answerHash: '',
  repeatOrderHash: '',
  answerQuestion: () => null,
  feedbackValue: '',
  feedbackCustomerHash: '',
  calendarAnswerHash: '',
  allergistId: '',
  patientHashContext: '',
  extraProductId: undefined,
  setExtraProductId: () => undefined,
  extraSurveyId: undefined,
});

export const setAnswerCookie = (hash: string) => {
  const expirationTime = new Date();

  expirationTime.setDate(expirationTime.getDate() + 7);

  cookies.set(COOKIE_NAME_ANSWER, hash, {
    expires: expirationTime,
    path: '/',
  });
};

export const setTicketCookie = (hash: string) => {
  const expirationTime = new Date();

  expirationTime.setDate(expirationTime.getDate() + 1);

  cookies.set(COOKIE_NAME_TICKET, hash, {
    expires: expirationTime,
    path: '/',
  });
};

const replaceValues = (questions: Question[], values: SimpleObject): Question[] => {
  let res: Question[] = questions;

  Object.keys(values).forEach((key) => {
    res = res.map((q) => {
      const regex = new RegExp(`{${key}}`);

      let value = values[key];

      if (key === 'name') {
        value = kreipinysVardoPavardes(value);
      } else if (key === 'nameChild') {
        value = singularDeclination.accusative(value);
      }

      return {
        ...q,
        heading: q.heading?.replace(regex, value),
        title: q.title?.replace(regex, value),
      };
    });
  });

  return res;
};

/** kl kuriuos parodys. Atsakyti klausimai, kurie turi visible:true.** */
const markVisible = (questions: Question[], previouslyTriggeredIds: number[]): Question[] => {
  let triggeredOptionIds: number[] = previouslyTriggeredIds || [];

  return questions.map((q) => {
    if (q.answerOptionId) {
      triggeredOptionIds = [...triggeredOptionIds, q.answerOptionId];
    }

    if (q.answerMultiOptionsIds) {
      triggeredOptionIds = [...triggeredOptionIds, ...q.answerMultiOptionsIds];
    }

    return {
      ...q,
      visible:
        q.triggerQuestionOptionIds.length < 1 ||
        q.triggerQuestionOptionIds.some((element) => {
          return triggeredOptionIds.indexOf(Number(element)) !== -1;
        }),
    };
  });
};

const findInitialQuestion = (questions: Question[]): { initial: number; isLast: boolean } => {
  const answered = questions.filter((q) => {
    if (!q.visible) {
      return false;
    }

    return !(!q.answerOptionId && !q.answerText && !q.answerSkipped && !q.answerMultiOptionsIds?.length);
  });

  if (!answered.length) {
    return { initial: 0, isLast: false };
  }

  const answeredIndex = questions.indexOf(answered[answered.length - 1]);
  const isLast = answeredIndex + 1 === questions.length;

  return { initial: answeredIndex + 1, isLast };
};
// Kad gauti atsakytus klausimus reikia perduoti answerHash
const getQuestions = async (
  type: string,
  token: string,
  answerHash: string,
  productId?: number,
  ticketId?: string,
  calendarAnswerHash?: string,
  patientHash?: string,
  extraSurveyId?: number,
): Promise<QuestionnaireResponse> => {
  switch (type) {
    case QuestionnaireType.Full:
      return QuestionnaireService.onboarding(answerHash, 2, productId, ticketId, extraSurveyId);
    case QuestionnaireType.Feedback:
      return QuestionnaireService.feedback();
    case QuestionnaireType.Calendar:
      return QuestionnaireService.onboarding(calendarAnswerHash, 2, 1);
    case QuestionnaireType.WeeklyTreatment:
      return QuestionnaireService.weeklyTreatment(patientHash);
    case QuestionnaireType.Anamnesis:
      return QuestionnaireService.anamnesis(answerHash);
    default:
      return QuestionnaireService.onboarding(answerHash, undefined, productId, ticketId, extraSurveyId);
  }
};

function QuestionnaireContextProvider({
  children,
  type,
  productId,
  hash,
  repeatOrderHash,
  feedbackValue,
  feedbackCustomerHash,
  calendarAnswerHash,
  allergist,
  patientHash,
  extraSurveyId,
}: PropsWithChildren<ProviderProps>) {
  const [questionnaire, setQuestionnaire] = useState<Question[]>([]);
  const [questionValues, setQuestionValues] = useState<SimpleObject<string>>({});
  const [triggeredIds, setTriggeredIds] = useState<number[]>([]);
  const [initialSlide, setInitialSlide] = useState<number>(0);
  const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);
  const [orderHash, setOrderHash] = useState<string>();
  const [repeatedOrderHash, setRepeatedOrderHash] = useState<string>();
  const [isQuotaReached, setIsQuotaReached] = useState<boolean>(false);
  const [answerHash, setAnswerHash] = useState<string>(cookies.get(COOKIE_NAME_ANSWER));
  const [ticketId, setTicketId] = useState<string>(cookies.get(COOKIE_NAME_TICKET));
  const [feedbackValueSet, setFeedbackValue] = useState<string>();
  const [feedbackCustomerHashSet, setfeedbackCustomerHash] = useState<string>();
  const [calendarAnswerHashSet, setCalendarAnswerHash] = useState<string>();
  const [allergistId, setAllergistId] = useState<string>();
  const [patientHashContext, setPatientHashContext] = useState<string>();
  const { token } = useAuth();
  const [upsellProductId, setUpsellProductId] = useState<number | undefined>();
  const [exSurveyId, setExtraSurveyId] = useState<number | undefined>();

  useEffect(() => {
    const loadData = async () => {
      // const { questions, values, triggeredQuestionOptionIds, ticketId as newTicketId }

      try {
        const questionsResponse = await getQuestions(
          type,
          token,
          answerHash,
          productId,
          ticketId,
          calendarAnswerHash,
          patientHash,
          extraSurveyId,
        );

        if (questionsResponse.resetAnswer) {
          cookies.remove(COOKIE_NAME_ANSWER, { path: '/' });
        }

        const visibleQuestion = markVisible(questionsResponse.questions, questionsResponse.triggeredQuestionOptionIds);

        const { initial, isLast } = findInitialQuestion(visibleQuestion);

        setTicketId(questionsResponse.ticketId);
        setIsLastQuestion(isLast);
        setQuestionValues(questionsResponse.values);

        setTriggeredIds(questionsResponse.triggeredQuestionOptionIds);
        setQuestionnaire(replaceValues(visibleQuestion, questionsResponse.values));
        setInitialSlide(initial);
      } catch (e) {
        setIsQuotaReached(true);
      }
    };

    setOrderHash(hash);
    setRepeatedOrderHash(repeatOrderHash);
    setfeedbackCustomerHash(feedbackCustomerHash);
    setFeedbackValue(feedbackValue);
    setCalendarAnswerHash(calendarAnswerHash);
    setAllergistId(allergist);
    setExtraSurveyId(extraSurveyId);
    if (calendarAnswerHash) {
      cookies.set(COOKIE_NAME_ORDER_ANSWER, calendarAnswerHash);
      setAnswerCookie(calendarAnswerHash);
      setAnswerHash(calendarAnswerHash);
    }
    setPatientHashContext(patientHash);
    loadData();
  }, [
    token,
    hash,
    type,
    calendarAnswerHash,
    feedbackCustomerHash,
    feedbackValue,
    repeatOrderHash,
    allergist,
    patientHash,
    extraSurveyId,
  ]);

  const updateQuestionValue = useCallback(
    (key: string, value: string): SimpleObject<string> => {
      const newQuestionValues = {
        ...questionValues,
        [key]: value,
      };

      setQuestionValues(newQuestionValues);

      return newQuestionValues;
    },
    [questionValues, setQuestionValues],
  );

  const answerQuestion = useCallback(
    (question: Question, answer: Partial<AnswerOption>, aHash: string) => {
      const questionIndex = questionnaire.indexOf(question);
      let values = questionValues;

      if (question.key) {
        values = updateQuestionValue(question.key, answer.text || '');
      }

      if (questionnaire[questionIndex].answerOptionId) {
        const itemIndex = triggeredIds.indexOf(questionnaire[questionIndex].answerOptionId || 0);

        triggeredIds.splice(itemIndex, 1);
      }

      if (
        questionnaire[questionIndex].answerMultiOptionsIds &&
        questionnaire[questionIndex].answerMultiOptionsIds !== undefined
      ) {
        const oldSelectedIds = questionnaire[questionIndex].answerMultiOptionsIds || [];

        for (let i = 0; i <= oldSelectedIds.length; i += 1) {
          const itemIndex = triggeredIds.indexOf(i);

          triggeredIds.splice(itemIndex, 1);
        }
      }

      setTriggeredIds([...triggeredIds]);

      questionnaire[questionIndex].answerMultiOptionsIds = answer.optionIds;
      questionnaire[questionIndex].answerOptionId = answer.optionId;
      questionnaire[questionIndex].answerText = answer.text;
      questionnaire[questionIndex].answerSkipped = answer.skipped;

      if (!orderHash) {
        setAnswerHash(aHash);
        setAnswerCookie(aHash);
      }

      setQuestionnaire(replaceValues(markVisible([...questionnaire], triggeredIds), values));
    },
    [questionnaire, orderHash, updateQuestionValue, questionValues, triggeredIds],
  );

  const setExtraProductId = useCallback((id: number | undefined) => {
    setUpsellProductId(id);
  }, []);

  const contextValue = useMemo(
    () => ({
      questions: questionnaire,
      isLast: isLastQuestion,
      questionValues,
      initialSlide,
      type,
      answerQuestion,
      orderHash,
      answerHash,
      repeatOrderHash: repeatedOrderHash,
      isQuotaReached,
      feedbackValue: feedbackValueSet,
      feedbackCustomerHash: feedbackCustomerHashSet,
      calendarAnswerHash: calendarAnswerHashSet,
      allergistId,
      patientHashContext,
      extraProductId: upsellProductId,
      setExtraProductId,
      extraSurveyId: exSurveyId,
    }),
    [
      questionnaire,
      type,
      questionValues,
      initialSlide,
      answerQuestion,
      isLastQuestion,
      orderHash,
      answerHash,
      repeatedOrderHash,
      isQuotaReached,
      feedbackValueSet,
      feedbackCustomerHashSet,
      calendarAnswerHashSet,
      allergistId,
      patientHash,
      upsellProductId,
      setUpsellProductId,
      exSurveyId,
    ],
  );

  return <QuestionnaireContext.Provider value={contextValue}>{children}</QuestionnaireContext.Provider>;
}

QuestionnaireContextProvider.defaultProps = {
  hash: '',
  repeatOrderHash: '',
  feedbackValue: '',
  feedbackCustomerHash: '',
  calendarAnswerHash: '',
  allergist: '',
  patientHash: '',
  extraSurveyId: '',
};

export { QuestionnaireContext, QuestionnaireContextProvider };
