import React, { useState } from 'react';
import { OnChangeValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { strings } from '../../../localization/strings';
import * as parcelTerminalsApi from '../../../services/api/parcel-terminals';
import QuestionnaireFooterButtons from '../footer-buttons';
import { ParcelTerminal } from '../../../entities/parcel-terminal';

interface ComponentProps {
  onSelect?: (location: ParcelTerminal) => void;
}

function QuestionnaireQuestionParcelTerminalComponent({ onSelect }: ComponentProps) {
  const [currentParcelTerminal, setCurrentParcelTerminal] = useState<ParcelTerminal | null>(null);

  const fetchTerminals = async (inputValue?: string) => {
    const res = await parcelTerminalsApi.getList(inputValue || undefined);

    return res.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const onSelectParcelTerminal = (option: OnChangeValue<ParcelTerminal, false>) => {
    setCurrentParcelTerminal(option);
  };

  const onContinue = () => {
    if (onSelect && currentParcelTerminal) {
      onSelect(currentParcelTerminal);
    }
  };

  return (
    <div className="min-w-full">
      <AsyncSelect
        className="pb-4"
        cacheOptions
        loadOptions={fetchTerminals}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        placeholder={strings().bloodRegistration.pickUpBoxPlaceholder}
        getOptionValue={(option: ParcelTerminal) => `${option.id}`}
        getOptionLabel={(option: ParcelTerminal) => `${option.name}, ${option.address}, ${option.zip}, ${option.city}`}
        onChange={onSelectParcelTerminal}
        menuPortalTarget={document.body}
        // defaultOptions
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        noOptionsMessage={() => null}
        isClearable
        isSearchable
      />

      <QuestionnaireFooterButtons disableContinue={!currentParcelTerminal} onContinue={() => onContinue()} />
    </div>
  );
}

QuestionnaireQuestionParcelTerminalComponent.defaultProps = {
  onSelect: null,
};

export default QuestionnaireQuestionParcelTerminalComponent;
