import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { setAnswerCookie } from '../../context/questionnaire';

function QuestionnaireStartConsultationPageComponent() {
  const navigate = useNavigate();
  const { answerHash } = useParams();

  useEffect(() => {
    const createAnswer = async () => {
      if (answerHash) {
        setAnswerCookie(answerHash);
      }

      navigate(`/questionnaire/onboarding/1`);
    };

    createAnswer();
  }, []);

  return <CommonAnimatedLoader />;
}

export default QuestionnaireStartConsultationPageComponent;
