import axios from 'axios';

import { StatusResponse } from '../../entities/status-response';
import { Message } from '../../entities/message';
import { parameters } from '../../localization/strings';

export const sendMessage = async (token: string, message: Partial<Message>): Promise<StatusResponse> => {
  const url = `${parameters().api.baseUrl}/messages/send-customer`;

  // console.log(`${parameters().api.baseUrl}/messages/send-customer`, 'param');

  const result = await axios.post(url, message, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getLatest = async (token: string, lastMessageId?: string): Promise<Message[]> => {
  const url = `${parameters().api.baseUrl}/messages/latest`;

  const result = await axios.get(url, {
    params: {
      lastMessageId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getMessage = async (token: string, messageId: string): Promise<Message> => {
  const url = `${parameters().api.baseUrl}/messages/get/${messageId}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
