import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import { Location } from '../../entities/location';
import { storeBloodLocation } from '../../services/api/orders';
import { useAuth } from '../../hooks/use-auth';
import BloodRegistrationSelectLocation from '../../components/blood-registration/select-location';
import * as locationsApi from '../../services/api/locations';
import BloodRegistrationSuccessMessage from '../../components/blood-registration/success-message';
import { OrderStatus } from '../../entities/order';

export default function ProfileBloodRegistrationPageComponent() {
  const { hash } = useParams();
  const { token, updateOrder } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);

  const onContinue = async (location: Location) => {
    if (location && hash) {
      setIsLoading(true);
      setCurrentLocation(location);

      await storeBloodLocation(token, hash, location.id);

      updateOrder(hash, {
        isBloodRegistered: true,
        status: OrderStatus.BloodPending,
        locationPhone: location.phone,
        locationName: location.partnerName,
        locationAddress: location.address,
      });

      setIsLoading(false);
      setIsCompleted(true);
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      const res = await locationsApi.getList();

      setLocations(res);
    };

    fetchLocations();
  }, [setLocations]);

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      {hash ? (
        <div>
          {!isCompleted && <BloodRegistrationSelectLocation locations={locations} onContinue={onContinue} />}
          {isCompleted && currentLocation && <BloodRegistrationSuccessMessage location={currentLocation} />}
        </div>
      ) : (
        <CommonAnimatedLoader />
      )}
    </div>
  );
}
