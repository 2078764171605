import React from 'react';
import { NavLink } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import UsersLoginForm from '../../components/users/login-form';
import Heading2 from '../../components/common/headings/heading2';
import { strings } from '../../localization/strings';
import Heading3 from '../../components/common/headings/heading3';
import IMAGES from '../../assets/images';
import { useSite } from '../../hooks/use-site';

const BgImage = styled.img`
  top: 0;
  width: 18rem;
  height: auto;
  ${tw`absolute z-0 left-[-2rem]`};
`;

function QuestionnaireLoginPageComponent() {
  const { isAllergomedica } = useSite();

  return (
    <div className="min-h-full md:flex items-center justify-center py-10 md:py-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl relative">
      {isAllergomedica && <BgImage src={IMAGES.BLOB.FIRST} alt="" aria-hidden="true" />}
      <div className="md:w-1/2 z-10 relative border-b md:border-b-0 pb-10 md:pb-0 md:border-r border-gray-200 md:pr-20">
        {/* Mes po sekmingo pirkimo turim sita pacia prisijungimo forma jeigu pagaunam kad useris registruotas */}
        <Heading2 className="mb-16">{strings().login.title}</Heading2>
        <UsersLoginForm isQuestionnaire />
      </div>

      <div className="mt-10 md:mt-0 md:w-1/2 relative z-10 md:pl-20">
        <Heading3 className="mb-6">{strings().login.dontHaveAccount}</Heading3>
        <NavLink
          key="register"
          to="/questionnaire/register"
          className="!no-underline bg-content-300 text-content-900 hover:bg-content-400 inline-flex px-7 sm:px-9 text-base rounded-2xl py-3.5 font-bold"
        >
          {strings().login.register}
        </NavLink>
      </div>
    </div>
  );
}

export default QuestionnaireLoginPageComponent;
