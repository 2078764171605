import tw, { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';

const ContainerMd = styled.div`
  ${tw`mx-auto container px-4`}

  ${up('md')} {
    max-width: 768px;
  }
`;

export default ContainerMd;
