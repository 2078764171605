import axios from 'axios';

import { parameters } from '../../localization/strings';
import { Doctor } from '../../entities/doctor';

export const getList = async (): Promise<Doctor[]> => {
  const url = `${parameters().api.baseUrl}/doctors/list`;

  const result = await axios.get(url);

  return result.data;
};
