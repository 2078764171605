import { MutableRefObject, useRef } from 'react';

const useFocus = (): [MutableRefObject<HTMLInputElement | null>, () => void] => {
  const htmlElRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const setFocus = (): void => {
    htmlElRef?.current?.focus?.();
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
