import React from 'react';

import { strings } from '../../localization/strings';
import { currency } from '../../services/formatter/currency';
import { ShortAnswerProduct } from '../../entities/short-answer';
import { useSite } from '../../hooks/use-site';

interface ComponentProps {
  children: React.ReactNode;
  product: ShortAnswerProduct;
}

export default function QuestionnaireProductsListItemHorizontal({ children, product }: ComponentProps) {
  const { locale } = useSite();

  return (
    <div className="border-b border-gray-200 px-4 py-5 sm:p-0">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-4">
          <dt className="text-lg sm:col-span-2">
            <div className="text-xl">
              {product.key && strings().products[product.key].title}{' '}
              {product.review && product.review === 'written' && strings().written}{' '}
              {product.isConsultation && product.key !== 'CONSULTATION' && <span>{strings().consultation}</span>}
            </div>
            <span className="font-bold text-blue-800">{currency(product.price, locale)}</span>
            {product.priceOriginal && (
              <span className="text-[#979797] inline-block ml-2 line-through">
                {currency(product.priceOriginal, locale)}
              </span>
            )}
          </dt>
          <dd className="mt-1 text-gray-900 sm:mt-0 text-base flex items-center justify-end">{children}</dd>
        </div>
      </dl>
    </div>
  );
}
