import { useContext } from 'react';

import { SiteContext } from '../context/site';

export const useSite = () => {
  const context = useContext(SiteContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useSite was used outside of its Provider');
  }

  return context;
};
