import React, { createContext, useMemo } from 'react';

import { parameters, setDomain, setLocale } from '../localization/strings';

interface ComponentProps {
  children: React.ReactNode;
}

interface ContextValue {
  theme: string;
  isAllergomedica: boolean;
  isfoodId: boolean;
  locale: string;
  hasConsultation: boolean;
  hasPersonCode: boolean;
  hasPresale: boolean;
  hasBirthDate: boolean;
  hasDoctor: boolean;
}

const SiteContext = createContext<ContextValue>({
  theme: '',
  isAllergomedica: false,
  isfoodId: false,
  locale: '',
  hasConsultation: false,
  hasPersonCode: false,
  hasPresale: false,
  hasBirthDate: false,
  hasDoctor: false,
});

function SiteContextProvider({ children }: ComponentProps) {
  const host = window.location.hostname;

  const theme = host.includes('allergomedica') ? 'allergomedica' : 'foodId';
  let locale = host.includes('.pl') ? 'pl' : 'lt';
  const isfoodId = theme === 'foodId';
  const isAllergomedica = theme === 'allergomedica';

  const urlObj = new URL(window.location.href);
  const pathSegments = urlObj.pathname.split('/');

  // The language is expected to be the first segment after the leading '/'
  const langFromUrl = pathSegments[1];

  if (langFromUrl === 'en' || langFromUrl === 'pl' || langFromUrl === 'lt') {
    locale = langFromUrl;
  }

  setLocale(locale);

  setDomain(theme);

  const contextValue = useMemo(
    () => ({
      theme,
      locale,
      isAllergomedica,
      isfoodId,
      hasConsultation: parameters().features.hasConsultation,
      hasPersonCode: parameters().features.hasPersonCode,
      hasPresale: parameters().features.hasPresale,
      hasBirthDate: parameters().features.hasBirthDate,
      hasDoctor: parameters().features.hasDoctor,
    }),
    [theme, isAllergomedica, isfoodId, locale],
  );

  return <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>;
}

export { SiteContext, SiteContextProvider };
