import * as noun from './lithuanian/noun';

const getValueIndex = (value: number, locale: string): number => {
  switch (locale) {
    case 'en':
      return value === 1 ? 0 : 1;

    case 'pl':
      if (value === 1) {
        return 0;
      }

      if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
        return 1;
      }

      return 2;
    case 'lt':
    default:
      if (value % 10 === 1 && value % 100 !== 11) {
        return 0;
      }

      return value % 10 >= 2 && (value % 100 < 10 || value % 100 >= 20) ? 1 : 2;
  }
};

export const pluralize = (value: number, translation: string, locale?: string): string => {
  const messageParts = translation.split(/\|/);
  const valueIndex = getValueIndex(value, locale || 'lt');

  return messageParts[valueIndex] ?? translation;
};

export const pluralizeWithNumber = (value: number, translation: string, locale?: string): string => {
  return `${value} ${pluralize(value, translation, locale)}`;
};

/**
 * Gražina daiktavardžio kilmininko linksnį.
 * @param string input
 * @param bool $vyriskaGimine parametras nusako ar zodis vyriškos giminės. Reikalingas, kuomet daiktavardis baigiasi 'tis'.
 * @access public
 * @static
 * @return string
 */
function genitivePlural(input: string, isMasculine = true) {
  const ending = noun.ending(input);

  switch (ending) {
    // case '':
    //   return input;
    //   break;

    case 'a':
      return `${input.substring(0, input.length - 1)}ų`;

    case 'ė':
      return `${input.substring(0, input.length - 1)}ių`;

    case 'tė':
      return `${input.substring(0, input.length - 2)}čių`;

    case 'as':
    case 'ias':
    case 'čias':
    case 'jas':
    case 'ius': // aktorius
      return `${input.substring(0, input.length - 2)}ų`;

    case 'us': // zmogus
      return `${input.substring(0, input.length - 3)}nių`;

    case 'is':
      return `${input.substring(0, input.length - 2)}ių`;

    case 'tis':
      return `${input.substring(0, input.length - 3)}čių`;

    case 'ys':
      return `${input.substring(0, input.length - 2)}ių`;

    case 'jys':
      return `${input.substring(0, input.length - 3)}jų`;

    case 'sis':
      return `${input.substring(0, input.length - 3)}sų`;

    case 'dis':
    case 'dys':
      return `${input.substring(0, input.length - 2)}žių`;

    case 'duo':
      return `${input.substring(0, input.length - 3)}denų`;

    case 'muo':
      return `${input.substring(0, input.length - 3)}menų`;

    case 'šuo':
      return `${input.substring(0, input.length - 3)}šunų`;

    default:
      return input;
  }
}
