import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import tw, { styled } from 'twin.macro';

const TooltipArrow = styled.div<{ placement: string }>`
  visibility: hidden;

  ${(props) => (props.placement === 'top' ? 'bottom: -4px;' : '')}
  ${(props) => (props.placement === 'bottom' ? 'top: -4px;' : '')}
  ${(props) => (props.placement === 'left' ? 'right: -4px;' : '')}
  ${(props) => (props.placement === 'right' ? 'left: -4px;' : '')}

  &,
  &::before {
    ${tw`bg-content-300`}
    position: absolute;
    width: 8px;
    height: 8px;
  }

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

interface ComponentProps {
  children: ReactNode;
}

function InfoTooltip({ children }: ComponentProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();
  const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  return (
    <Popover className="ml-3 print:hidden">
      <Popover.Button className="outline-none" ref={(ref: HTMLButtonElement) => setReferenceElement(ref)}>
        <div className="md:text-2xl text-xl text-content-600 hover:text-content-800 font-medium mt-0.5">
          <span className="icon am-icon-info" />
        </div>
      </Popover.Button>
      <Popover.Panel
        ref={(ref: HTMLDivElement) => setPopperElement(ref)}
        style={styles.popper}
        {...attributes.popper}
        className="bg-content-300 text-md font-normal leading-normal w-96 p-3 mx-6 md:mx-0 rounded-lg shadow-md z-[2]"
      >
        <TooltipArrow placement={state?.placement || 'top'} ref={(ref) => setArrowElement(ref)} style={styles.arrow} />
        {children}
      </Popover.Panel>
    </Popover>
  );
}

export default InfoTooltip;
