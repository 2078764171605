import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ShortAnswer, ShortAnswerProduct } from '../../entities/short-answer';
import * as questionnaireApi from '../../services/api/questionnaire';
import CommonButtonDefault from '../../components/common/buttons/default';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import QuestionnaireProductsListItem from '../../components/questionnaire/products-list-item';

export default function ProfileSelectProductPageComponent() {
  const { hash, productId } = useParams<{ hash: string; productId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [answer, setAnswer] = useState<ShortAnswer>();
  const navigate = useNavigate();

  const parsedProductId = parseInt(productId || '', 10);

  useEffect(() => {
    const fetchAnswer = async (h: string) => {
      const res = await questionnaireApi.answer(h);

      setAnswer(res);
      setIsLoading(false);
    };

    if (hash) {
      fetchAnswer(hash);
    }
  }, [hash]);

  const onSelectProduct = async (product: ShortAnswerProduct) => {
    if (hash) {
      navigate(`/questionnaire/onboarding/${product.id}/2`);
    }
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      {!isLoading && hash && answer && (
        <div className="my-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row lg:space-x-8 mb-4 md:mb-10">
            {answer.products.map((product) => (
              <QuestionnaireProductsListItem
                product={product}
                key={product.key}
                isRecommended={product.id === parsedProductId}
              >
                {product.comingSoon ? (
                  <div className="w-full text-center py-4 text-gray-500">{strings().product.comingSoon}</div>
                ) : (
                  <CommonButtonDefault primary className="w-full mt-8" onClick={() => onSelectProduct(product)}>
                    {strings().product.select}
                  </CommonButtonDefault>
                )}
              </QuestionnaireProductsListItem>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
