import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/use-auth';
import { OrderStatus } from '../../entities/order';
import { parameters } from '../../localization/strings';
import ModalSignAgreement from '../../components/modal/sign-agreement';
import { useModal } from '../../hooks/use-modal';
import { checkDocumentStatus } from '../../services/api/agreements';

export default function ResearchSpouseSignPageComponent() {
  const { token, user } = useAuth();
  const { signToken, signerToken, orderHash } = useParams();
  const { setVisible } = useModal();
  const [signLink, setSignLink] = useState<string>('');

  async function checkSignState(signatureToken: string, hash: string): Promise<any> {
    setTimeout(async () => {
      const o = await checkDocumentStatus(signatureToken);

      if (o.status === OrderStatus.New) {
        setTimeout(async () => {
          await checkSignState(signatureToken, hash);
        }, 2000);
      } else if (o.status !== OrderStatus.New) {
        setVisible('SignAgreement', false);
      }
    }, 2000);
  }

  useEffect(() => {
    if (signToken && signerToken && orderHash) {
      setSignLink(`${parameters().dokobit.host}/signing/${signToken}?access_token=${signerToken}`);
      setVisible('SignAgreement', true);
      checkSignState(signToken, orderHash);
    }
  }, [signToken, signerToken, token, user]);

  return (
    <div>
      <ModalSignAgreement link={signLink} />
    </div>
  );
}
