import React from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import classNames from 'classnames';

import { useAuth } from '../../hooks/use-auth';
import { OrderStatus } from '../../entities/order';
import ContainerMd from '../../components/common/containers/containerMd';
import RoundedBlock from '../../components/common/blocks/roundedBlock';
import Heading3 from '../../components/common/headings/heading3';
import { parameters, strings } from '../../localization/strings';
import OrderProgressComponent from '../../components/order/progress';
import { getDate } from '../../services/formatter/date';
import StickyBanner from '../../components/common/sticky/stickyBanner';

const LinkButton = styled(Link)`
  ${tw`inline-block p-2 mb-3 pl-5 pr-5 bg-blue-800 hover:bg-blue-900 text-gray-100 text-md md:text-lg rounded-button focus:border-4 border-blue-300`}
`;

export default function ProfileDashboardPageComponent() {
  const { user } = useAuth();

  const currentStep = (status: string) => {
    switch (status) {
      case OrderStatus.Completed:
        return 4;
      case OrderStatus.SampleReceived:
        return 3;
      default:
        return 2;
    }
  };

  return (
    <ContainerMd className="mt-10">
      {!user?.orders.length && <div>{strings().dashboard.noOrders}</div>}
      {user?.orders.map((order, i) => {
        return (
          <RoundedBlock key={order.id} className="mb-5">
            <div className="flex flex-row">
              <div className="mr-auto">
                {order.productKey && <Heading3>{order.productTitle}</Heading3>}
                <div className="sm:flex sm:space-x-3 mt-3">
                  {order.id && (
                    <p className="text-content-700 text-sm mt-1 sm:mt-0">
                      {strings().dashboard.orderId} {order.id}
                    </p>
                  )}
                  {order.paidAt && (
                    <p className="text-content-700 text-sm mt-1 sm:mt-0">
                      {strings().dashboard.orderedAt} {getDate(order.paidAt)}
                    </p>
                  )}
                  {(order.firstName ?? order.lastName) && (
                    <p className="text-content-700 text-sm">
                      {order.firstName} {order.lastName}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-shrink-1">
                <span
                  className={classNames('inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium', {
                    'bg-green-100 text-green-800': order.status === OrderStatus.Completed,
                    'bg-yellow-100 text-yellow-800': order.status !== OrderStatus.Completed,
                  })}
                >
                  {order.status === OrderStatus.Completed &&
                    order.productKey !== 'TREATMENT_PLAN' &&
                    strings().dashboard.status.completed}

                  {order.status === OrderStatus.Completed && order.productKey === 'TREATMENT_PLAN' && 'Aktyvuotas'}
                  {order.status !== OrderStatus.Completed && strings().dashboard.status.ongoing}
                </span>
              </div>
            </div>
            {order.productId !== parameters().product.immunotherapyProductId &&
              order.productId !== parameters().product.immunotherapyProductId6month && (
                <div className="mt-10">
                  <OrderProgressComponent
                    isConsultation={order.productKey === 'CONSULTATION'}
                    current={currentStep(order.status)}
                    isResearch={order.productKey === 'FREE_SCREENER' || order.productKey === 'FREE_SCREENER_ASIT'}
                    isImmunotherapy={order.productId === parameters().product.stepsImmunotherapyId}
                    isImmunotherapyConsultation={
                      order.productId === parameters().product.stepsImmunotherapyConsultationId
                    }
                  />
                </div>
              )}

            {order.status === OrderStatus.Completed && order.productKey !== 'TREATMENT_PLAN' && (
              <RoundedBlock className="bg-gray-50 mt-10" key={`registration-${i + 1}`}>
                <div>
                  <Heading3 className="mb-3">{strings().dashboard.tasks.orderCompletedHeading}</Heading3>
                  <p className="text-content-700">
                    {order.productKey === 'CONSULTATION'
                      ? strings().dashboard.tasks.orderCompletedDescriptionConsultation
                      : strings().dashboard.tasks.orderCompletedDescription}
                  </p>
                </div>
                <div className="mt-8">
                  <LinkButton
                    to={`/profile/results/${order.hash}${
                      order.productKey === 'CONSULTATION' ? '/recommendations' : ''
                    }`}
                    className="no-underline"
                  >
                    {strings().dashboard.resultPreview}
                  </LinkButton>
                </div>
              </RoundedBlock>
            )}
          </RoundedBlock>
        );
      })}
      <StickyBanner providedFeedback={user?.providedFeedback} hasCompletedOrders={user?.hasCompletedOrders} />
    </ContainerMd>
  );
}
