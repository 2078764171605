export const currency = (input: number, locale: string): string => {
  let currencyCode = 'EUR';

  if (locale === 'pl') {
    currencyCode = 'PLN';
  }
  // Create our number formatter.
  const formatter = new Intl.NumberFormat(locale || 'lt', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: locale === 'pl' ? 0 : 2,
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(input); /* $2,500.00 */
};
