import { useContext } from 'react';

import { QuestionnaireContext } from '../context/questionnaire';

export const useQuestionnaire = () => {
  const context = useContext(QuestionnaireContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useQuestionnaire was used outside of its Provider');
  }

  return context;
};
