import React, { useEffect } from 'react';

import { QuestionnaireContextProvider } from '../../../context/questionnaire';
import QuestionnaireSliderComponent from '../../../components/questionnaire/slider';
import { QuestionnaireType } from '../../../entities/questionnaire-type';
import { useLayout } from '../../../hooks/use-layout';

export default function QuestionnaireAnamnesisPageComponent() {
  // anamnezės klausimynas, nėra produkto
  const intProductId = 0;

  const { setMenuLogoState } = useLayout();

  useEffect(() => {
    setMenuLogoState(false);
  }, [setMenuLogoState]);

  return (
    <QuestionnaireContextProvider type={QuestionnaireType.Anamnesis} productId={intProductId}>
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
