import axios, { AxiosError } from 'axios';

import { parameters } from '../../localization/strings';
import UploadedFile from '../../entities/uploaded-file';
import { ApiError } from '../../entities/api-error';

export const uploadFile = async (key: string, patientHash: string): Promise<UploadedFile> => {
  const url = `${parameters().api.baseUrl}/patients/upload`;

  try {
    const result = await axios.post(
      url,
      {
        key,
        patientHash,
      },
      {},
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError<ApiError>;

    // if (e typeof AxiosError) {
    throw new Error(err.response?.data.error);
    // }
  }
};
